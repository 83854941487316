import React, { Component } from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { gbGraphTheme } from "../../utils/helpers";

import {
  visitorCountConfig,
  visitorPercentageConfig,
  reportCountConfig,
  reportPercentageConfig,
} from "./config";
import "./statisticPieChart.less";
interface Props {
  headline: string;
  type: string;

  pieData: [];
}

export default class StatisticPieChart extends Component<Props> {
  static propTypes = {
    headline: PropTypes.string,
    type: PropTypes.string,

    pieData: PropTypes.array,
  };

  constructor(props: Props) {
    super(props);
  }

  render() {
    // set Highcharts options
    Highcharts.setOptions(gbGraphTheme);

    const { headline } = this.props;
    let config: any;
    if (this.props.type === "visitor-count") {
      config = visitorCountConfig;
    } else if (this.props.type === "visitor-percentage") {
      config = visitorPercentageConfig;
    }
    if (this.props.type === "report-count") {
      config = reportCountConfig;
    } else if (this.props.type === "report-percentage") {
      config = reportPercentageConfig;
    }

    // set additional data
    config.plotOptions.pie.dataLabels.style.color =
      (Highcharts.theme && Highcharts.theme.contrastTextColor) || "black";
    config.series[0].data = this.props.pieData;

    return (
      <div className="component-statistic-graph">
        <h4>{headline}</h4>
        <div className="statistic-graph-body">
          <HighchartsReact highcharts={Highcharts} options={config} />
        </div>
      </div>
    );
  }
}
