import Highcharts from "highcharts";

import { i18n } from "../services/i18n";
import ExhibitionApi from "../services/exhibitionApi";
import { gbCountries } from "./gbCountries";
import { gbDivisions } from "./gbDivisions";

export const sortKeys = (obj) => {
  return Object.keys(obj).sort((a, b) => {
    return obj[b].sum - obj[a].sum;
  });
};

export const sortDates = (obj) => {
  const keys = Object.keys(obj);

  keys.sort((a, b) => {
    // const dateA = moment.utc().dayOfYear(obj[a]).valueOf();
    // const dateB = moment.utc().dayOfYear(obj[b]).valueOf();

    // return (dateA > dateB) ? 1 : ((dateB > dateA) ? -1 : 0);
    return new Date(b) - new Date(a);
  });

  return keys;
};

export const gbGraphTheme = (Highcharts.theme = {
  colors: ["#a9b745", "#3b8194"],
  title: {
    style: {
      textTransform: "uppercase",
      fontSize: ".95rem",
    },
  },
  subtitle: {
    style: {
      color: "#73879C",
      fontSize: ".5rem",
    },
  },
});

export const gbGraphTranslation = {
  lang: {
    decimalPoint: i18n("decimal_point"),
    thousandsSep: i18n("thousands_sep"),
    loading: i18n("loading"),
    months: [
      i18n("jan"),
      i18n("feb"),
      i18n("mar"),
      i18n("apr"),
      i18n("may"),
      i18n("june"),
      i18n("july"),
      i18n("aug"),
      i18n("sept"),
      i18n("oct"),
      i18n("nov"),
      i18n("dec"),
    ],
    weekdays: [
      i18n("sunday"),
      i18n("monday"),
      i18n("tuesday"),
      i18n("wednesday"),
      i18n("thursday"),
      i18n("friday"),
      i18n("saturday"),
    ],
    shortMonths: [
      i18n("jan_short"),
      i18n("feb_short"),
      i18n("mar_short"),
      i18n("apr_short"),
      i18n("may_short"),
      i18n("june_short"),
      i18n("july_short"),
      i18n("aug_short"),
      i18n("sept_short"),
      i18n("oct_short"),
      i18n("nov_short"),
      i18n("dec_short"),
    ],
    exportButtonTitle: i18n("export_button_title"),
    printButtonTitle: i18n("print_button_title"),
    rangeSelectorFrom: i18n("range_selector_from"),
    rangeSelectorTo: i18n("range_selector_to"),
    rangeSelectorZoom: i18n("range_selector_zoom"),
    downloadPNG: i18n("download_png"),
    downloadJPEG: i18n("download_jpeg"),
    downloadPDF: i18n("download_pdf"),
    downloadSVG: i18n("download_svg"),
    resetZoom: i18n("reset_zoom"),
    resetZoomTitle: i18n("reset_zoom"),
  },
};

export const removeEvrs = (key) => {
  let instance;

  ExhibitionApi.getInstance()
    .then((i) => {
      instance = i;

      return instance.remoteEvcDB.query("statistics/evrs", { key });
    })
    .then((evrs) => {
      evrs.rows.map((evr) => {
        instance.remoteEvcDB.remove(evr.id, evr.value._rev);
      });
    });
};

export const pushEvrs = (count) => {
  const countriesTotal = gbCountries.length;
  const divisionsTotal = gbDivisions.length;
  const startDate = new Date("08-01-2017 10:30");
  const endDate = new Date("11-01-2017 02:10");

  for (let i = 0; i < count; i++) {
    ExhibitionApi.getInstance().then((i) => {
      const randomCustomerGroup = Math.floor(Math.random() * (5 - 0 + 1)) + 0;
      const randomCountry =
        Math.floor(Math.random() * (countriesTotal - 1 + 1)) + 0;
      const randomVisitorCount = Math.floor(Math.random() * (100 - 1 + 1)) + 1;
      const randomWasSent = Math.floor(Math.random() * (2 - 0 + 1)) + 0;
      const randomDate = new Date(
        +startDate + Math.random() * (endDate - startDate)
      );
      // const randomDateString = randomDate.getFullYear() + '-' + ('0'+(randomDate.getMonth()+1)).slice(-2) + '-' + ('0' + randomDate.getDate()).slice(-2);
      const randomDivision =
        Math.floor(Math.random() * (divisionsTotal - 1 + 1)) + 0;

      const randomDoc = {
        "@datatype": "forms_dataset",
        archived: false,
        content: {
          _attachments: ["1494779521056.jpg"],
          division: gbDivisions[randomDivision].id,
          frmBericht_add1_mcs_1: "",
          frmBericht_add1_mcs_2: "",
          frmBericht_add1_mcs_3: "",
          frmBericht_add1_mcs_4: "",
          frmBericht_add1_mcs_5: "",
          frmBericht_add1_mcs_6: "",
          frmBericht_add1_mcs_7: "",
          frmBericht_add1_mcs_8: "",
          frmBericht_add1_mcs_9: "",
          imgExhibCard1: "1494779521056.jpg",
          selCountry: gbCountries[randomCountry].id,
          selCustomerGroup: randomCustomerGroup.toString(),
          selFrmBericht_add1_util_1: "0",
          selFrmBericht_add1_util_2: "0",
          selFrmBericht_add1_util_3: "0",
          selFrmBericht_add1_util_4: "0",
          selFrmBericht_add1_util_5: "0",
          selFrmBericht_add1_util_6: "0",
          selFrmBericht_add1_util_7: "0",
          selFrmBericht_add1_util_8: "0",
          selFrmBericht_add1_util_9: "0",
          stdCreationDate: randomDate,
          stdFormName: "frmReport",
          stdTitle: "CAMMELLI",
          stdUserEmail: "ottmar.hailfinger@groz-beckert.com",
          stdUserFullName: "Ottmar Hailfinger",
          stdUserLoginName: "hailfino",
          txtAddress: "Tunis",
          txtCompany: "CMC Tunisie",
          txtDate: randomDate,
          txtExhibition_VisitorName: "Amin Rekik",
          txtExhibition_VisitorsCount: randomVisitorCount.toString(),
          txtExhibition_icon: "techtextil.png",
          txtExhibition_key: "berlin-2017",
          txtExhibition_status: "1",
          txtGPS: "",
          txtMedia_Mail_Body:
            "As discussed during our meeting, attached please find the promised files.\n\nBest regards, \n[salesman]",
          txtMedia_Mail_CustomerName: "",
          txtMedia_Mail_Email: "",
          txtMedia_ani: "",
          txtMedia_bro: "",
          txtStatus: "2",
          txtTOD_Desc_1:
            "Schmetz Händler in Tunesien. \nTraditionsunternehmen, sehr stark. Ca. 2 Mio Schmetz pro Jahr. \nInteresse am Bezug von GB SMN. Habe abgelehnt.\nSieht Möglichkeit an das Geschäft mit dem Militär ranzukommen. ",
          txtTOD_Desc_2: "",
          txtTOD_Desc_3: "",
          txtTOD_Desc_4: "",
          txtTOD_Desc_5: "",
          txtTOD_Desc_6: "",
          txtTOD_Desc_7: "",
          txtTOD_Desc_8: "",
          txtTOD_Subject_1: "Techtextil Frankfurt 2017",
          txtTOD_Subject_2: "",
          txtTOD_Subject_3: "",
          txtTOD_Subject_4: "",
          txtTOD_Subject_5: "",
          txtTOD_Subject_6: "",
          txtTOD_Subject_7: "",
          txtTOD_Subject_8: "",
          txtTOD_Type_1: "General # Exhibition Report",
          txtTOD_Type_2: "",
          txtTOD_Type_3: "",
          txtTOD_Type_4: "",
          txtTOD_Type_5: "",
          txtTOD_Type_6: "",
          txtTOD_Type_7: "",
          txtTOD_Type_8: "",
          txtTimeFrom: "09:28",
          txtTimeTo: "18:28",
        },
        division: gbDivisions[randomDivision].id,
        identifier: "frmReport-1494779534",
        sent: randomWasSent > 0,
        username: "tono",
      };

      i.remoteEvcDB.post(randomDoc);
    });
  }
};
