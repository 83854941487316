export const gbCompanyTypes = [
  {
    'id': '3',
    'name': 'Enduser'
  },
  {
    'id': '2',
    'name': 'Dealer'
  },
  {
    'id': '4',
    'name': 'Agent'
  },
  {
    'id': '1',
    'name': 'Machine Builder'
  },
  {
    'id': '5',
    'name': 'School'
  },
  {
    'id': '0',
    'name': 'Others'
  },
  {
    'id': '6',
    'name': 'Others'
  },
  {
    'id': '7',
    'name': 'Others'
  },
  {
    'id': '8',
    'name': 'Others'
  }
];
