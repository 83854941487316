import React from "react";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import { i18n } from "../../services/i18n";
import { formatDate } from "../../utils/date";
import { Exhibition } from "../../types/exhibition";
import "./exhibitionArchiv.less";
import { Circles } from "react-loader-spinner";
const ExhibitionArchiv = ({
  isLoading,
  list,
}: {
  isLoading: boolean;
  list: {
    acquisition_period: { closed: boolean };
    person_in_charge: string;
    title: string;
    sales_user_list: {
      fullname: string;
      id: string;
    }[];
  }[];
}) => {
  const [searchInput, setSearchInput] = React.useState("");
  const onClearSearch = () => {
    setSearchInput("");
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  let inActiveExhibitions = list.filter((e) => e.acquisition_period.closed);
  inActiveExhibitions = _.sortBy(inActiveExhibitions, "end").reverse();

  // do we need to apply a filter?
  if (searchInput.length) {
    const inActiveExhibitionsFiltered = [];

    for (let i = 0; i < inActiveExhibitions.length; i++) {
      const currentExhibition = inActiveExhibitions[i];
      const title = currentExhibition.title.toLowerCase();
      const personInChargeObject = _.find(currentExhibition.sales_user_list, {
        id: currentExhibition.person_in_charge,
      });
      const personInCharge = personInChargeObject?.fullname.toLowerCase() || "";

      // search for titles that match the searchInput
      if (
        title.includes(searchInput.toLowerCase()) ||
        personInCharge.includes(searchInput.toLowerCase())
      ) {
        inActiveExhibitionsFiltered.push(currentExhibition);
      }
    }

    inActiveExhibitions = inActiveExhibitionsFiltered;
  }

  return (
    <div className="container-exhibitions">
      <div className="hero">
        <h1>{i18n("archiv")}</h1>
      </div>
      <div className="archiv-search">
        <div className="headline">{i18n("inactive_exhibitions")}</div>
        <input
          type="text"
          placeholder={i18n("search_placeholder")}
          value={searchInput}
          onChange={onSearchChange}
        />
        <button
          className={
            searchInput.length !== 0 ? "clear-button active" : "clear-button"
          }
          onClick={onClearSearch}
        >
          {i18n("search_clear")}
        </button>
      </div>
      <div className="inactive-exhibitions">
        {inActiveExhibitions.map((exhibition: any) => (
          <ExhibitionItem
            exhibition={exhibition}
            key={exhibition._id}
            isLoading={isLoading}
          />
        ))}
        {inActiveExhibitions.length === 0 && (
          <div className="none">{i18n("no_exhibitions")}</div>
        )}
      </div>
    </div>
  );
};

const ExhibitionItem = ({
  exhibition,
  isLoading,
}: {
  exhibition: Exhibition;
  isLoading: boolean;
}) => {
  const {
    _id,
    title,
    start,
    end,
    drafts,
    reports,
    person_in_charge,
    sales_user_list,
    acquisition_period,
    ivrs_sent,
  } = exhibition;
  const closing = acquisition_period && acquisition_period.closed && !ivrs_sent;

  // was there a person_in_charge set?
  let inChargeName;
  let inChargeError;
  if (!person_in_charge || person_in_charge.length === 0) {
    inChargeError = i18n("in_charge_not_set");
  } else {
    // try to fetch name of person_in_charge from sales_user_list
    const personInCharge = _.find(sales_user_list, { id: person_in_charge });
    if (!personInCharge || !personInCharge.fullname) {
      inChargeError = i18n("in_charge_unkown");
    } else {
      inChargeName = personInCharge.fullname;
    }
  }

  return (
    <Link to={`/${_id}`}>
      <div className="container-exhibition-item">
        <div className="title">{title}</div>
        <div className="person-in-charge">
          {inChargeError && <span className="error">{inChargeError}</span>}
          {!inChargeError && inChargeName && <span>{inChargeName}</span>}
        </div>
        <div className="details">
          <div className="date">
            {formatDate(start)} - {formatDate(end)}
          </div>
          {isLoading && (
            <div className="kpi">
              <span>
                <Circles height={15} width={150} />
              </span>
            </div>
          )}
          {!isLoading &&
            (closing ? (
              <div className="kpi">
                <span>{i18n("closing_open_reports")}</span>
              </div>
            ) : (
              <div className="kpi">
                <span>
                  {drafts || "0"} {i18n("drafts")}
                </span>{" "}
                {" / "}
                {reports || "0"} {i18n("reports")}
              </div>
            ))}

          <div className="actions">
            <button className="secondary-button">{i18n("details")}</button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ExhibitionArchiv;
