import Papa from "papaparse";
import * as _ from "lodash";
import { i18n } from "./i18n";
import { User } from "../types/user";

/**
 * Maps divisions from non ST-users from CSV-files to internal gb-divisions from database.
 * @type {Object}
 */
const divisionMap = {
  kn: ["K"],
  nw: ["N"],
  we: ["W"],
  tn: ["TN"],
  smn: ["S"],
  cc: ["CC"],
  user_ttk: ["K"],
  default: ["K", "N", "W", "S", "TN", "CC"],
};

/**
 * Parses a given csv-string into an Array of staff-objects.
 *
 * @param  {string}            csvString [CSV as string]
 * @return {Array<UserObject>}           [Parsed UserObjects or errorMessage]
 */
export function getStaffFromCSV(csvString: string) {
  return new Promise((resolve, reject) => {
    if (typeof csvString !== "string") {
      return reject({
        message: i18n("csv_error_input"),
      });
    }

    const csvResult = Papa.parse(csvString);
    // was there an error during parsing?
    if (csvResult.errors && csvResult.errors.length > 0) {
      return reject({
        message: `${i18n("csv_error_syntax")}: ${csvResult.errors[0].message}`,
      });
    }

    // create user-model from csv-data
    const staff = [];
    for (let i = 0; i < csvResult.data.length; i++) {
      const currentRow: string[] = csvResult.data[i];

      // skip header row
      if (currentRow[0] === "GBUid") {
        continue;
      } else if (
        typeof currentRow[0] !== "string" ||
        typeof currentRow[1] !== "string" ||
        typeof currentRow[2] !== "string" ||
        typeof currentRow[4] !== "string"
      ) {
        // skip invalid gbu-ids
        continue;
      }

      // only push needed data
      staff.push({
        id: `org.couchdb.user:${currentRow[0].toLowerCase()}`,
        // firstName: currentRow[1],
        // lastName: currentRow[2],
        fullname: `${currentRow[1]} ${currentRow[2]}`,
        email: currentRow[4] || "not available",
        divisionId: currentRow[5] ? currentRow[5].trim() : null,
      });
    }

    return resolve(staff);
  });
}

export function buildStaffModels(
  csvStaff: User[],
  exhibitionStaff: [],
  users: any
) {
  for (let i = 0; i < csvStaff.length; i++) {
    // check if current csvStaff-user is ST-user or non ST-user
    const stUser: any = _.find(users, { id: csvStaff[i].id });
    if (stUser) {
      // is ST-user, add data from SalesTool
      csvStaff[i].shortname = stUser.value.shortname;
      csvStaff[i].report_to = stUser.value.shortname;
      csvStaff[i].fullname = stUser.value.fullname;
      csvStaff[i].divisions = stUser.value.divisions;
      csvStaff[i].has_st = true;
    } else {
      // no ST-user, add default data
      const gbUid = csvStaff[i].id.replace("org.couchdb.user:", "");
      csvStaff[i].id = `nonst:${gbUid}`;
      csvStaff[i].shortname = gbUid;
      csvStaff[i].divisions =
        divisionMap[csvStaff[i].divisionId.toLowerCase()] ||
        divisionMap.default;
      csvStaff[i].has_st = false;
    }

    // check if current csvStaff-user is alreday linked
    if (exhibitionStaff.indexOf(csvStaff[i].id) !== -1) {
      csvStaff[i].linked = true;
    }
  }

  return csvStaff;
}
