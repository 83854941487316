import en from "../i18n/en.i18n";

const localeMap: any = {
  en,
};

let locale = "en";

export function i18n(key: string) {
  if (key.charAt(0) === key.charAt(0).toUpperCase()) {
    throw new Error("Key must be lowercase");
  }

  if (key in localeMap[locale]) {
    return localeMap[locale][key];
  }

  console.warn(`No Translation for Key: ${key}`);

  return key;
}

export function setLocale(localeString: string) {
  if (localeString in localeMap) {
    locale = localeString;
  } else {
    const [language, territory] = localeString.split(/_/);

    if (language in localeMap) {
      locale = language;
    } else {
      console.log(`Unknown locale: ${localeString}`);
    }
  }
}
