export const gbCountries = [
  {'id':'1', 'name': 'Germany', 'continent': 'Europe'},
  {'id':'104', 'name': 'Egypt', 'continent': 'Africa'},
  {'id':'106', 'name': 'Ethiopia', 'continent': 'Africa'},
  {'id':'108', 'name': 'Afghanistan', 'continent': 'Asia'},
  {'id':'110', 'name': 'Albania', 'continent': 'Europe'},
  {'id':'112', 'name': 'Algeria', 'continent': 'Africa'},
  {'id':'114', 'name': 'Angola', 'continent': 'Africa'},
  {'id':'115', 'name': 'Antigua and Barbuda', 'continent': 'North America'},
  {'id':'116', 'name': 'Argentina', 'continent': 'South America'},
  {'id':'118', 'name': 'Australia', 'continent': 'Oceania'},
  {'id':'120', 'name': 'Bahrain', 'continent': 'Asia'},
  {'id':'121', 'name': 'Barbados', 'continent': 'South America'},
  {'id':'122', 'name': 'Belgium', 'continent': 'Europe'},
  {'id':'124', 'name': 'Bolivia', 'continent': 'South America'},
  {'id':'125', 'name': 'Botswana', 'continent': 'Africa'},
  {'id':'126', 'name': 'Brazil', 'continent': 'South America'},
  {'id':'128', 'name': 'Guyana', 'continent': 'South America'},
  {'id':'132', 'name': 'Bulgaria', 'continent': 'Europe'},
  {'id':'134', 'name': 'Myanmar', 'continent': 'Asia'},
  {'id':'136', 'name': 'Burundi', 'continent': 'Africa'},
  {'id':'138', 'name': 'Canada', 'continent': 'North America'},
  {'id':'140', 'name': 'Sri Lanka', 'continent': 'Asia'},
  {'id':'142', 'name': 'Chile', 'continent': 'South America'},
  {'id':'144', 'name': 'Colombia', 'continent': 'South America'},
  {'id':'146', 'name': 'Costa Rica', 'continent': 'South America'},
  {'id':'148', 'name': 'Cuba', 'continent': 'South America'},
  {'id':'150', 'name': 'Cyprus', 'continent': 'Europe'},
  {'id':'152', 'name': 'Denmark', 'continent': 'Europe'},
  {'id':'154', 'name': 'Benin', 'continent': 'Africa'},
  {'id':'156', 'name': 'Dominican Republic', 'continent': 'South America'},
  {'id':'157', 'name': 'United Arab Emirates', 'continent': 'Asia'},
  {'id':'158', 'name': 'Ecuador', 'continent': 'South America'},
  {'id':'160', 'name': 'Côte dIvoire', 'continent': 'Africa'},
  {'id':'161', 'name': 'Eritrea', 'continent': 'Africa'},
  {'id':'162', 'name': 'El Salvador', 'continent': 'South America'},
  {'id':'163', 'name': 'Fiji', 'continent': 'Asia'},
  {'id':'164', 'name': 'Finland', 'continent': 'Europe'},
  {'id':'166', 'name': 'Taiwan', 'continent': 'Asia'},
  {'id':'168', 'name': 'France', 'continent': 'Europe'},
  {'id':'172', 'name': 'Gabon', 'continent': 'Africa'},
  {'id':'174', 'name': 'Gambia', 'continent': 'Africa'},
  {'id':'175', 'name': 'Palestinian Territory, Occupied', 'continent': 'Asia'},
  {'id':'176', 'name': 'Ghana', 'continent': 'Africa'},
  {'id':'180', 'name': 'Greece', 'continent': 'Europe'},
  {'id':'182', 'name': 'United Kingdom', 'continent': 'Europe'},
  {'id':'184', 'name': 'Guatemala', 'continent': 'South America'},
  {'id':'186', 'name': 'Haiti', 'continent': 'South America'},
  {'id':'188', 'name': 'Honduras', 'continent': 'South America'},
  {'id':'190', 'name': 'Hong Kong', 'continent': 'Asia'},
  {'id':'192', 'name': 'India', 'continent': 'Asia'},
  {'id':'194', 'name': 'Indonesia', 'continent': 'Asia'},
  {'id':'196', 'name': 'Iraq', 'continent': 'Asia'},
  {'id':'198', 'name': 'Iran, Islamic Republic of', 'continent': 'Asia'},
  {'id':'200', 'name': 'Ireland', 'continent': 'Europe'},
  {'id':'202', 'name': 'Iceland', 'continent': 'Europe'},
  {'id':'204', 'name': 'Israel', 'continent': 'Asia'},
  {'id':'206', 'name': 'Italy', 'continent': 'Europe'},
  {'id':'208', 'name': 'Jamaica', 'continent': 'South America'},
  {'id':'210', 'name': 'Japan', 'continent': 'Asia'},
  {'id':'212', 'name': 'Yemen', 'continent': 'Asia'},
  {'id':'214', 'name': 'Jordan', 'continent': 'Asia'},
  {'id':'218', 'name': 'Cambodia', 'continent': 'Asia'},
  {'id':'220', 'name': 'Cameroon', 'continent': 'Africa'},
  {'id':'224', 'name': 'Kenya', 'continent': 'Africa'},
  {'id':'226', 'name': 'Congo, the Democratic Republic of the', 'continent': 'Africa'},
  {'id':'228', 'name': 'Kuwait', 'continent': 'Asia'},
  {'id':'229', 'name': 'Korea, North', 'continent': 'Asia'},
  {'id':'230', 'name': 'Korea, South', 'continent': 'Asia'},
  {'id':'232', 'name': 'Lao Peoples Democratic Republic', 'continent': 'Asia'},
  {'id':'234', 'name': 'Lebanon', 'continent': 'Asia'},
  {'id':'236', 'name': 'Liberia', 'continent': 'Africa'},
  {'id':'238', 'name': 'Libyan Arab Jamahiriya', 'continent': 'Asia'},
  {'id':'240', 'name': 'Luxembourg', 'continent': 'Europe'},
  {'id':'242', 'name': 'Madagascar', 'continent': 'Africa'},
  {'id':'244', 'name': 'Malaysia', 'continent': 'Asia'},
  {'id':'246', 'name': 'Malta', 'continent': 'Europe'},
  {'id':'247', 'name': 'Martinique', 'continent': 'South America'},
  {'id':'248', 'name': 'Morocco', 'continent': 'Africa'},
  {'id':'249', 'name': 'Mauritius', 'continent': 'Africa'},
  {'id':'250', 'name': 'Mexico', 'continent': 'North America'},
  {'id':'252', 'name': 'Mongolia', 'continent': 'Asia'},
  {'id':'254', 'name': 'Mozambique', 'continent': 'Africa'},
  {'id':'256', 'name': 'Nepal', 'continent': 'Asia'},
  {'id':'257', 'name': 'Vanuatu', 'continent': 'Asia'},
  {'id':'258', 'name': 'New Zealand', 'continent': 'Oceania'},
  {'id':'260', 'name': 'Nicaragua', 'continent': 'South America'},
  {'id':'262', 'name': 'Netherlands', 'continent': 'Europe'},
  {'id':'264', 'name': 'Nigeria', 'continent': 'Africa'},
  {'id':'268', 'name': 'Norway', 'continent': 'Europe'},
  {'id':'269', 'name': 'Burkina Faso', 'continent': 'Africa'},
  {'id':'270', 'name': 'Malawi', 'continent': 'Africa'},
  {'id':'272', 'name': 'Austria', 'continent': 'Europe'},
  {'id':'274', 'name': 'Bangladesh', 'continent': 'Asia'},
  {'id':'275', 'name': 'Pakistan', 'continent': 'Asia'},
  {'id':'276', 'name': 'Panama', 'continent': 'North America'},
  {'id':'278', 'name': 'Paraguay', 'continent': 'North America'},
  {'id':'280', 'name': 'Peru', 'continent': 'South America'},
  {'id':'282', 'name': 'Philippines', 'continent': 'Asia'},
  {'id':'284', 'name': 'Poland', 'continent': 'Europe'},
  {'id':'286', 'name': 'Portugal', 'continent': 'Europe'},
  {'id':'288', 'name': 'Guinea-Bissau', 'continent': 'Africa'},
  {'id':'290', 'name': 'Guinea', 'continent': 'Africa'},
  {'id':'292', 'name': 'Zimbabwe', 'continent': 'Africa'},
  {'id':'294', 'name': 'Romania', 'continent': 'Europe'},
  {'id':'295', 'name': 'Zambia', 'continent': 'Africa'},
  {'id':'298', 'name': 'Saudi Arabia', 'continent': 'Asia'},
  {'id':'300', 'name': 'Sweden', 'continent': 'Europe'},
  {'id':'302', 'name': 'Switzerland', 'continent': 'Europe'},
  {'id':'303', 'name': 'Senegal', 'continent': 'Africa'},
  {'id':'304', 'name': 'Sierra Leone', 'continent': 'Africa'},
  {'id':'305', 'name': 'Singapore', 'continent': 'Asia'},
  {'id':'306', 'name': 'Somalia', 'continent': 'Africa'},
  {'id':'307', 'name': 'Slovakia', 'continent': 'Europe'},
  {'id':'308', 'name': 'Spain', 'continent': 'Europe'},
  {'id':'310', 'name': 'Sudan', 'continent': 'Africa'},
  {'id':'312', 'name': 'South Africa', 'continent': 'Africa'},
  {'id':'314', 'name': 'Suriname', 'continent': 'South America'},
  {'id':'316', 'name': 'Syrian Arab Republic', 'continent': 'Asia'},
  {'id':'317', 'name': 'French Polynesia', 'continent': 'South America'},
  {'id':'319', 'name': 'Tanzania, United Republic of', 'continent': 'Africa'},
  {'id':'320', 'name': 'Thailand', 'continent': 'Asia'},
  {'id':'322', 'name': 'Togo', 'continent': 'Africa'},
  {'id':'324', 'name': 'Trinidad and Tobago', 'continent': 'Africa'},
  {'id':'326', 'name': 'Czech Republic', 'continent': 'Europe'},
  {'id':'328', 'name': 'Turkey', 'continent': 'Asia'},
  {'id':'330', 'name': 'Tunisia', 'continent': 'Africa'},
  {'id':'333', 'name': 'Uganda', 'continent': 'Africa'},
  {'id':'334', 'name': 'Hungary', 'continent': 'Europe'},
  {'id':'336', 'name': 'Uruguay', 'continent': 'South America'},
  {'id':'338', 'name': 'United States', 'continent': 'North America'},
  {'id':'340', 'name': 'Venezuela', 'continent': 'South America'},
  {'id':'342', 'name': 'Viet Nam', 'continent': 'Asia'},
  {'id':'344', 'name': 'China', 'continent': 'Asia'},
  {'id':'346', 'name': 'Central African Republic', 'continent': 'Africa'},
  {'id':'348', 'name': 'Armenia', 'continent': 'Europe'},
  {'id':'350', 'name': 'Azerbaijan', 'continent': 'Asia'},
  {'id':'352', 'name': 'Estonia', 'continent': 'Europe'},
  {'id':'354', 'name': 'Georgia', 'continent': 'Asia'},
  {'id':'356', 'name': 'Kazakhstan', 'continent': 'Asia'},
  {'id':'358', 'name': 'Kyrgyzstan', 'continent': 'Asia'},
  {'id':'360', 'name': 'Latvia', 'continent': 'Europe'},
  {'id':'362', 'name': 'Lithuania', 'continent': 'Europe'},
  {'id':'364', 'name': 'Moldova, Republic of', 'continent': 'Europe'},
  {'id':'366', 'name': 'Russian Federation', 'continent': 'Asia'},
  {'id':'368', 'name': 'Tajikistan', 'continent': 'Asia'},
  {'id':'370', 'name': 'Turkmenistan', 'continent': 'Asia'},
  {'id':'372', 'name': 'Ukraine', 'continent': 'Europe'},
  {'id':'374', 'name': 'Uzbekistan', 'continent': 'Asia'},
  {'id':'376', 'name': 'Belarus', 'continent': 'Europe'},
  {'id':'377', 'name': 'Bosnia and Herzegovina', 'continent': 'Europe'},
  {'id':'378', 'name': 'Croatia', 'continent': 'Europe'},
  {'id':'379', 'name': 'Macedonia, the former Yugoslav Republic of', 'continent': 'Europe'},
  {'id':'380', 'name': 'Serbia', 'continent': 'Europe'},
  {'id':'381', 'name': 'Slovenia', 'continent': 'Europe'},
  {'id':'382', 'name': 'Oman', 'continent': 'Asia'},
  {'id':'383', 'name': 'Qatar', 'continent': 'Asia'},
  {'id':'384', 'name': 'Montenegro', 'continent': 'Europe'},
  {'id':'450', 'name': 'Equatorial Guinea', 'continent': 'Africa'},
  {'id':'457', 'name': 'Belize', 'continent': 'South America'},
  {'id':'458', 'name': 'Bermuda', 'continent': 'South America'},
  {'id':'459', 'name': 'Bhutan', 'continent': 'Asia'},
  {'id':'462', 'name': 'Brunei Darussalam', 'continent': 'Asia'},
  {'id':'464', 'name': 'Djibouti', 'continent': 'Africa'},
  {'id':'466', 'name': 'Faroe Islands', 'continent': 'Europe'},
  {'id':'467', 'name': 'Falkland Islands (Malvinas)', 'continent': 'South America'},
  {'id':'468', 'name': 'French Guiana', 'continent': 'South America'},
  {'id':'469', 'name': 'Gibraltar', 'continent': 'Europe'},
  {'id':'470', 'name': 'Grenada', 'continent': 'Europe'},
  {'id':'476', 'name': 'Cayman Islands', 'continent': 'South America'},
  {'id':'483', 'name': 'Congo', 'continent': 'Africa'},
  {'id':'485', 'name': 'Liechtenstein', 'continent': 'Europe'},
  {'id':'487', 'name': 'Macao', 'continent': 'Asia'},
  {'id':'488', 'name': 'Maldives', 'continent': 'Asia'},
  {'id':'489', 'name': 'Mali', 'continent': 'Africa'},
  {'id':'492', 'name': 'Mauritania', 'continent': 'Africa'},
  {'id':'498', 'name': 'Namibia', 'continent': 'Africa'},
  {'id':'502', 'name': 'Niger', 'continent': 'Africa'},
  {'id':'508', 'name': 'Papua New Guinea', 'continent': 'Asia'},
  {'id':'511', 'name': 'Puerto Rico', 'continent': 'South America'},
  {'id':'512', 'name': 'Rwanda', 'continent': 'Africa'},
  {'id':'520', 'name': 'Swaziland', 'continent': 'Africa'},
  {'id':'522', 'name': 'Tonga', 'continent': 'Africa'},
  {'id':'523', 'name': 'Chad', 'continent': 'Africa'}
];
