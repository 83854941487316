import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "groz-beckert-web";
import * as _ from "lodash";
import { Add, Remove } from "react-ionicons";

import { i18n } from "../../services/i18n";
import StaffImport from "../staffImport/staffImport";
import Button from "../../components/button/button";

import { Exhibition, ExhibitionPropType } from "../../types/exhibition";

import "./staffList.less";
import { User } from "../../types/user";
interface Props {
  isReadOnly?: boolean;
  getSTUsers: () => void;
  getSTUserById: (id: string) => Promise<void>;
  refetchExhibitionsAction: () => Promise<void>;
  loadStaffAction: (data: any) => void;
  updateExhibitionAction: (data: any) => void;
  exhibition: Exhibition;
  users: {};
}

interface State {
  isUpdating: boolean;
  showImportModal: boolean;
  staffData: Partial<User>[];
  errorMessage: string;
}
export default class StaffList extends Component<Props, State> {
  static propTypes = {
    isReadOnly: PropTypes.bool,
    getSTUsers: PropTypes.func,
    getSTUserById: PropTypes.func,
    refetchExhibitionsAction: PropTypes.func,
    loadStaffAction: PropTypes.func,
    updateExhibitionAction: PropTypes.func,

    exhibition: ExhibitionPropType,
    users: PropTypes.object,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isUpdating: false,
      showImportModal: false,

      staffData: this.parseStaffData(props.exhibition.sales_staff),
      errorMessage: "",
    };
  }

  componentDidMount() {
    // this.props.loadStaffAction();
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      staffData: this.parseStaffData(nextProps.exhibition.sales_staff),
    });
  }

  parseStaffData(staffIds: string[]) {
    const staffData: Partial<User>[] = [];
    staffIds.map((staffId) => {
      // get additional data from sales_user_list
      const staffUser = _.find(this.props.exhibition?.sales_user_list, {
        id: staffId,
      }) as User;
      if (staffUser) {
        staffData.push({
          id: staffId,
          fullname: staffUser.fullname,
          has_st: staffUser.has_st,
          deactivated: false,
        });
      }
    });

    return staffData;
  }

  checkST(index: number) {
    // give visual feedback
    const staffData = this.state.staffData.slice();
    staffData[index].isChecking = true;
    this.setState({
      staffData,
    });

    const { id } = this.state.staffData[index];
    // strip nonst-label from id (nonst:userName)
    const userId = id.replace("nonst:", "");

    // check if has a salestool-account now
    return this.props
      .getSTUserById(userId)
      .then((user: any) => {
        if (!user && !user.has_st) {
          throw new Error("USER_NOT_FOUND");
        }

        // user has now a salestool-account, update his data in exhibition and
        // push to database
        const exhibition: Exhibition = {
          ...this.props.exhibition,
        };

        // update sales_staff entry
        for (let i = 0; i < exhibition.sales_staff.length; i++) {
          const currentId = exhibition.sales_staff[i];
          if (currentId === id) {
            exhibition.sales_staff[i] = user.id;
            continue;
          }
        }

        // update sales_user_list
        for (let i = 0; i < exhibition.sales_user_list.length; i++) {
          const currentUser = exhibition.sales_user_list[i];
          if (currentUser.id === id) {
            const updatedUser = {
              ...currentUser,
              id: user.id,
              report_to: user.value.shortname,
              fullname: user.value.fullname,
              divisions: user.value.divisions,
              has_st: true,
            };
            exhibition.sales_user_list[i] = updatedUser;
            continue;
          }
        }

        return this.props.updateExhibitionAction(exhibition);
      })
      .catch((error) => {
        if (error.message === "USER_NOT_FOUND") {
          // user has still no salestool-access, nothing has changed
          staffData[index].isChecking = false;
          this.setState({
            staffData,
          });

          return;
        }

        console.error(error);
        alert(error);
      });
  }

  /**
   * Toggles the activation-state of a staff-user. If a staff-user is deactivated he will be
   * unlinked from exhibition on this.onUpdate execution.
   *
   * @param  {number} index     [Array-index of staff-user that will be toggled]
   */
  toggleListItem(index: number) {
    const updatedStaff = this.state.staffData.slice();

    // toggle index
    updatedStaff[index].deactivated = !updatedStaff[index].deactivated;
    this.setState({
      staffData: updatedStaff,
    });
  }

  /**
   * Fetches all staff-users that are marked for unlinking (staff.deativated) and remove them
   * from exhibition.staff_users. Also pushed updates to CouchDB and gives feedback.
   *
   * @return {[type]} [description]
   */
  onUpdate() {
    this.setState({
      isUpdating: true,
    });

    // create a deep copy of staffData, staffData will be updated by refetching the exhibition data
    const staffDataCache = _.clone(this.state.staffData);

    return this.props
      .refetchExhibitionsAction()
      .then(() => {
        const updatedStaff = this.props.exhibition.sales_staff.slice();
        let updatedSales = this.props.exhibition.sales_user_list.slice();
        let updatedPersonInCharge = this.props.exhibition.person_in_charge;

        // get all staff that will be unlinked from exhibition and remove it from exhibition.sales_staff
        for (let i = 0; i < staffDataCache.length; i++) {
          if (staffDataCache[i].deactivated) {
            const position = updatedStaff.indexOf(staffDataCache[i].id);
            if (position !== -1) {
              // unlink
              updatedStaff.splice(position, 1);
              updatedSales = _.reject(updatedSales, {
                id: staffDataCache[i].id,
              });

              // if unlinked user is also person in charge, remove it too
              if (updatedPersonInCharge === staffDataCache[i].id) {
                updatedPersonInCharge = "";
              }
            }
          }
        }

        // update staff in exhibition
        return this.props.updateExhibitionAction({
          _id: this.props.exhibition._id,
          title: this.props.exhibition.title,
          key: this.props.exhibition.key,
          location: this.props.exhibition.location,
          country: this.props.exhibition.country,
          start: this.props.exhibition.start,
          end: this.props.exhibition.end,
          acquisition_period: this.props.exhibition.acquisition_period,
          person_in_charge: updatedPersonInCharge,
          sales_staff: updatedStaff,
          sales_user_list: updatedSales,
        });
      })
      .then(() => {
        // everything went fine
        this.setState({
          isUpdating: false,
        });
      })
      .catch((err) => {
        // show error
        this.setState({
          isUpdating: false,
          errorMessage: err.message,
        });
      });
  }

  /**
   * Counts all already linked staff that will be unlinked on submit.
   *
   * @return {number} [Unlink count]
   */
  getUnlinkedCount() {
    // get all newly unlinked accounts
    let newlyUnlinked = 0;
    for (let i = 0; i < this.state.staffData.length; i++) {
      if (this.state.staffData[i].deactivated) {
        newlyUnlinked++;
      }
    }

    return newlyUnlinked;
  }

  render() {
    const unlinkCount = this.getUnlinkedCount();
    const { isReadOnly, getSTUsers } = this.props;

    return (
      <div
        className={`staff-list ${
          this.props.exhibition.acquisition_period.closed ? "closed" : ""
        }`}
      >
        <div className="staff-list-header">
          <h3>{i18n("staff_list")}</h3>
          {!isReadOnly && (
            <button
              className="primary-button"
              onClick={() => {
                this.setState({ showImportModal: true });
              }}
            >
              {i18n("csv_import")}
            </button>
          )}
        </div>
        {this.state.staffData.map((staff, index) => (
          <div
            className={`staff-item ${staff.deactivated ? "deactivated" : ""}`}
            key={staff.id}
          >
            <div className="staff-id">{index + 1}</div>
            <div className="staff-name">
              {staff.fullname}
              {this.props.users[staff.id] &&
              this.props.users[staff.id].email ? (
                <span>({this.props.users[staff.id].email})</span>
              ) : null}
              {!staff.has_st && (
                <span className="no-st">{i18n("staff_no_st")}</span>
              )}
            </div>
            {!staff.has_st && (
              <Button
                className={`primary-button check-st ${
                  staff.isChecking ? "checking" : ""
                }`}
                loading={staff.isChecking}
                onClick={this.checkST.bind(this, index)}
              >
                {i18n("staff_check_st")}
              </Button>
            )}
            <div className="staff-controls">
              {!isReadOnly && (
                <button
                  className="clear-button"
                  onClick={this.toggleListItem.bind(this, index)}
                >
                  <Remove color="#d1050c" />
                  <Add color="#004f23" />
                </button>
              )}
            </div>
          </div>
        ))}
        {this.state.staffData.length === 0 && (
          <div className="staff-list-empty">{i18n("staff_list_empty")}</div>
        )}
        {this.state.errorMessage && (
          <div className="staff-feedback">{this.state.errorMessage}</div>
        )}
        {this.state.staffData.length !== 0 && !isReadOnly && (
          <div className="staff-submit">
            {unlinkCount !== 0 && (
              <div className="staff-info">
                <span>-{unlinkCount}</span> Staff
              </div>
            )}
            <Button
              className="primary-button"
              onClick={this.onUpdate.bind(this)}
              loading={this.state.isUpdating}
              disabled={unlinkCount === 0}
            >
              {i18n("staff_list_update")}
            </Button>
          </div>
        )}
        <Modal
          withCloseButton
          isVisible={this.state.showImportModal}
          onRequestClose={() => {
            this.setState({ showImportModal: false });
          }}
        >
          <StaffImport
            exhibition={this.props.exhibition}
            getSTUsers={getSTUsers}
            updateExhibitionAction={this.props.updateExhibitionAction}
            refetchExhibitionsAction={this.props.refetchExhibitionsAction}
            onRequestClose={() => {
              this.setState({ showImportModal: false });
            }}
          />
        </Modal>
      </div>
    );
  }
}
