import React from "react";
import { NavLink } from "react-router-dom";
import { i18n } from "../../services/i18n";
import "./navigation.less";
import image from "groz-beckert-web/dist/assets/logo/groz-beckert-int.svg";
interface Props {
  isReadOnly: boolean;
  userName: string;
  logoutAction: () => void;
}
const Navigation = (props: Props) => {
  const { isReadOnly, userName, logoutAction } = props;

  return (
    <div className="component-navigation">
      <div className="container">
        <nav>
          <NavLink to="/">{i18n("overview")}</NavLink>
          <NavLink to="/archiv">{i18n("archiv")}</NavLink>
          {!isReadOnly && <NavLink to="/users">{i18n("evc_users")}</NavLink>}
        </nav>
        <div className="logout">
          <div>
            {i18n("greeting")} <span>{userName}</span>
          </div>
          <button className="primary-button" onClick={logoutAction}>
            Logout
          </button>
        </div>
        <div className="logo">
          <img src={image} />
        </div>
      </div>
    </div>
  );
};
export default Navigation;
