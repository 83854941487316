import React, { Component } from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import Heatmap from "highcharts/modules/heatmap";
import HighchartsReact from "highcharts-react-official";

import "./statisticHeatMap.less";

// Load Highmaps as a module
Heatmap(Highcharts);
interface Props {
  headline: string;
  treeData: [];
  treeX: [];
  treeY: [];
}
export default class StatisticHeatMap extends Component<Props> {
  static propTypes = {
    headline: PropTypes.string,

    treeData: PropTypes.array,
    treeX: PropTypes.array,
    treeY: PropTypes.array,
  };

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { headline, treeData, treeX, treeY } = this.props;

    const config = {
      chart: {
        type: "heatmap",
        marginTop: 40,
        marginBottom: 80,
        plotBorderWidth: 1,
        height: 4000,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: treeX,
        title: null,
      },
      yAxis: {
        categories: treeY,
        title: null,
      },
      colorAxis: {
        min: 0,
        minColor: "#FFFFFF",
        maxColor: Highcharts.getOptions().colors[0],
      },
      legend: {
        align: "right",
        layout: "vertical",
        margin: 0,
        verticalAlign: "top",
        y: 25,
        symbolHeight: 280,
      },
      series: [
        {
          name: "Visitors per Country and Division",
          borderWidth: 1,
          data: treeData,
          dataLabels: {
            enabled: true,
            color: "#000000",
          },
        },
      ],
    };

    return (
      <div className="component-statistic-tree">
        <h4>{headline}</h4>
        <div className="statistic-heat-body">
          <HighchartsReact highcharts={Highcharts} options={config} />
        </div>
      </div>
    );
  }
}
