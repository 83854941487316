export const pad = (nr: number) => {
  const str = String(nr);
  return str.length === 1 ? "0" + str : str;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${pad(date.getDate())}.${pad(
    date.getMonth() + 1
  )}.${date.getFullYear()}`;
};
