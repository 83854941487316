import React, { useEffect } from "react";
import { redirect } from "react-router-dom";

import Login from "../../components/login/login";

const StaffLogin = ({
  doLogin,
}: {
  doLogin: (userName: string, userPassword: string) => Promise<void>;
}) => {
  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);

  const requestLogin = (userName: string, userPassword: string) => {
    return doLogin(userName, userPassword).then(() => {
      setRedirectToReferrer(true);
    });
  };
  useEffect(() => {
    redirect("/");
  }, [redirectToReferrer]);

  return <Login doLogin={requestLogin} />;
};
export default StaffLogin;
