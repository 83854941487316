import React from "react";
import { i18n } from "../../services/i18n";
import { Admin, AdminSchema } from "../../types/admin";
import AdminEdit from "../../components/adminEdit/adminEdit";
import "./adminCreate.less";

const AdminCreate = ({
  createAdminAction,
}: {
  createAdminAction: (model: Admin) => Promise<void>;
}) => {
  const model = AdminSchema.newDefault();

  return (
    <div>
      <h3>{i18n("evc_users_create_admin")}</h3>
      <AdminEdit
        model={model}
        onDoneEditing={(model) => createAdminAction(model)}
        btnText={i18n("create")}
      />
    </div>
  );
};

export default AdminCreate;
