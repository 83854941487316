import React, { useState } from "react";
import NotificationSender from "../../components/notificationSender/notificationSender";
import InChargeAssignment from "../../components/inChargeAssignment/inChargeAssignment";
import StaffList from "../staffList/staffList";
import { i18n } from "../../services/i18n";
import { Master, Detail } from "../../components/masterDetail/masterDetail";
import ExhibitionGeneral from "../exhibitionGeneral/exhibitionGeneral";
import StatisticsVisitors from "../statisticsVisitors/statisticsVisitors";
import StatisticsReports from "../statisticsReports/statisticsReports";
import "./exhibitionDetail.less";
import { useParams } from "react-router-dom";
import { Exhibition } from "../../types/exhibition";

interface Props {
  isReadOnly: boolean;
  getSTUsers: () => void;
  getSTUserById: (id: string) => Promise<void>;
  endAcquisitionPeriodAction: (id: string) => Promise<void>;
  updateExhibitionAction: (exhibition: {}) => Promise<void>;
  refetchExhibitionsAction: () => Promise<void>;
  loadStaffAction: () => void;
  deleteExhibitionAction: (id: string) => Promise<void>;
  pushNotificationAction: (
    exhibitionKey: string,
    notification: string
  ) => Promise<any>;
  getNotificationsAction: (key: string) => Promise<void>;
  getExhibitionExportAction: (id: string) => void;
  getEvrsAction: (id: string) => void;
  users: { [key: string]: any; full_name: string; email: string };
  notifications: [];
  exhibitionsById: {};
}

interface State {
  visitorCount: null | number;
  reportCount: null | number;
}
const ExhibitionDetail = (props: Props) => {
  const params = useParams();
  const [state, setState] = useState<State>({
    visitorCount: null,
    reportCount: null,
  });

  const onVisitorCount = (count: number) => {
    setState({ ...state, visitorCount: count });
  };

  const onReportCount = (count: number) => {
    setState({ ...state, reportCount: count });
  };

  const {
    isReadOnly,
    getSTUsers,
    getSTUserById,
    notifications,
    updateExhibitionAction,
    endAcquisitionPeriodAction,
    deleteExhibitionAction,
    pushNotificationAction,
    getNotificationsAction,
    exhibitionsById,
    getExhibitionExportAction,
  } = props;

  if (!exhibitionsById[params.id]) {
    return <div>{i18n("loading")}...</div>;
  }
  const exhibition: Exhibition = exhibitionsById[params.id];
  const { title } = exhibition;
  return (
    <div>
      <h1>{title}</h1>
      <Master>
        <Detail title={i18n("general")}>
          <ExhibitionGeneral
            isReadOnly={isReadOnly}
            exhibition={exhibition}
            updateExhibitionAction={updateExhibitionAction}
            endAcquisitionPeriodAction={endAcquisitionPeriodAction}
            deleteExhibitionAction={deleteExhibitionAction}
          />
        </Detail>
        <Detail title={i18n("staff")}>
          <>
            <InChargeAssignment
              isReadOnly={isReadOnly}
              users={props.users}
              exhibition={exhibition}
              updateExhibitionAction={props.updateExhibitionAction}
              refetchExhibitionsAction={props.refetchExhibitionsAction}
            />
            <StaffList
              isReadOnly={isReadOnly}
              users={props.users}
              exhibition={exhibition}
              getSTUsers={getSTUsers}
              getSTUserById={getSTUserById}
              updateExhibitionAction={props.updateExhibitionAction}
              refetchExhibitionsAction={props.refetchExhibitionsAction}
              loadStaffAction={props.loadStaffAction}
            />
          </>
        </Detail>
        <Detail title={i18n("notification")}>
          <NotificationSender
            isReadOnly={isReadOnly}
            exhibition={exhibition}
            notifications={notifications}
            getNotificationsAction={getNotificationsAction}
            pushNotificationAction={pushNotificationAction}
          />
        </Detail>
        <Detail title={i18n("reports")} badge={state.reportCount}>
          <StatisticsReports
            exhibition={exhibition}
            exhibitionKey={exhibition.key}
            pushReportCount={onReportCount}
            getEvrsAction={props.getEvrsAction}
          />
        </Detail>
        <Detail title={i18n("visitors")} badge={state.visitorCount}>
          <StatisticsVisitors
            exhibition={exhibition}
            exhibitionKey={exhibition.key}
            pushVisitorCount={onVisitorCount}
            getEvrsAction={props.getEvrsAction}
            getExhibitionExportAction={getExhibitionExportAction}
          />
        </Detail>
      </Master>
    </div>
  );
};

export default ExhibitionDetail;
