import React, { Component } from "react";
import PropTypes from "prop-types";

import Button from "../button/button";
import { Key, Person } from "react-ionicons";

import { i18n } from "../../services/i18n";
import "./login.less";
interface Props {
  doLogin: (userName: string, userPassword: string) => Promise<void>;
}
interface State {
  isLoggingIn: boolean;

  nameInputValue: string;
  passwordInputValue: string;
  errorMessage: string;
}
export default class Login extends Component<Props, State> {
  nameInput = React.createRef<HTMLInputElement>();

  static propTypes = {
    doLogin: PropTypes.func,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoggingIn: false,

      nameInputValue: "",
      passwordInputValue: "",
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.nameInput.current?.focus();
  }

  onChangeNameInput(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      nameInputValue: event.target.value,
      errorMessage: "",
    });
  }

  onChangePasswordInput(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      passwordInputValue: event.target.value,
      errorMessage: "",
    });
  }

  onKeyPressInput(event: React.KeyboardEvent<HTMLInputElement>) {
    // if enter, do submit (when all inputs were filled)
    if (
      event.charCode === 13 &&
      this.state.nameInputValue &&
      this.state.passwordInputValue
    ) {
      event.preventDefault();

      this.onLogin();
    }
  }

  onLogin() {
    this.setState({
      isLoggingIn: true,
    });

    // trigger redirect on login in parent-container or show errorMessage in this component
    return this.props
      .doLogin(this.state.nameInputValue, this.state.passwordInputValue)
      .catch((error) => {
        let errorMessage;
        // error.name from couchdb, error from server-api
        if (
          !error ||
          error.name === "unauthorized" ||
          error === "UNAUTHORIZED"
        ) {
          errorMessage = i18n("login_unauthorized");
        } else {
          errorMessage = error.reason || `${i18n("unkown_error")} [${error}]`;
        }

        this.setState({
          isLoggingIn: false,
          errorMessage: errorMessage,
        });
      });
  }

  render() {
    return (
      <div className="component-login">
        <h1>
          Welcome to <span>EVC</span>
        </h1>

        <div className="login-box">
          <div
            className={`item ${
              this.state.nameInputValue.length > 0 ? "has-value" : ""
            }`}
          >
            <div className="label">
              <Person style={{ width: 15 }} color="#c2c2c2" />
              <span>{i18n("login_name")}</span>
            </div>
            <input
              ref={this.nameInput}
              type="text"
              onChange={this.onChangeNameInput.bind(this)}
              onKeyPress={this.onKeyPressInput.bind(this)}
            />
          </div>

          <div
            className={`item ${
              this.state.passwordInputValue.length > 0 ? "has-value" : ""
            }`}
          >
            <div className="label">
              <Key style={{ width: 15 }} color="#c2c2c2" />
              <span>{i18n("login_password")}</span>
            </div>
            <input
              type="password"
              onChange={this.onChangePasswordInput.bind(this)}
              onKeyPress={this.onKeyPressInput.bind(this)}
            />
          </div>

          {this.state.errorMessage && (
            <div className="login-feedback">{this.state.errorMessage}</div>
          )}

          <div className="login-submit">
            <Button
              className="primary-button"
              onClick={this.onLogin.bind(this)}
              loading={this.state.isLoggingIn}
              disabled={
                !this.state.nameInputValue || !this.state.passwordInputValue
              }
            >
              <span> {i18n("login")}</span>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
