import React, { Component } from "react";
import { i18n } from "../../services/i18n";
import PropTypes from "prop-types";
import { AdminSchema } from "../../types/admin";
import Button from "../button/button";
import "./adminEdit.less";
import { Model } from "./types";

interface Props {
  model?: Model;
  instantValidation?: boolean;
  onDoneEditing?: (model: Model) => Promise<void>;
  onUpdate?: (model: Model) => Promise<void>;
  onDelete?: (model: Model) => Promise<void>;
  btnText?: string;
  nameInput?: string;
  adminName?: string;
}
interface State {
  model: Model;
  errors: [] | null | unknown;
  validation: boolean;
  passwordRepeat: string;
  errorPasswordRepeat: boolean;
  errorResponse: string;
  submitting: boolean;
  submittingSuccess: boolean;
  updatingSuccess: boolean;
  deletingSuccess: boolean;
  nameInput?: string;
  updating?: boolean;
  deleting?: boolean;
}
export default class AdminEdit extends Component<Props, State> {
  static propTypes = {
    model: PropTypes.object,
    instantValidation: PropTypes.bool,
    onDoneEditing: PropTypes.func,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    btnText: PropTypes.string,
    nameInput: PropTypes.string,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      model: Object.assign({}, props.model),
      errors: [],
      validation: props.instantValidation ? props.instantValidation : false,
      passwordRepeat: props.model?.password ? props.model?.password : "",
      errorPasswordRepeat: false,
      errorResponse: "",
      submitting: false,
      submittingSuccess: false,
      updatingSuccess: false,
      deletingSuccess: false,
    };
  }

  applyChange(key: string, value: string) {
    const { validation } = this.state;
    const model = Object.assign({}, this.state.model);

    switch (key) {
      case "name":
        // no spaces allowed in name
        const name = value.replace(/[^A-Za-z]/g, "");

        model["name"] = name;
        model["_id"] = `org.couchdb.user:${name}`;

        this.setState({
          nameInput: name,
        });
        break;
      default:
        model[key] = value;
        break;
    }

    if (validation) {
      try {
        AdminSchema.validate(model);

        this.setState({
          errors: null,
        });
      } catch (errors) {
        this.setState({
          errors,
        });
      }
    }

    this.setState({
      model,
    });
  }

  applyRole(role: string, add: boolean) {
    const model = Object.assign({}, this.state.model);

    if (add) {
      // add role to model
      model.roles.push(role);
    } else {
      // remove role from model
      const index = model.roles.indexOf(role);
      if (index !== -1) {
        model.roles.splice(index, 1);
      }
    }

    this.setState({
      model,
    });
  }

  submit() {
    // don't allow resubmitting, when request is still running
    if (this.state.submitting || this.state.updating || this.state.deleting) {
      return;
    }

    const model = Object.assign(this.state.model);
    try {
      AdminSchema.validate(model);
    } catch (errors) {
      this.setState({
        errors: errors as [],
        validation: true,
      });

      console.error(errors);

      return;
    }

    // check if entered passwords do match
    if (model.password !== this.state.passwordRepeat) {
      this.setState({
        errorPasswordRepeat: true,
      });

      return;
    }

    this.setState({
      submitting: true,
      errorResponse: "",
    });

    return this.props
      .onDoneEditing?.(this.state.model)
      .then(() => {
        this.setState({
          submitting: false,
          submittingSuccess: true,
        });
      })
      .catch((error) => {
        let errorMessage = `${i18n("evc_users_unkown_conflict")} [${
          error.name
        }: ${error.message}]`;
        if (error.name === "conflict" || error === "ADMIN_ALREADY_CREATED") {
          errorMessage = i18n("evc_users_create_conflict");
        } else if (error === "READ_ONLY_NOT_ALLOWED") {
          errorMessage = i18n("permission_error");
        }

        this.setState({
          submitting: false,
          errorResponse: errorMessage,
        });
      });
  }

  update() {
    // don't allow resubmitting, when request is still running
    if (this.state.submitting || this.state.updating || this.state.deleting) {
      return;
    }

    const model = Object.assign(this.state.model);
    try {
      AdminSchema.validate(model);
    } catch (errors) {
      this.setState({
        errors,
        validation: true,
      });

      console.error(errors);

      return;
    }

    // check if entered passwords do match
    if (model.password !== this.state.passwordRepeat) {
      this.setState({
        errorPasswordRepeat: true,
      });

      return;
    }

    this.setState({
      updating: true,
      errorResponse: "",
    });

    return this.props
      .onUpdate?.(this.state.model)
      .then(() => {
        this.setState({
          updating: false,
          updatingSuccess: true,
        });
      })
      .catch((error) => {
        let errorMessage = `${i18n("evc_users_unkown_conflict")} [${
          error.name
        }: ${error.message}]`;
        if (error === "READ_ONLY_NOT_ALLOWED") {
          errorMessage = i18n("permission_error");
        }

        this.setState({
          updating: false,
          errorResponse: errorMessage,
        });
      });
  }

  delete() {
    if (this.state.submitting || this.state.updating || this.state.deleting) {
      return;
    }

    this.setState({
      deleting: true,
      errorResponse: "",
    });

    return this.props
      .onDelete?.(this.state.model)
      .then(() => {
        this.setState({
          deleting: false,
          deletingSuccess: true,
        });
      })
      .catch((error) => {
        let errorMessage = `${i18n("evc_users_unkown_conflict")} [${
          error.name
        }: ${error.message}]`;
        if (error === "READ_ONLY_NOT_ALLOWED") {
          errorMessage = i18n("permission_error");
        }

        this.setState({
          deleting: false,
          errorResponse: errorMessage,
        });
      });
  }

  keyHasError = (errors: any, keys: any): boolean => {
    if (!errors || !errors.got || errors.got.length === 0) {
      return false;
    }

    const key = keys.shift();

    for (let len = errors.got.length, i = 0; i < len; i++) {
      const error = errors.got[i];
      if (error.name === key) {
        if (keys.length !== 0 && error.got) {
          return this.keyHasError(error, keys);
        } else {
          return true;
        }
      }
    }

    return false;
  };

  errorFor = (...keys: any) => {
    if (this.keyHasError(this.state.errors, keys)) {
      return <div className="field-error">{i18n("required_field")}</div>;
    }
    return null;
  };

  errorClass = (...keys: any) =>
    this.keyHasError(this.state.errors, keys) ? "error" : "";

  render() {
    const {
      model,
      submitting,
      updating,
      deleting,
      submittingSuccess,
      updatingSuccess,
      deletingSuccess,
      errorPasswordRepeat,
      errorResponse,
      nameInput,
    } = this.state;
    const { adminName, btnText, onDoneEditing, onDelete, onUpdate } =
      this.props;

    // is read-only?
    let readOnly = true;
    if (model.roles.indexOf("full_admin") !== -1) {
      readOnly = false;
    }

    return (
      <div className="admin-edit" style={{ width: 500 }}>
        <h4>{i18n("general")}</h4>
        <div className={`item ${this.errorClass("name")}`}>
          <div className="label">{i18n("login_name")}</div>
          <input
            type="text"
            onChange={(e) => this.applyChange("name", e.target.value)}
            autoFocus
            value={nameInput}
            defaultValue={model.name}
            tabIndex={1}
          />
          {this.errorFor("login_name")}
        </div>
        <div className={`item ${this.errorClass("full_name")}`}>
          <div className="label">{i18n("full_name")}</div>
          <input
            type="text"
            onChange={(e) => this.applyChange("full_name", e.target.value)}
            defaultValue={model.full_name}
            tabIndex={2}
          />
          {this.errorFor("full_name")}
        </div>
        <div className="item">
          <div className="label">{i18n("evc_users_read_only")}</div>
          <button
            className={`check-button ${readOnly ? "active" : ""}`}
            onClick={() => this.applyRole("full_admin", false)}
          >
            {i18n("yes")}
          </button>
          <button
            className={`check-button ${!readOnly ? "active" : ""}`}
            onClick={() => this.applyRole("full_admin", true)}
          >
            {i18n("no")}
          </button>
        </div>

        <h4>{i18n("login_password")}</h4>
        <div
          className={`item ${this.errorClass("password")} ${
            errorPasswordRepeat ? "error" : ""
          }`}
        >
          <div className="label">{i18n("login_password")}</div>
          <input
            type="password"
            onChange={(e) => this.applyChange("password", e.target.value)}
            defaultValue={model.password}
            tabIndex={3}
          />
        </div>
        <div
          className={`item ${this.errorClass("password")} ${
            errorPasswordRepeat ? "error" : ""
          }`}
        >
          <div className="label">{i18n("login_password_repeat")}</div>
          <input
            type="password"
            onChange={(e) =>
              this.setState({
                passwordRepeat: e.target.value,
                errorPasswordRepeat: false,
              })
            }
            defaultValue={model.password}
            tabIndex={4}
          />
        </div>
        {errorPasswordRepeat && (
          <div className="general-error">{i18n("passwords_not_matching")}</div>
        )}
        {errorResponse && <div className="general-error">{errorResponse}</div>}
        <div className="edit-actions">
          {!submittingSuccess && (
            <Button
              loading={submitting}
              className="primary-button"
              onClick={() => this.submit()}
            >
              <span>{btnText}</span>
            </Button>
          )}
          {!updatingSuccess && !deletingSuccess && (
            <Button
              loading={updating}
              className="primary-button"
              onClick={() => this.update()}
            >
              {i18n("update")}
            </Button>
          )}
          {!updatingSuccess && !deletingSuccess && adminName !== model.name && (
            <Button
              loading={deleting as boolean}
              className="primary-button delete-button"
              onClick={() => this.delete()}
            >
              {i18n("delete")}
            </Button>
          )}
          {submittingSuccess && (
            <div className="success-feedback">
              {i18n("evc_users_submit_success_feedback")}
            </div>
          )}
          {updatingSuccess && (
            <div className="success-feedback">
              {i18n("evc_users_update_success_feedback")}
            </div>
          )}
          {deletingSuccess && (
            <div className="success-feedback">
              {i18n("evc_users_delete_success_feedback")}
            </div>
          )}
        </div>
      </div>
    );
  }
}
