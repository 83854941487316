import "groz-beckert-web/dist/styles/bundle.css";
import "./app.less";
import React, { PureComponent } from "react";
import { Route, Routes, HashRouter as Router } from "react-router-dom";
import ExhibitionApi from "./services/exhibitionApi";
import AdminApi from "./services/adminApi";
import SessionApi from "./services/sessionApi";
import UsersApi from "./services/usersApi";

import Navigation from "./components/navigation/navigation";
import AdminManagement from "./containers/adminManagement/adminManagement";
import ExhibitionList from "./containers/exhibitionList/exhibitionList";
import ExhibitionArchiv from "./containers/exhibitionArchiv/exhibitionArchiv";
import ExhibitionDetail from "./containers/exhibitionDetail/exhibitionDetail";
import NoMatch from "./containers/noMatch/noMatch";

import { i18n } from "./services/i18n";
import Logger from "./services/logger";
import StaffLogin from "./containers/staffLogin/staffLogin";
import StatisticsVisitors from "./containers/statisticsVisitors/statisticsVisitors";
import StatisticsReports from "./containers/statisticsReports/statisticsReports";
import PrivateRouteApp from "./containers/privateRouteApp/PrivateRouteApp";
import { Admin } from "./types/admin";
import { Exhibition } from "./types/exhibition";
import { Session } from "./types/session";
import { Circles } from "react-loader-spinner";

const adminApi = new AdminApi();
const sessionApi = new SessionApi();
const usersApi = new UsersApi();

interface State extends Session {
  isPreparing: boolean;
  isAuthenticated: boolean;
  hasNavigation: boolean;
  adminError: null | string;
  admins: null | Admin[] | undefined;
  exhibitions: [];
  exhibitionsById: {};
  exhibitionsLoading: boolean;
  users: [];
  usersById: {};
  notifications: [];
}

class App extends PureComponent {
  private countsByKey: {
    [key: string]: { drafts: number; reports: number };
  } = {};

  state: State = {
    isPreparing: true,
    isAuthenticated: false,
    isReadOnly: true,
    hasNavigation: false,
    adminError: null,
    userName: null,
    admins: null,
    exhibitions: [],
    exhibitionsById: {},
    exhibitionsLoading: false,
    users: [],
    usersById: {},
    notifications: [],
  };

  resetState = () => {
    this.setState({
      isPreparing: false,
      isAuthenticated: false,
      isReadOnly: true,
      hasNavigation: false,
      adminError: null,
      userName: null,
      admins: null,
      exhibitions: [],
      exhibitionsById: {},
      exhibitionsLoading: false,
      users: [],
      usersById: {},
      notifications: [],
    });
  };

  componentDidMount() {
    let session: Session;
    return sessionApi
      .getSession()
      .then((s) => {
        session = s;
        this.setState({
          isAuthenticated: true,
          isReadOnly: session.isReadOnly,
          userName: session.userName,
          adminError: session.isReadOnly ? i18n("forbidden") : null,
          exhibitionsLoading: true,
          isPreparing: false,
          hasNavigation: true,
        });

        console.log("update exhibitions by mount");
        this.fetch(true);
        this.getStaffUsers();
        if (!session.isReadOnly) {
          this.getAdmins();
        }
      })
      .catch((error) => {
        this.setState({
          exhibitionsLoading: false,
          isPreparing: false,
          hasNavigation: false,
        });
        Logger.error("App.componentDidMount", "UNKOWN_ERROR", error);
      });
  }

  fetch = (updateCounts: boolean = false) => {
    console.log("update exhibitions");
    return ExhibitionApi.getExhibitions()
      .then((exhibitions) => {
        const byId: any = {};
        exhibitions.forEach(
          (exhibition: any) => (byId[exhibition._id] = exhibition),
        );

        // apply cached counts
        exhibitions.forEach((entry) => {
          if (entry.key && this.countsByKey[entry.key] !== undefined) {
            const counts = this.countsByKey[entry.key];
            entry.drafts = counts.drafts;
            entry.reports = counts.reports;
          }
        });

        this.setState({
          exhibitions,
          exhibitionsById: byId,
        });

        // FIXME we fetch counts as side effect and dont wait here cause this request is expensive
        if (updateCounts) {
          ExhibitionApi.getDraftAndReportCountsByKey()
            .then((countsByKey) => {
              const newExhibitions = [...exhibitions];
              this.countsByKey = countsByKey;

              newExhibitions.map((entry) => {
                if (entry.key && countsByKey[entry.key] !== undefined) {
                  const counts = countsByKey[entry.key];
                  entry.drafts = counts.drafts;
                  entry.reports = counts.reports;
                }
              });

              this.setState({
                exhibitions: newExhibitions,
                exhibitionsById: byId,
                exhibitionsLoading: false,
              });
            })
            .catch((error) => {
              Logger.error(
                "App.getDraftAndReportCountsByKey",
                "UNKOWN_ERROR",
                error,
              );

              this.setState({ exhibitionsLoading: false });
            });
        }

        return Promise.resolve();
      })
      .catch((error: any) => {
        Logger.error("App.fetch", "UNKOWN_ERROR", error);
      });
  };

  getNotifications = (key: string) => {
    return ExhibitionApi.getNotificationsByKey(key).then(
      (notifications = []) => {
        this.setState({
          notifications,
        });
      },
    );
  };

  getEvrs = (key: string) => {
    return ExhibitionApi.getEvrsByKey(key);
  };

  getAdmins = () => {
    return adminApi
      .getAdmins()
      .then((admins) => {
        this.setState({
          admins,
        });
      })
      .catch((error) => {
        Logger.error("App.getAdmins", "UNKOWN_ERROR", error);

        this.setState({
          adminError: error,
        });
      });
  };

  getStaffUsers = () => {
    return usersApi.getUsers().then((users) => {
      const usersById: any = {};
      users.map(
        (user: { id: string; value: { fullname: string; email: string } }) => {
          usersById[user.id] = {
            full_name: user.value.fullname,
            email: user.value.email,
          };
        },
      );

      this.setState({
        users,
        usersById,
      });
    });
  };

  getSTUsers = () => {
    return usersApi.getUsers();
  };

  getSTUserById = (userId: string) => {
    return usersApi.getUserById(userId);
  };

  refetchExhibitions = () => {
    console.log("update exhibitions by refetchExhibitions");
    return this.fetch();
  };

  updateExhibition = (exhibition: Exhibition) => {
    return ExhibitionApi.updateExhibition(exhibition).then(() =>
      this.fetch(false),
    );
  };

  createExhihibtion = (exhibition: Exhibition) => {
    return ExhibitionApi.createExhihibtion(exhibition).then(() =>
      this.fetch(false),
    );
  };

  createAdmin = (admin: Admin) => {
    return adminApi.createAdmin(admin);
  };

  updateAdmin = (admin: Admin) => {
    return adminApi.updateAdmin(admin);
  };

  deleteAdmin = (adminName: string) => {
    return adminApi.deleteAdmin(adminName);
  };

  endAcquisitionPeriod = (id: string) => {
    return ExhibitionApi.endAcquisitionPeriod(id).then(() => this.fetch(false));
  };

  deleteExhibition = (id: string) => {
    return ExhibitionApi.deleteExhibition(id).then(() => {
      this.fetch();

      // For some reasons this.setState() is blocking in this.fetch() afer deletion.
      // TODO: find solution for workaround.
      return Promise.resolve();
    });
  };

  getExhibitionExport = (key: string) => {
    return ExhibitionApi.getExhibitionExportByKey(key);
  };

  pushNotification = (exhibitionKey: string, notification: string) => {
    return ExhibitionApi.createNotification(exhibitionKey, notification).then(
      () => {
        return this.getNotifications(exhibitionKey);
      },
    );
  };

  doLogin = (userName: string, userPassword: string) => {
    let session: Session;
    return sessionApi.createSession(userName, userPassword).then((s) => {
      session = s;
      this.setState({
        isAuthenticated: true,
        isReadOnly: session.isReadOnly,
        userName,
        adminError: session.isReadOnly ? i18n("forbidden") : null,
        exhibitionsLoading: true,
        hasNavigation: true,
      });

      this.fetch(true);
      this.getStaffUsers();
      if (!session.isReadOnly) {
        this.getAdmins();
      }
    });
  };

  doLogout = () => {
    return sessionApi
      .destroySession()
      .then(() => this.resetState())
      .catch(() => this.resetState());
  };

  hideNavigation = () => {
    this.setState({ hasNavigation: false });
  };

  render() {
    const {
      isPreparing,
      exhibitions,
      exhibitionsById,
      exhibitionsLoading,
      isReadOnly,
      userName,
    } = this.state;
    return (
      <Router>
        <div className="main-app">
          {this.state.hasNavigation && (
            <Navigation
              isReadOnly={isReadOnly}
              userName={userName as string}
              logoutAction={this.doLogout}
            />
          )}
          <div className="page">
            <div className="container">
              {this.state.isAuthenticated ? (
                <Routes>
                  <Route
                    path="/"
                    element={
                      <ExhibitionList
                        isReadOnly={isReadOnly}
                        isLoading={exhibitionsLoading}
                        list={exhibitions}
                        createAction={this.createExhihibtion}
                      />
                    }
                  />
                  <Route
                    path="/archiv"
                    element={
                      <ExhibitionArchiv
                        isLoading={exhibitionsLoading}
                        list={exhibitions}
                      />
                    }
                  />
                  <Route
                    path="/users"
                    element={
                      <AdminManagement
                        admins={this.state.admins}
                        adminName={this.state.userName}
                        adminError={this.state.adminError}
                        getAdminsAction={this.getAdmins}
                        createAdminAction={this.createAdmin}
                        updateAdminAction={this.updateAdmin}
                        deleteAdminAction={this.deleteAdmin}
                      />
                    }
                  />
                  <Route
                    path="/statistic/visitors/:exhibitionKey"
                    element={
                      <PrivateRouteApp hideNavigation={this.hideNavigation}>
                        <StatisticsVisitors
                          hideExport
                          getEvrsAction={this.getEvrs}
                          getExhibitionExportAction={this.getExhibitionExport}
                        />
                      </PrivateRouteApp>
                    }
                  />
                  <Route
                    path="/statistic/reports/:exhibitionKey"
                    element={
                      <PrivateRouteApp hideNavigation={this.hideNavigation}>
                        <StatisticsReports
                          getEvrsAction={this.getEvrs}
                          getExhibitionExportAction={this.getExhibitionExport}
                        />
                      </PrivateRouteApp>
                    }
                  />
                  <Route
                    path="/:id"
                    element={
                      <ExhibitionDetail
                        isReadOnly={isReadOnly}
                        exhibitionsById={exhibitionsById}
                        users={this.state.usersById}
                        notifications={this.state.notifications}
                        getSTUsers={this.getSTUsers}
                        getSTUserById={this.getSTUserById}
                        endAcquisitionPeriodAction={this.endAcquisitionPeriod}
                        updateExhibitionAction={this.updateExhibition}
                        deleteExhibitionAction={this.deleteExhibition}
                        refetchExhibitionsAction={this.refetchExhibitions}
                        loadStaffAction={this.getStaffUsers}
                        getExhibitionExportAction={this.getExhibitionExport}
                        getEvrsAction={this.getEvrs}
                        pushNotificationAction={this.pushNotification}
                        getNotificationsAction={this.getNotifications}
                      />
                    }
                  />
                  <Route path="*" element={<NoMatch />} />
                </Routes>
              ) : !isPreparing ? (
                <StaffLogin doLogin={this.doLogin} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Router>
    );
  }
}
export default App;
