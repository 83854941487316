import React, { Component } from "react";
import * as _ from "lodash";
import { i18n } from "../../services/i18n";
import moment from "moment";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import { Exhibition, ExhibitionSchema } from "../../types/exhibition";
import Button from "../button/button";

const DatePickerOptions = {
  dateFormat: "dd.MM.yyy",
};

const EXHIBITION_FORMAT = "YYYY-MM-DD";
interface Props {
  model: Exhibition;
  instantValidation: boolean;
  onDoneEditing: (model: any) => Promise<void>;
  btnText: string;
}
interface State {
  model: Exhibition;
  errors: [] | null | unknown;
  validation: boolean;
  submitting?: boolean;
}

export default class ExhibitionEdit extends Component<Props, State> {
  static propTypes = {
    model: PropTypes.object,
    instantValidation: PropTypes.bool,
    onDoneEditing: PropTypes.func,
    btnText: PropTypes.string,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      model: Object.assign({}, props.model),
      errors: [],
      validation: props.instantValidation,
    };
  }

  applyChange(key: string, value: {}) {
    const { validation } = this.state;
    const model = Object.assign(this.state.model);

    switch (key) {
      case "acquisition_period":
        model["acquisition_period"] = {
          ...model["acquisition_period"],
          ...value,
        };
        break;
      default:
        model[key] = value;
        break;
    }

    if (validation) {
      try {
        this.validate(model);

        this.setState({
          errors: null,
        });
      } catch (errors) {
        this.setState({
          errors,
        });
      }
    }

    this.setState({
      model,
    });
  }

  submit() {
    const model = Object.assign(this.state.model);

    try {
      this.validate(model);
    } catch (errors) {
      this.setState({
        errors,
        validation: true,
      });

      console.error(errors);

      return;
    }

    const submittingEnd = () =>
      this.setState({
        submitting: false,
      });

    this.setState({
      submitting: true,
    });

    return this.props
      .onDoneEditing(this.state.model)
      .then(submittingEnd)
      .catch(submittingEnd);
  }

  isValid() {
    const model = Object.assign(this.state.model);
    try {
      this.validate(model);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Model validation function.
   * Dates are manually validated because they depend on each other. -> Not yet supported by Attribute validation lib.
   *
   * @param {object} model ExhibitionSchema model
   */
  validate(model: Exhibition) {
    const modelClone: any = this.prepareModel(model);
    return ExhibitionSchema.validate(modelClone);
  }

  prepareModel(model: Exhibition) {
    const modelClone: any = _.cloneDeep(model);

    const { start, end, acquisition_period } = modelClone;
    const { start: aStart, end: aEnd } = acquisition_period;

    const todayTime = new Date(moment().format(EXHIBITION_FORMAT)).getTime();
    let startTime = todayTime;
    let endTime = todayTime;
    let aStartTime = todayTime;
    let aEndTime = todayTime;

    try {
      startTime = new Date(start).getTime();
    } catch (e) {}
    try {
      endTime = new Date(end).getTime();
    } catch (e) {}
    try {
      aStartTime = new Date(aStart).getTime();
    } catch (e) {}
    try {
      aEndTime = new Date(aEnd).getTime();
    } catch (e) {}

    // adding custom errors
    modelClone._errors = {};

    // end should not be before start
    if (endTime < startTime) {
      modelClone.end = null;
      modelClone._errors.end = "error_end_before_start";
    }
    // acquisition end should not be before acquisition start
    if (aEndTime < aStartTime) {
      modelClone.acquisition_period.end = null;
      modelClone._errors.acquisition_period_end = "error_aqend_before_aqstart";
    }
    // acquisition start should be before start
    if (startTime <= aStartTime) {
      modelClone.acquisition_period.start = null;
      modelClone._errors.acquisition_period_start = "error_aqstart_after_start";
    }
    // acquisition end should be at least 3 days after end
    if (endTime + 3 * 24 * 60 * 60 * 1000 > aEndTime) {
      modelClone.acquisition_period.end = null;
      modelClone._errors.acquisition_period_end =
        modelClone._errors.acquisition_period_end ||
        "error_aq_end_not_3_days_after_end";
    }

    return modelClone;
  }

  keyHasError = (errors: any, keys: []): any => {
    if (!errors || !errors.got || errors.got.length === 0) {
      return false;
    }

    const key = keys.shift();

    for (let len = errors.got.length, i = 0; i < len; i++) {
      const error = errors.got[i];
      if (error.name === key) {
        if (keys.length !== 0 && error.got) {
          return this.keyHasError(error, keys);
        } else {
          return true;
        }
      }
    }

    return false;
  };

  errorFor = (...keys: any) => {
    const isAcquisitionPeriod = keys.includes("acquisition_period");
    const isStart = keys.includes("start");
    const isEnd = keys.includes("end");
    const hasError = this.keyHasError(this.state.errors, keys);
    if (hasError) {
      const modelClone = this.prepareModel(this.state.model);
      const errorStyle = { display: "block", maxWidth: "190px" };
      if (
        isAcquisitionPeriod &&
        isEnd &&
        modelClone._errors.acquisition_period_end
      ) {
        return (
          <div className="field-error" style={{ ...errorStyle }}>
            {i18n(modelClone._errors.acquisition_period_end)}
          </div>
        );
      }
      if (
        isAcquisitionPeriod &&
        isStart &&
        modelClone._errors.acquisition_period_start
      ) {
        return (
          <div className="field-error" style={{ ...errorStyle }}>
            {i18n(modelClone._errors.acquisition_period_start)}
          </div>
        );
      }
      if (isEnd && modelClone._errors.end) {
        return (
          <div className="field-error" style={{ ...errorStyle }}>
            {i18n(modelClone._errors.end)}
          </div>
        );
      }
      return <div className="field-error">{i18n("required_field")}</div>;
    }
    return null;
  };

  errorClass = (...keys: any) =>
    this.keyHasError(this.state.errors, keys) ? "error" : "";

  render() {
    const { model, submitting } = this.state;
    const { btnText } = this.props;

    return (
      <div style={{ width: 500 }}>
        <h4>{i18n("general")}</h4>
        <div className={`item ${this.errorClass("title")}`}>
          <div className="label">{i18n("title")}</div>
          <input
            type="text"
            onChange={(e) => this.applyChange("title", e.target.value)}
            autoFocus
            defaultValue={model.title}
          />
          {this.errorFor("title")}
        </div>
        <div className={`item ${this.errorClass("key")}`}>
          <div className="label">{i18n("key")}</div>
          <input
            type="text"
            onChange={(e) => this.applyChange("key", e.target.value)}
            defaultValue={model.key}
          />
          {this.errorFor("key")}
        </div>
        <div className={`item ${this.errorClass("location")}`}>
          <div className="label">{i18n("location")}</div>
          <input
            type="text"
            onChange={(e) => this.applyChange("location", e.target.value)}
            defaultValue={model.location}
          />
          {this.errorFor("location")}
        </div>
        <div className={`item ${this.errorClass("country")}`}>
          <div className="label">{i18n("country")}</div>
          <input
            type="text"
            onChange={(e) => this.applyChange("country", e.target.value)}
            defaultValue={model.country}
          />
          {this.errorFor("country")}
        </div>
        <div className={`item ${this.errorClass("start")}`}>
          <div className="label">{i18n("exhibition_date_start")}</div>
          <DatePicker
            {...DatePickerOptions}
            selected={model.start ? new Date(model.start) : null}
            onChange={(v) =>
              this.applyChange("start", moment(v).format(EXHIBITION_FORMAT))
            }
          />
          {this.errorFor("start")}
        </div>
        <div className={`item ${this.errorClass("end")}`}>
          <div className="label">{i18n("exhibition_date_end")}</div>
          <DatePicker
            {...DatePickerOptions}
            selected={model.end ? new Date(model.end) : null}
            onChange={(v) =>
              this.applyChange("end", moment(v).format(EXHIBITION_FORMAT))
            }
          />
          {this.errorFor("end")}
        </div>
        <h4>{i18n("acquisition_period")}</h4>
        <div className="help-text">{i18n("acquisition_info")}</div>
        <div
          className={`item ${this.errorClass("acquisition_period", "start")}`}
        >
          <div className="label">{i18n("acquisition_period_start")}</div>
          <DatePicker
            {...DatePickerOptions}
            selected={
              model.acquisition_period.start
                ? new Date(model.acquisition_period.start)
                : null
            }
            onChange={(start) =>
              this.applyChange("acquisition_period", {
                start: !start
                  ? undefined
                  : moment(start).format(EXHIBITION_FORMAT),
              })
            }
          />
          {this.errorFor("acquisition_period", "start")}
        </div>
        <div className={`item ${this.errorClass("acquisition_period", "end")}`}>
          <div className="label">{i18n("acquisition_period_end")}</div>
          <DatePicker
            {...DatePickerOptions}
            selected={
              model.acquisition_period.end
                ? new Date(model.acquisition_period.end)
                : null
            }
            onChange={(end) =>
              this.applyChange("acquisition_period", {
                end: !end ? undefined : moment(end).format(EXHIBITION_FORMAT),
              })
            }
          />
          {this.errorFor("acquisition_period", "end")}
        </div>
        <Button
          loading={submitting}
          className="primary-button"
          onClick={() => this.submit()}
        >
          <span> {btnText}</span>
        </Button>
      </div>
    );
  }
}
