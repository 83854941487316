export const visitorsConfig = {
  chart: {
    zoomType: "x",
  },
  title: {
    text: "",
  },
  xAxis: {
    type: "datetime",
  },
  yAxis: {
    title: {
      text: "# Visitors",
    },
    floor: 0,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    area: {
      animation: false,
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        // stops: [
        //   [0, Highcharts.getOptions().colors[0]],
        //   [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
        // ]
      },
      marker: {
        radius: 2,
      },
      lineWidth: 1,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      threshold: null,
    },
    series: {
      pointInterval: 24 * 3600 * 1000,
    },
  },
  series: [
    {
      type: "area",
      name: "Visitors",
      // data: this.props.graphData,
    },
  ],
};

export const reportsConfig = {
  chart: {
    zoomType: "x",
  },
  title: {
    text: "",
  },
  // subtitle: {
  //   text: document.ontouchstart === undefined ? 'Click zum zoomen' : 'Click zum zoomen'
  // },
  xAxis: {
    type: "datetime",
  },
  yAxis: {
    allowDecimals: false,
    title: {
      text: "# Reports",
    },
    floor: 0,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    area: {
      animation: false,
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        // stops: [
        //   [0, Highcharts.getOptions().colors[0]],
        //   [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
        // ]
      },
      marker: {
        radius: 2,
      },
      lineWidth: 1,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      threshold: null,
    },
    series: {
      pointInterval: 24 * 3600 * 1000,
    },
  },
  series: [
    {
      type: "area",
      name: "Reports",
      // data: data
    },
  ],
};
