export const visitorCountConfig = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.y} Visitors</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        format: "<b>{point.name}</b>: {point.y} Visitors",
        style: {
          // color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
        },
      },
    },
  },
  series: [
    {
      name: "Visitors per Division",
      colorByPoint: true,
      // data: this.props.pieData
    },
  ],
};

export const visitorPercentageConfig = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        style: {
          // color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
        },
      },
    },
  },
  series: [
    {
      name: "Visitors per Division",
      colorByPoint: true,
      // data: data
    },
  ],
};

export const reportCountConfig = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.y} Visitors</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        format: "<b>{point.name}</b>: {point.y} Reports",
        style: {
          // color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
        },
      },
    },
  },
  series: [
    {
      name: "Reports per Division",
      colorByPoint: true,
      // data: this.props.pieData
    },
  ],
};

export const reportPercentageConfig = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        style: {
          // color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
        },
      },
    },
  },
  series: [
    {
      name: "Reports per Division",
      colorByPoint: true,
      // data: data
    },
  ],
};
