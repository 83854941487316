import React, { Component } from "react";
import PropTypes from "prop-types";

import "./statisticTable.less";
interface Props {
  headline: string;
  hasIndex: boolean;
  hasLimit: number;
  tableData: {
    headers: [];
    body: {
      [key: string]: string;
    };
    key: string;
  };
}
export default class StatisticTable extends Component<Props> {
  static propTypes = {
    headline: PropTypes.string,
    hasIndex: PropTypes.bool,
    hasLimit: PropTypes.number,

    tableData: PropTypes.object,
  };

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  generateTableHeader() {
    if (!this.props.tableData.headers) {
      return null;
    }

    const tableHeader = this.props.tableData.headers.map((header) => {
      return (
        <div className="cell header-cell" key={header}>
          {header}
        </div>
      );
    });

    return tableHeader;
  }

  generateTableBody() {
    if (!this.props.tableData.body) {
      return null;
    }

    const tableBody = Object.keys(this.props.tableData.body).map(
      (key, index) => {
        // limit rows according to hasLimit-prop
        if (index + 1 > this.props.hasLimit) {
          return;
        }

        const rows = Object.keys(this.props.tableData.body[key]).map(
          (division) => {
            return (
              <div className="cell" key={`${key}-${division}`}>
                {this.props.tableData.body[key][division]}
              </div>
            );
          }
        );

        // render table-body with index in first column?
        if (this.props.hasIndex) {
          return (
            <div className="table-row" key={key}>
              <div className="cell index-cell">{index + 1}</div>
              <div className="cell">{key}</div>
              {rows}
            </div>
          );
        }

        return (
          <div className="table-row" key={key}>
            <div className="cell">{key}</div>
            {rows}
          </div>
        );
      }
    );

    return tableBody;
  }

  render() {
    const tableHeader = this.generateTableHeader();
    const tableBody = this.generateTableBody();

    return (
      <div className="component-statistics">
        {this.props.headline && <h4>{this.props.headline}</h4>}
        <div className="table table-striped">
          <div className="table-header">{tableHeader && tableHeader}</div>
          <div className="table-body">{tableBody}</div>
        </div>
      </div>
    );
  }
}
