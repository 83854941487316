import React from "react";
import { Circles } from "react-loader-spinner";

interface Props {
  loading?: boolean;
  children?: React.ReactElement;
  className?: string;
  style?: {};
  rest?: any;
  onClick?: () => void;
  disabled?: boolean;
}

const Button = (props: Props) => {
  const { loading, children, className, style, ...rest } = props;
  const loader = (
    <div style={{ position: "relative", width: 27 }}>
      <Circles />
    </div>
  );

  return (
    <button
      {...rest}
      className={`${className || ""} ${loading ? "disabled" : ""}`}
      style={{
        height: 27,
        overflow: "hidden",
        ...style,
      }}
    >
      {!loading && children}
      {loading && loader}
    </button>
  );
};

export default Button;
