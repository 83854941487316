import { Admin } from "../types/admin";
import BaseApi from "./baseApi";

export default class AdminApi extends BaseApi {
  createAdmin(admin: Admin) {
    let readOnly = true;
    if (admin.roles.indexOf("full_admin") !== -1) {
      readOnly = false;
    }

    return this.axiosInstance
      .post("admin", {
        admin,
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return Promise.reject(error.response.data.error);
        }

        return Promise.reject(error);
      });
  }

  updateAdmin(admin: Admin) {
    return this.axiosInstance
      .put("admin", {
        admin,
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return Promise.reject(error.response.data.error);
        }

        return Promise.reject(error);
      });
  }

  deleteAdmin(adminId: string) {
    return this.axiosInstance
      .delete("admin", {
        data: {
          deleteId: adminId,
        },
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return Promise.reject(error.response.data.error);
        }

        return Promise.reject(error);
      });
  }

  getAdmins() {
    return this.axiosInstance
      .get("/admin/list")
      .then((response) => {
        return response.data.admins;
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return Promise.reject(error.response.data.error);
        }

        return Promise.reject(error);
      });
  }
}
