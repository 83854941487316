import * as _ from "lodash";

import { Exhibition } from "../types/exhibition";
import BaseApi from "./baseApi";

/**
 * Service for handling all interaction with the remote-couchdb.
 * Uses singleton-pattern.
 *
 * @type {[type]}
 */
export default class ExhibitionApi extends BaseApi {
  static instance: ExhibitionApi | null = null;

  /**
   * Request a CouchDB-instance.
   *
   * @return {Promise<Instance>} [Class-instance]
   */
  static getInstance(): ExhibitionApi {
    if (!this.instance) {
      this.instance = new ExhibitionApi();
    }

    return this.instance;
  }

  /**
   * Returns all needed data for an export via 'export/overview'-view.
   *
   * @param  {String}         key [Key of exhibition]
   * @return {Array<Object>}     [Array of found documents]
   */
  static async getExhibitionExportByKey(key: string) {
    const response = await this.getInstance().axiosInstance.get(
      `exhibition/${key}/export`,
    );
    return response.data;
  }

  /**
   * List of exhibitions
   * @return {Promise<Array>} Array of exhibitions
   */
  static async getExhibitions() {
    const instance = this.getInstance();

    const response = await instance.axiosInstance.get<{
      exhibitions: Exhibition[];
    }>("exhibitions/list");
    return response.data.exhibitions ?? [];
  }

  /**
   * List of exhibitions
   */
  static async getDraftAndReportCountsByKey() {
    const instance = this.getInstance();
    const response = await instance.axiosInstance.get<{
      [key: string]: { drafts: number; reports: number };
    }>("exhibitions/counts");
    return response.data ?? {};
  }

  static async getEvrsByKey(key: string) {
    const instance = this.getInstance();
    const response = await instance.axiosInstance.get(
      `exhibition/${key}/statistics`,
    );
    return response.data ?? {};
  }

  static createNotification(exhibitionKey: string, notificationText: string) {
    return this.getInstance().axiosInstance.post("exhibition/notification", {
      exhibition_key: exhibitionKey,
      "@datatype": "notification",
      notification_type: "reminder",
      message: notificationText,
      timestamp: new Date(),
    });
  }

  static async getNotificationsByKey(exhibitionKey: string) {
    const instance = this.getInstance();

    const response = await instance.axiosInstance.get<{
      notifications: any[];
    }>(`exhibition/${exhibitionKey}/notifications`);

    return response.data.notifications ?? [];
  }

  /**
   * Ends the acquisition_period for a given exhibition with `id`.
   * @param  {String} id exhibition identifier
   * @return {Promise<Exhibition>} returns the modified Exhibition
   */
  static async endAcquisitionPeriod(id: string): Promise<Exhibition> {
    const response = await this.getInstance().axiosInstance.post<Exhibition>(
      `exhibition/${id}/end_acquisition_period`,
    );

    return response.data;
  }

  /**
   * Creates an exhibition in db
   * @param  {Exhibition} exhibition
   * @return {Promise<Exhibition>}
   */
  static createExhihibtion(exhibition: Exhibition) {
    const e = Object.assign({}, exhibition);
    delete e._id;

    return this.getInstance().axiosInstance.post("exhibition", e);
  }

  /**
   * Deletes the exhibition with the given `id`
   * @param  {String} id exhibition identifier
   * @return {Promise}
   */
  static deleteExhibition(id: string) {
    return this.getInstance().axiosInstance.delete(`exhibition/${id}`);
  }

  /**
   * Updates the exhibition with the given `id`
   * @param  {String} id exhibition identifier
   * @return {Promise}
   */
  static updateExhibition(exhibition: Exhibition) {
    const {
      _id,
      title,
      key,
      location,
      country,
      start,
      end,
      acquisition_period,
      person_in_charge,
      sales_staff,
      sales_user_list,
    } = exhibition;

    return this.getInstance().axiosInstance.put(`exhibition/${_id}`, {
      title,
      key,
      location,
      country,
      start,
      end,
      acquisition_period,
      person_in_charge,
      sales_staff,
      sales_user_list,
    });
  }
}
