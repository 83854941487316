import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Exhibition, ExhibitionPropType } from "../../types/exhibition";
import Button from "../button/button";
import { i18n } from "../../services/i18n";
import "./notificationSender.less";
interface Props {
  isReadOnly?: boolean;
  exhibition: Exhibition;
  notifications?: any[];
  pushNotificationAction?: (
    exhibitionKey: string,
    notificationInput: string
  ) => Promise<any>;
  getNotificationsAction?: (exhibitionKey: string) => Promise<any>;
}

interface State {
  isPushing: boolean;
  notificationInput: string;
  errorMessage: string;
}
export default class NotificationSender extends Component<Props, State> {
  static propTypes = {
    isReadOnly: PropTypes.bool,
    exhibition: ExhibitionPropType,
    notifications: PropTypes.array,
    pushNotificationAction: PropTypes.func,
    getNotificationsAction: PropTypes.func,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isPushing: false,
      notificationInput: this.buildDefaultNotification(),
      errorMessage: "",
    };
  }

  componentDidMount() {
    return this.props.getNotificationsAction?.(this.props.exhibition.key);
  }

  /**
   * Builds the default notificationInput's text.
   *
   * @return {string} [Default notification]
   */
  buildDefaultNotification() {
    const { exhibition } = this.props;
    const daysUntilEnd = this.calculateEndDays();

    // get day string ("8 days"/"1 day")
    let dayString;
    if (daysUntilEnd === 1) {
      dayString = `${daysUntilEnd} ${i18n("notification_day")}`;
    } else {
      dayString = `${daysUntilEnd} ${i18n("notification_days")}`;
    }

    // construct notfication
    const defaultNotification = `${i18n(
      "notification_default"
    )} ${dayString}. ${i18n("notification_default_2")} ${moment(
      exhibition.acquisition_period.end
    ).format("MMMM Do YYYY")}.`;

    return defaultNotification;
  }

  /**
   * Calculates the days from now until the exhibition's end-date is reached.
   *
   * @return {number}     [Days until end-date is reached]
   */
  calculateEndDays() {
    const { exhibition } = this.props;
    const dateNow = moment(Date.now());
    const dateEnd = moment(exhibition.acquisition_period.end);

    return dateEnd.diff(dateNow, "days");
  }

  /**
   * Is fired when the notification-textarea's value was changed.
   * Updates state.notificationInput with new value.
   *
   * @param  {Object} event [React's onChangeEvent result]
   */
  changeNotificationInput(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      notificationInput: event.target.value,
      errorMessage: "",
    });
  }

  /**
   * Is fired when the user clicks the "Send Notification"-button.
   * Does a basic validation of the notificationInput, passes it to parent
   * and shows visual feedback.
   */
  onPushNotification() {
    // validate notification
    if (!this.state.notificationInput) {
      // no message, show error
      this.setState({
        errorMessage: i18n("notification_error"),
      });
      return;
    }

    // show loading feedback
    this.setState({
      isPushing: true,
    });

    // container should push new notification to backend
    this.props
      .pushNotificationAction?.(
        this.props.exhibition.key as string,
        this.state.notificationInput
      )
      .then(() => {
        // everything went well, stop feedback
        this.setState({
          isPushing: false,
        });
      })
      .catch((error) => {
        // show error-message
        this.setState({
          isPushing: false,
          errorMessage: error.message,
        });
      });
  }

  render() {
    const { exhibition, isReadOnly } = this.props;

    return (
      <div className="component-notification-sender">
        {!exhibition["acquisition_period"].closed && !isReadOnly && (
          <div>
            <h4>{i18n("notification_send")}</h4>
            <div className="help-text">{i18n("notification_help")}</div>
            <div className="notification-send">
              <div className="item">
                <textarea
                  className="value"
                  value={this.state.notificationInput}
                  onChange={this.changeNotificationInput.bind(this)}
                />
              </div>
              {this.state.errorMessage && (
                <div className="notification-feedback">
                  {this.state.errorMessage}
                </div>
              )}
              <Button
                loading={this.state.isPushing}
                className="secondary-button"
                onClick={this.onPushNotification.bind(this)}
              >
                {i18n("notification_send")}
              </Button>
            </div>
          </div>
        )}
        <div className="notification-history">
          <h4>{i18n("notification_history")}</h4>
          <div className="notification-history-list">
            {this.props.notifications
              ?.sort(
                (a, b) =>
                  new Date(b.timestamp).getTime() -
                  new Date(a.timestamp).getTime()
              )
              .map((item) => (
                <div className="notification-history-item" key={item.timestamp}>
                  <div className="history-date">
                    {moment(item.timestamp).format("DD.MM.YYYY, HH:mm")}
                  </div>
                  <div className="history-message">{item.message}</div>
                </div>
              ))}

            {this.props.notifications?.length === 0 && (
              <div className="muted">{i18n("notification_none")}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
