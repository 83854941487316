import Highcharts from "highcharts";
import Treemap from "highcharts/modules/treemap";
import HighchartsReact from "highcharts-react-official";

import "./statisticTreeChart.less";

// Load Highmaps as a module
Treemap(Highcharts);

interface Props {
  headline: string;

  treeData: [];
}
const StatisticTreeChart = (props: Props) => {
  const { headline, treeData } = props;
  const config = {
    series: [
      {
        type: "treemap",
        layoutAlgorithm: "squarified",
        allowDrillToNode: true,
        animationLimit: 1000,
        levelIsConstant: false,
        dataLabels: {
          enabled: false,
        },
        levels: [
          {
            level: 1,
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "18px",
              },
            },
            borderWidth: 3,
          },
          {
            level: 2,
            dataLabels: {
              align: "left",
              verticalAlign: "top",
              enabled: true,
            },
            borderWidth: 3,
          },
          {
            level: 3,
            dataLabels: {
              enabled: true,
            },
            borderWidth: 3,
          },
        ],
        opacity: 0,
        data: treeData,
      },
    ],
    subtitle: {
      text: "Click points to drill down.",
    },
    title: {
      text: "",
    },
  };
  return (
    <div className="component-statistic-tree">
      <h4>{headline}</h4>
      <div className="statistic-tree-body">
        <HighchartsReact highcharts={Highcharts} options={config} />
      </div>
    </div>
  );
};

export default StatisticTreeChart;
