export const config =
  process.env.NODE_ENV === "production"
    ? {
        environment: "web",
        api: {
          // server runs on same url in production mode
          url: window.location.origin + "/api",
          version: "v1",
        },
      }
    : {
        environment: "web",
        api: {
          // server runs on different url in development mode
          url: "http://localhost:8080/api",
          version: "v1",
        },
      };
