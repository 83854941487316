import React from "react";
import { i18n } from "../../services/i18n";
import AdminEdit from "../../components/adminEdit/adminEdit";
import "./adminUpdate.less";
import { Admin } from "../../types/admin";
import { Model } from "../../components/adminEdit/types";
interface Props {
  admin: Model | null;
  adminName: string;
  updateAdminAction: (model: Admin) => Promise<void>;
  deleteAdminAction: (model: Model) => Promise<void>;
}
const AdminUpdate = (props: Props) => {
  const { admin, updateAdminAction, deleteAdminAction, adminName } = props;
  return (
    <div>
      <h3>{i18n("evc_users_update_admin")}</h3>
      <AdminEdit
        model={admin as Model}
        adminName={adminName}
        onUpdate={(model) => updateAdminAction(model)}
        onDelete={deleteAdminAction}
        btnText={i18n("update")}
      />
    </div>
  );
};

export default AdminUpdate;
