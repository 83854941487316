import { config } from "../environment";

export default class Logger {
  static instance: Logger | null = null;
  environment: string | null = null;
  logError: {
    (...data: any[]): void;
    (message?: any, ...optionalParams: any[]): void;
  } | null = null;
  logInfo: {
    (...data: any[]): void;
    (message?: any, ...optionalParams: any[]): void;
  } | null = null;
  logDebug = null;

  constructor() {
    const { environment } = config;
    this.environment = environment;

    // check if global logging functions were set (if set app is running on ios)
    // Info:
    if ((window as any).mobileAppLog) {
      this.logInfo = (window as any).mobileAppLog;
    } else {
      this.logInfo = console.log;
    }
    // Error:
    if ((window as any).mobileAppError) {
      this.logError = (window as any).mobileAppError;
    } else {
      this.logError = console.error;
    }

    Logger.instance = this;
    return Promise.resolve(this) as any;
  }

  /**
   * Creates a Logging-instance.
   *
   * @return {Promise<Instance>} [Class-instance]
   */
  static createInstance() {
    return new Logger();
  }

  /**
   * Request a Logger-instance.
   * If not created yet, a Logger-instance is initiated and returned.
   *
   * @return {Promise<Instance>} [Class-instance]
   */
  static getInstance(): any {
    if (!Logger.instance) {
      return Logger.createInstance();
    }

    return Promise.resolve(Logger.instance);
  }

  static error(caller: string, message: string, error: string) {
    return this.getInstance().then((logger: Logger) => {
      const date = new Date().toISOString();

      logger.logError?.(logger.environment, date, caller, message, error);
    });
  }

  static info(caller: string, message: string) {
    return this.getInstance().then((logger: Logger) => {
      const date = new Date().toISOString();

      logger.logInfo?.(logger.environment, date, caller, message);
    });
  }
}
