import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal } from "groz-beckert-web";
import PropTypes from "prop-types";
import * as _ from "lodash";
import { Circles } from "react-loader-spinner";
import { i18n } from "../../services/i18n";
import { formatDate } from "../../utils/date";
import ExhibitionCreate from "../exhibitionCreate/exhibitionCreate";
import Button from "../../components/button/button";
import { Exhibition, ExhibitionPropType } from "../../types/exhibition";
import "./exhibitionList.less";
interface Props {
  isReadOnly: boolean;
  isLoading: boolean;
  createAction: (...args: any[]) => Promise<void>;
  list: {
    acquisition_period: { closed: boolean };
    person_in_charge: string;
    title: string;
    sales_user_list: {
      fullname: string;
      id: string;
    }[];
  }[];
}
interface State {
  modalVisible: boolean;
  showAllInactiave: boolean;
}
export default class Exhibitions extends Component<Props, State> {
  static propTypes = {
    isReadOnly: PropTypes.bool,
    isLoading: PropTypes.bool,
    list: PropTypes.array,
    createAction: PropTypes.func,
  };

  state = {
    modalVisible: false,
    showAllInactiave: false,
  };

  closeModal = () => this.setState({ modalVisible: false });
  openModal = () => this.setState({ modalVisible: true });

  createAction = (...args: any[]) => {
    return this.props.createAction(...args).then(() => {
      this.closeModal();

      return Promise.resolve();
    });
  };

  toggleInactiaveExhibitions() {
    const showAll = !this.state.showAllInactiave;
    this.setState({
      showAllInactiave: showAll,
    });
  }

  render() {
    const { list, isReadOnly, isLoading } = this.props;
    const { modalVisible } = this.state;
    let activeExhibitions = list.filter((e) => !e.acquisition_period.closed);
    let inActiveExhibitions = list.filter((e) => e.acquisition_period.closed);
    activeExhibitions = _.sortBy(activeExhibitions, "start");
    inActiveExhibitions = _.sortBy(inActiveExhibitions, "end").reverse();

    return (
      <div className="container-exhibitions">
        <div className="hero">
          <h1>{i18n("exhibitions")}</h1>
          {!isReadOnly && (
            <button className="primary-button" onClick={this.openModal}>
              {i18n("create_exhibition")}
            </button>
          )}
        </div>
        <h4>{i18n("active_exhibitions")}</h4>
        <div className="active-exhibitions">
          {activeExhibitions.map((exhibition) => (
            <ExhibitionItem
              exhibition={exhibition}
              key={exhibition._id}
              isLoading={isLoading}
            />
          ))}
          {activeExhibitions.length === 0 && (
            <div className="none">{i18n("no_exhibitions")}</div>
          )}
        </div>
        <h4>{i18n("inactive_exhibitions")}</h4>
        <div className="inactive-exhibitions">
          {inActiveExhibitions.map(
            (exhibition, index) =>
              (index < 5 || this.state.showAllInactiave) && (
                <ExhibitionItem
                  exhibition={exhibition}
                  key={exhibition._id}
                  isLoading={isLoading}
                />
              ),
          )}
          {inActiveExhibitions.length > 5 && (
            <div className="show-all">
              <Button
                className="primary-button"
                onClick={this.toggleInactiaveExhibitions.bind(this)}
              >
                {this.state.showAllInactiave ? (
                  <span>{i18n("show_less")}</span>
                ) : (
                  <span>{i18n("show_more")}</span>
                )}
              </Button>
            </div>
          )}
          {inActiveExhibitions.length === 0 && (
            <div className="none">{i18n("no_exhibitions")}</div>
          )}
        </div>
        <Modal
          withCloseButton
          isVisible={modalVisible}
          onRequestClose={this.closeModal}
        >
          <ExhibitionCreate createAction={this.createAction} />
        </Modal>
      </div>
    );
  }
}

const ExhibitionItem = ({
  exhibition,
  isLoading,
}: {
  exhibition: Exhibition;
  isLoading: boolean;
}) => {
  const {
    _id,
    title,
    start,
    end,
    drafts,
    reports,
    person_in_charge,
    sales_user_list,
  } = exhibition;

  // was there a person_in_charge set?
  let inChargeName;
  let inChargeError;
  if (!person_in_charge || person_in_charge.length === 0) {
    inChargeError = i18n("in_charge_not_set");
  } else {
    // try to fetch name of person_in_charge from sales_user_list
    const personInCharge = _.find(sales_user_list, { id: person_in_charge });
    if (!personInCharge || !personInCharge.fullname) {
      inChargeError = i18n("in_charge_unkown");
    } else {
      inChargeName = personInCharge.fullname;
    }
  }

  return (
    <Link to={`/${_id}`}>
      <div className="container-exhibition-item">
        <div className="title">{title}</div>
        <div className="person-in-charge">
          {inChargeError && <span className="error">{inChargeError}</span>}
          {!inChargeError && inChargeName && <span>{inChargeName}</span>}
        </div>
        <div className="details">
          <div className="date">
            {formatDate(start)} - {formatDate(end)}
          </div>

          {isLoading && (
            <div className="kpi">
              <span>
                <Circles height={15} width={150} />
              </span>
            </div>
          )}
          {!isLoading && (
            <div className="kpi">
              <span>
                {drafts || "0"} {i18n("drafts")}
              </span>{" "}
              {" / "}
              {reports || "0"} {i18n("reports")}
            </div>
          )}
          <div className="actions">
            <button className="secondary-button">{i18n("details")}</button>
          </div>
        </div>
      </div>
    </Link>
  );
};

ExhibitionItem.propTypes = {
  exhibition: ExhibitionPropType,
};
