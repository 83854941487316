import React from "react";

interface Props {
  hideNavigation: () => void;
  children?: React.ReactElement;
}

const PrivateRouteApp = (props: Props) => {
  const { children, hideNavigation } = props;

  React.useEffect(() => {
    hideNavigation();
  }, []);

  return children;
};
export default PrivateRouteApp;
