import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "groz-beckert-web";
import { i18n } from "../../services/i18n";
import { formatDate } from "../../utils/date";
import ExhibitionEdit from "../../components/exhibitionEdit/exhibitionEdit";
import Button from "../../components/button/button";
import { ExhibitionPropType, Exhibition } from "../../types/exhibition";
interface Props {
  isReadOnly: boolean;
  endAcquisitionPeriodAction: (id: string) => Promise<void>;
  deleteExhibitionAction: (id: string) => Promise<void>;
  updateExhibitionAction: (exhibition: Exhibition) => Promise<void>;
  exhibition: Exhibition;
}
interface State {
  modalVisible: boolean;
  modalAction: string | null;
  isEditing: boolean;
  loading: boolean;
}

export default class ExhibitionGeneral extends Component<Props, State> {
  static propTypes = {
    isReadOnly: PropTypes.bool,
    endAcquisitionPeriodAction: PropTypes.func,
    deleteExhibitionAction: PropTypes.func,
    updateExhibitionAction: PropTypes.func,
    exhibition: ExhibitionPropType,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      modalVisible: false,
      modalAction: null,
      isEditing: false,
      loading: false,
    };
  }

  completeModalAction = () => {
    const { modalAction } = this.state;

    this.setState({
      loading: true,
    });

    switch (modalAction) {
      case "ending":
        return this.props
          .endAcquisitionPeriodAction(this.props.exhibition._id)
          .then(() => {
            this.setState({
              loading: false,
              modalVisible: false,
            });
          })
          .catch(() => {
            this.setState({
              loading: false,
            });
          });

      case "deleting":
        return this.props
          .deleteExhibitionAction(this.props.exhibition._id)
          .then(() => {
            this.setState({
              loading: false,
              modalVisible: false,
            });

            // go back
            (window as any).location = "/";
          })
          .catch(() => {
            this.setState({
              loading: false,
            });
          });
      default:
    }
  };

  showModalForEnding = () =>
    this.setState({
      modalVisible: true,
      modalAction: "ending",
    });

  showModalForDeleting = () =>
    this.setState({
      modalVisible: true,
      modalAction: "deleting",
    });

  closeModal = () => this.setState({ modalVisible: false });
  enableEdit = () => this.setState({ isEditing: true });
  disableEdit = () => this.setState({ isEditing: false });

  editExhibition = (exhibition: Exhibition) => {
    return this.props.updateExhibitionAction(exhibition).then(() => {
      this.setState({
        isEditing: false,
      });
    });
  };

  render() {
    const { modalVisible, isEditing, modalAction, loading } = this.state;
    const { title, key, location, country, start, end, acquisition_period } =
      this.props.exhibition;
    const { isReadOnly } = this.props;

    if (isEditing) {
      return (
        <ExhibitionEdit
          model={this.props.exhibition}
          instantValidation
          onDoneEditing={this.editExhibition}
          btnText={i18n("save")}
        />
      );
    }

    return (
      <div>
        <h4>{i18n("general")}</h4>
        <div className="item">
          <div className="label">{i18n("title")}</div>
          <div className="value">{title}</div>
        </div>
        <div className="item">
          <div className="label">{i18n("key")}</div>
          <div className="value">{key}</div>
        </div>
        <div className="item">
          <div className="label">{i18n("location")}</div>
          <div className="value">{location}</div>
        </div>
        <div className="item">
          <div className="label">{i18n("country")}</div>
          <div className="value">{country}</div>
        </div>
        <div className="item">
          <div className="label">{i18n("date")}</div>
          <div className="value">
            {formatDate(start)} - {formatDate(end)}
          </div>
        </div>
        <div className="item">
          <div className="label">{i18n("acquisition_period")}</div>
          <div className="value">
            {acquisition_period && !acquisition_period.closed ? (
              <span>
                {formatDate(acquisition_period.start)} -{" "}
                {formatDate(acquisition_period.end)}
              </span>
            ) : (
              <span>{i18n("acquisition_period_closed")}</span>
            )}
          </div>
          {acquisition_period && !acquisition_period.closed && !isReadOnly && (
            <button
              className="secondary-button"
              onClick={this.showModalForEnding}
            >
              {i18n("end_acquisition_period")}
            </button>
          )}
        </div>
        {!acquisition_period.closed && !isReadOnly && (
          <div>
            <button className="secondary-button" onClick={this.enableEdit}>
              {i18n("edit")}
            </button>
            <button
              className="secondary-button"
              onClick={this.showModalForDeleting}
            >
              {i18n("delete")}
            </button>
          </div>
        )}
        <Modal
          isVisible={modalVisible}
          withCloseButton
          onRequestClose={this.closeModal}
        >
          <>
            <div>
              {modalAction === "deleting"
                ? i18n("delete_exhibition_confirm")
                : i18n("end_acquisition_period_confirm")}
            </div>
            <Button
              className="primary-button"
              onClick={this.completeModalAction}
              loading={loading}
            >
              {i18n("confirm")}
            </Button>
            <button className="secondary-button" onClick={this.closeModal}>
              {i18n("cancel")}
            </button>
          </>
        </Modal>
      </div>
    );
  }
}
