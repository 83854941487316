import React, { Component } from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";

import { gbGraphTheme, gbGraphTranslation } from "../../utils/helpers";
import HighchartsReact from "highcharts-react-official";
import { visitorsConfig, reportsConfig } from "./config";
import "./statisticGraph.less";
interface Props {
  headline: string;
  type: string;

  graphData: {}[];
}
export default class StatisticGraph extends Component<Props> {
  static propTypes = {
    headline: PropTypes.string,
    type: PropTypes.string,

    graphData: PropTypes.array,
  };

  constructor(props: Props) {
    super(props);
  }

  render() {
    // set Highcharts options
    Highcharts.setOptions(gbGraphTheme);
    Highcharts.setOptions(gbGraphTranslation);

    const { headline } = this.props;
    let config: any;
    if (this.props.type === "visitor") {
      config = visitorsConfig;
    } else if (this.props.type === "report") {
      config = reportsConfig;
    }

    // set additional data
    config.plotOptions.area.fillColor.stops = [
      [0, Highcharts.getOptions().colors[0]],
      [
        1,
        Highcharts.Color(Highcharts.getOptions().colors[0])
          .setOpacity(0)
          .get("rgba"),
      ],
    ];
    config.series[0].data = this.props.graphData;

    return (
      <div className="component-statistic-graph">
        <h4>{headline}</h4>
        <div className="statistic-graph-body">
          <HighchartsReact highcharts={Highcharts} options={config} />
        </div>
      </div>
    );
  }
}
