import React from "react";
import PropTypes from "prop-types";
import "./exhibitionCreate.less";
import { i18n } from "../../services/i18n";
import { ExhibitionSchema } from "../../types/exhibition";
import ExhibitionEdit from "../../components/exhibitionEdit/exhibitionEdit";
import { Model } from "../../components/adminEdit/types";

const ExhibitionCreate = ({
  createAction,
}: {
  createAction: (model: Model) => void;
}) => {
  const model = ExhibitionSchema.newDefault();

  return (
    <div>
      <h3>{i18n("create_exhibition")}</h3>
      <ExhibitionEdit
        model={model}
        onDoneEditing={(model) => createAction(model)}
        btnText={i18n("create")}
      />
    </div>
  );
};

ExhibitionCreate.propTypes = {
  createAction: PropTypes.func,
};

export default ExhibitionCreate;
