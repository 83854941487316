import React, { ComponentType } from "react";
import { useLocation, useParams, Location } from "react-router-dom";

interface RouteParams {
  [key: string]: string | undefined;
}
interface RouterParams {
  location: Location;
  params: Readonly<Partial<RouteParams>>;
}

const withRouter = (Component: ComponentType<RouterParams>) => {
  const ComponentWithRouter = (props: {
    location?: Location;
    params?: Readonly<Partial<RouteParams>>;
  }) => {
    let params = useParams<RouteParams>();
    const location = useLocation();

    return <Component {...props} location={location} params={params} />;
  };
  return ComponentWithRouter;
};
export default withRouter;
