import React, { Component } from "react";
import PropTypes from "prop-types";
import { i18n } from "../../services/i18n";
import StatisticGraph from "../../components/statisticGraph/statisticGraph";
import StatisticTable from "../../components/statisticTable/statisticTable";
import StatisticPieChart from "../../components/statisticPieChart/statisticPieChart";
import StatisticTreeChart from "../../components/statisticTreeChart/statisticTreeChart";
import StatisticHeatMap from "../../components/statisticHeatMap/statisticHeatMap";
import { ExhibitionPropType } from "../../types/exhibition";
import Logger from "../../services/logger";
import { VisitorsCalculator } from "../../services/statistics";

import ExhibitionExport from "../exhibitionExport/exhibitionExport";
import ExhibitionStatisticsExport from "../exhibitionStatisticsExport/exhibitionStatisticsExport";

import "./statisticsVisitors.less";
import withRouter from "../../components/withRouter";
interface Props {
  getExhibitionExportAction?: (id: string) => void;
  getEvrsAction: (id: string) => void;
  pushVisitorCount: (visitors: number) => void;
  doLogin?: (userName: string, userPassword: string) => Promise<void>;
  hideExport?: boolean;
  exhibition: {};
  params?: {
    exhibitionKey: string;
  };
  exhibitionKey?: string;
}
interface State {
  isLoading: boolean;
  isEmpty: boolean;
  statsCalculated: boolean;
  countryTabActive: string;
  typeTabActive: string;
}
class StatisticsVisitors extends Component<Props, State> {
  static propTypes = {
    hideExport: PropTypes.bool,
    exhibitionKey: PropTypes.string,
    exhibition: ExhibitionPropType,
    pushVisitorCount: PropTypes.func,
    getEvrsAction: PropTypes.func,
    getExhibitionExportAction: PropTypes.func,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      isEmpty: true,
      statsCalculated: false,

      countryTabActive: "global",
      typeTabActive: "global",
    };
  }

  componentDidMount() {
    const exhibitionKey =
      this.props.exhibitionKey ?? this.props.params?.exhibitionKey;
    if (exhibitionKey) {
      this.props
        .getEvrsAction(exhibitionKey)
        .then((evrs) => {
          this.calculateCount(evrs);
          this.calculateStatistics(evrs);
        })
        .catch((error) => {
          Logger.error(
            "StatisticsVisitors.componentDidMount",
            "COUCHDB_EVR_ERROR",
            error,
          );
        });
    }
  }

  componentWillUnmount() {
    this.setState({
      statsCalculated: false,
    });
  }

  calculateCount(evrs: []) {
    if (!this.props.pushVisitorCount) {
      return;
    }

    let visitors = 0;
    evrs.map((evr: { visitors: string }) => {
      visitors += parseInt(evr.visitors);
    });

    this.props.pushVisitorCount(visitors);
  }

  calculateStatistics(evrs: []) {
    try {
      const statistics = new VisitorsCalculator(evrs);

      this.dailyPerDivision = statistics.parseDailyDivison();
      this.dailyTotalGraph = statistics.parseDailyTotalGraph(
        this.dailyPerDivision.body,
      );

      this.topCountries = statistics.parseTopCountries();
      this.topCountriesDivision = statistics.parseTopCountriesPerDivision();

      this.pieTotal = statistics.parsePieTotal(
        this.dailyPerDivision.body["Sum"],
      );

      this.continentTree = statistics.parseContinentTree(
        this.topCountriesDivision,
      );
      this.divisionCountryHeatMap = statistics.parseDivisionCountryHeatMap(
        this.topCountriesDivision,
      );

      this.companyTypes = statistics.parseCompanyType();

      this.setState({
        isLoading: false,
        isEmpty: evrs.length === 0,
        statsCalculated: true,
      });
    } catch (error) {
      Logger.error(
        "StatisticsVisitors.calculateStatistics",
        "CALCULATION_ERROR",
        error,
      );
    }
  }

  generateCompanyTypeTabs(topCountriesDivision: {}) {
    const tabs = Object.keys(topCountriesDivision).map((key) => {
      return (
        <div
          className={`${this.state.typeTabActive === key ? "active" : ""}`}
          onClick={() => this.setState({ typeTabActive: key })}
          key={key}
        >
          {i18n(key.toLowerCase())}
        </div>
      );
    });

    return tabs;
  }

  generateTopCountriesPerDivisionTabs(topCountriesDivision: {
    [key: string]: string;
  }) {
    const tabs = Object.keys(topCountriesDivision).map((key) => {
      const hasValues = Object.keys(topCountriesDivision[key]).length > 0;

      if (hasValues) {
        return (
          <div
            className={`${this.state.countryTabActive === key ? "active" : ""}`}
            onClick={() => this.setState({ countryTabActive: key })}
            key={key}
          >
            {i18n(key.toLowerCase())}
          </div>
        );
      }

      return (
        <div className="disabled" key={key}>
          {i18n(key.toLowerCase())}
        </div>
      );
    });

    return tabs;
  }

  render() {
    // where reports returned from database?
    if (this.state.isLoading) {
      return <h4>{i18n("loading")}...</h4>;
    }

    // are there entrys?
    if (this.state.isEmpty) {
      return <h4>{i18n("empty")}</h4>;
    }

    // where the stats already calculated?
    if (!this.state.statsCalculated) {
      return <h4>{i18n("calculating")}...</h4>;
    }

    // calculate active tabs
    const topCountriesDivisionTabs = this.generateTopCountriesPerDivisionTabs(
      this.topCountriesDivision,
    );
    const companyTypesTabs = this.generateCompanyTypeTabs(
      this.topCountriesDivision,
    );

    const exportData = {
      dailyPerDivision: this.dailyPerDivision,
      dailyTotalGraph: this.dailyTotalGraph,
      topCountries: this.topCountries,
      topCountriesDivision: this.topCountriesDivision,
      //topCountriesDivisionTabs: this.topCountriesDivisionTabs,
      pieTotal: this.pieTotal,
      //continentTree: this.continentTree,
      //divisionCountryHeatMap: this.divisionCountryHeatMap,
      //companyTypes: this.companyTypes,
      //companyTypesTabs: this.companyTypesTabs,
    };
    return (
      <div className="container-exhibition-statistics">
        {!this.props.hideExport && (
          <div className="export-buttons">
            <ExhibitionExport
              exhibitionId={
                this.props.exhibitionKey
                  ? this.props.exhibitionKey
                  : this.props.params?.exhibitionKey
              }
              getExhibitionExportAction={this.props.getExhibitionExportAction}
            />
            <ExhibitionStatisticsExport
              exhibition={this.props.exhibition}
              statisticsData={exportData}
              exhibitionId={
                this.props.exhibitionKey
                  ? this.props.exhibitionKey
                  : this.props.params?.exhibitionKey
              }
              exportType="visitors"
            />
          </div>
        )}

        <div className="statistics-report">
          <StatisticGraph
            headline={i18n("statistic_visitors_exhibition")}
            type="visitor"
            graphData={this.dailyTotalGraph}
          />
        </div>

        <div className="statistics-report">
          <StatisticTable
            headline={i18n("statistic_visitors_daily_per_division")}
            hasIndex={false}
            tableData={this.dailyPerDivision}
          />
        </div>

        <div className="statistics-report">
          <h4>{i18n("statistic_top_10_countries")}</h4>
          <div className="statistics-tabs">
            <div
              className={`${
                this.state.countryTabActive === "global" ? "active" : ""
              }`}
              onClick={() => this.setState({ countryTabActive: "global" })}
            >
              Global
            </div>
            {topCountriesDivisionTabs}
          </div>
          <div className="statistics-tabs-content">
            {this.state.countryTabActive === "global" && (
              <StatisticTable
                hasIndex={true}
                hasLimit={10}
                tableData={this.topCountries}
              />
            )}
            {this.state.countryTabActive === "K" && (
              <StatisticTable
                hasIndex={true}
                hasLimit={10}
                tableData={{ body: this.topCountriesDivision["K"] }}
              />
            )}
            {this.state.countryTabActive === "W" && (
              <StatisticTable
                hasIndex={true}
                hasLimit={10}
                tableData={{ body: this.topCountriesDivision["W"] }}
              />
            )}
            {this.state.countryTabActive === "N" && (
              <StatisticTable
                hasIndex={true}
                hasLimit={10}
                tableData={{ body: this.topCountriesDivision["N"] }}
              />
            )}
            {this.state.countryTabActive === "TN" && (
              <StatisticTable
                hasIndex={true}
                hasLimit={10}
                tableData={{ body: this.topCountriesDivision["TN"] }}
              />
            )}
            {this.state.countryTabActive === "CC" && (
              <StatisticTable
                hasIndex={true}
                hasLimit={10}
                tableData={{ body: this.topCountriesDivision["CC"] }}
              />
            )}
            {this.state.countryTabActive === "S" && (
              <StatisticTable
                hasIndex={true}
                hasLimit={10}
                tableData={{ body: this.topCountriesDivision["S"] }}
              />
            )}
            {this.state.countryTabActive === "X" && (
              <StatisticTable
                hasIndex={true}
                hasLimit={10}
                tableData={{ body: this.topCountriesDivision["X"] }}
              />
            )}
          </div>
        </div>

        <div className="statistics-report">
          <StatisticPieChart
            headline={i18n("statistic_visitors_division_total_percentage")}
            type="visitor-percentage"
            pieData={this.pieTotal}
          />
        </div>

        <div className="statistics-report">
          <StatisticPieChart
            headline={i18n("statistic_visitors_division_total_count")}
            type="visitor-count"
            pieData={this.pieTotal}
          />
        </div>

        <div className="statistics-report">
          <StatisticTreeChart
            headline={i18n("statistic_visitors_continent_country")}
            treeData={this.continentTree}
          />
        </div>

        <div className="statistics-report">
          <h4>{i18n("statistic_visitors_company_type")}</h4>
          <div className="statistics-tabs">
            <div
              className={`${
                this.state.typeTabActive === "global" ? "active" : ""
              }`}
              onClick={() => this.setState({ typeTabActive: "global" })}
            >
              Global
            </div>
            {companyTypesTabs}
          </div>
          <div className="statistics-tabs-content">
            {this.state.typeTabActive === "global" && (
              <StatisticTable
                hasIndex={false}
                tableData={{
                  headers: this.companyTypes.headers,
                  body: this.companyTypes.body["global"],
                }}
              />
            )}
            {this.state.typeTabActive === "K" && (
              <StatisticTable
                hasIndex={false}
                tableData={{
                  headers: this.companyTypes.headers,
                  body: this.companyTypes.body["K"],
                }}
              />
            )}
            {this.state.typeTabActive === "W" && (
              <StatisticTable
                hasIndex={false}
                tableData={{
                  headers: this.companyTypes.headers,
                  body: this.companyTypes.body["W"],
                }}
              />
            )}
            {this.state.typeTabActive === "N" && (
              <StatisticTable
                hasIndex={false}
                tableData={{
                  headers: this.companyTypes.headers,
                  body: this.companyTypes.body["N"],
                }}
              />
            )}
            {this.state.typeTabActive === "TN" && (
              <StatisticTable
                hasIndex={false}
                tableData={{
                  headers: this.companyTypes.headers,
                  body: this.companyTypes.body["TN"],
                }}
              />
            )}
            {this.state.typeTabActive === "CC" && (
              <StatisticTable
                hasIndex={false}
                tableData={{
                  headers: this.companyTypes.headers,
                  body: this.companyTypes.body["CC"],
                }}
              />
            )}
            {this.state.typeTabActive === "S" && (
              <StatisticTable
                hasIndex={false}
                tableData={{
                  headers: this.companyTypes.headers,
                  body: this.companyTypes.body["S"],
                }}
              />
            )}
            {this.state.typeTabActive === "X" && (
              <StatisticTable
                hasIndex={false}
                tableData={{
                  headers: this.companyTypes.headers,
                  body: this.companyTypes.body["X"],
                }}
              />
            )}
          </div>
        </div>

        <div className="statistics-report">
          <StatisticHeatMap
            headline={i18n("statistic_visitors_division")}
            treeData={this.divisionCountryHeatMap.data}
            treeX={this.divisionCountryHeatMap.x}
            treeY={this.divisionCountryHeatMap.y}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(StatisticsVisitors as any);
