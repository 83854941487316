import PropTypes from "prop-types";
import A, { Attribute } from "../utils/model/attribute";
import { pad } from "../utils/date.js";
import { User } from "./user";

const ExhibitionDate = new Attribute(
  (x) => toString.call(x) === "[object String]" && /\d{4}-\d{2}-\d{2}/g.test(x),
  () => {
    const a = new Date();
    return `${a.getFullYear()}-${pad(a.getMonth() + 1)}-${pad(a.getDate())}`;
  }
);
ExhibitionDate.name = "ExhibitionDate";

const ExhibitionSchema = A.schema({
  _id: A.optional.string,
  key: A.required.nonemptyString.defaultsTo(""),
  "@datatype": A.required.string.defaultsTo("exhibition"),
  title: A.required.nonemptyString.defaultsTo(""),
  country: A.required.nonemptyString.defaultsTo(""),
  location: A.required.nonemptyString.defaultsTo(""),
  start: ExhibitionDate,
  end: ExhibitionDate,
  acquisition_period: {
    start: ExhibitionDate,
    end: ExhibitionDate,
    closed: A.required.boolean.defaultsTo(false),
  },
  person_in_charge: A.required.string.defaultsTo(""),
  sales_staff: A.required.array.defaultsTo(["org.couchdb.user:u1100407"]),
  sent_notifications: A.required.array.defaultsTo([]),
});

const ExhibitionPropType = PropTypes.shape({
  _id: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string,
  country: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  acquisition_period: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    closed: PropTypes.bool,
  }),
  person_in_charge: PropTypes.string,
  sales_staff: PropTypes.array,
});

export interface Exhibition {
  _id?: string;
  title: string;
  key?: string;
  id?: string;
  "@datatype"?: string;
  sent_notifications?: [];
  location: string;
  country: string;
  start: string;
  end: string;
  acquisition_period: {
    start: string;
    end: string;
    closed: boolean;
  };
  person_in_charge: string;
  sales_user_list: User[];
  sales_staff: string[];
  ivrs_sent?: boolean;
  drafts?: number;
  reports?: number;
}

export { ExhibitionSchema, ExhibitionPropType };
