import React from "react";
import "./masterDetail.less";
import { Circles } from "react-loader-spinner";

interface Props {
  children: React.ReactElement;
  badge?: number | null;
  title: string;
}
export const Master = ({ children }: { children: React.ReactElement }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  return (
    <div className="component-master-detail">
      <aside>
        <ul>
          {React.Children.map(children, (c, index) => (
            <li
              className={`${index === activeIndex ? "active" : ""}`}
              onClick={() => setActiveIndex(index)}
              key={`${c.props.title}`}
            >
              {c.props.title}
              {c.props.badge === null && (
                <div style={{ position: "relative", width: 27 }}>
                  <Circles />
                </div>
              )}
              {typeof c.props.badge === "number" && (
                <span>{c.props.badge}</span>
              )}
            </li>
          ))}
        </ul>
      </aside>
      <div className="master">
        {React.Children.map(children, (c, index) => (
          <main
            style={{
              opacity: index === activeIndex ? "1" : "0",
              zIndex: index === activeIndex ? "2" : "1",
            }}
            key={`${c.props.title}`}
          >
            {c}
          </main>
        ))}
      </div>
    </div>
  );
};

export const Detail = (props: Props) => (
  <div className="detail">{props.children}</div>
);
