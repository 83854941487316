import React, { Component } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";

import Logger from "../../services/logger";
import { i18n } from "../../services/i18n";
import { getStaffFromCSV, buildStaffModels } from "../../services/csv";
import "./staffImport.less";

import Button from "../../components/button/button";
import Tabs from "../../components/tabs/tabs";
import StaffAssignment from "../../components/staffAssignment/staffAssignment";

import { ExhibitionPropType, Exhibition } from "../../types/exhibition";
import { User } from "../../types/user";

/**
 * Container for handling the import of staff via CSV-file.
 *
 * @type {Object}
 *
 */
interface Props {
  getSTUsers?: () => void;
  refetchExhibitionsAction: () => Promise<void>;
  updateExhibitionAction?: (exibition: Exhibition) => void;
  onRequestClose?: () => void;

  exhibition: Exhibition;
}

interface State {
  isParsing: boolean;
  activeId: string;
  tabs: { id: string; disabled: boolean }[];
  csvInput: string;
  staffData: any;
  errorMessage: string;
}

export default class StaffImport extends Component<Props, State> {
  static propTypes = {
    getSTUsers: PropTypes.func,
    refetchExhibitionsAction: PropTypes.func,
    updateExhibitionAction: PropTypes.func,
    onRequestClose: PropTypes.func,

    exhibition: ExhibitionPropType,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isParsing: false,

      activeId: "csv_import",
      tabs: [
        { id: "csv_import", disabled: false },
        { id: "staff", disabled: true },
      ],
      csvInput: "",
      staffData: null,
      errorMessage: "",
    };
  }

  /**
   * Is fired when the csv-textarea's value was changed.
   * Updates state.csvInput with value.
   *
   * @param  {Object} event [React's onChangeEvent result]
   */
  changeCSVInput(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      csvInput: event.target.value,
    });
  }

  /**
   * Is fired from Tabs-child-component when a tab-item was clicked.
   * Updates state.activeId with handed tabId.
   *
   * @param  {string} tabId [Clicked id from state.tabs]
   */
  changeActiveTab(tabId: string) {
    this.setState({
      activeId: tabId,
    });
  }

  /**
   * Is executed when the user submits the entered csv from textarea.
   * State.csvInput will be validated and parsed. If data was parsed successfully
   * state.staffData is updated with extracted staff-objects and the tab is switched
   * to 'staff' (where the state.staffData will be presented).
   */
  parseCSV() {
    // validate csv
    if (!this.state.csvInput) {
      return this.setState({
        errorMessage: i18n("csv_error_input"),
      });
    }

    // give parsing feedback
    this.setState({
      isParsing: true,
    });

    // parse csv and extract staff-users
    let csvStaff: any;
    return getStaffFromCSV(this.state.csvInput)
      .then((cf) => {
        csvStaff = cf;

        return this.props.refetchExhibitionsAction();
      })
      .then(() => this.props.getSTUsers?.())
      .then((users) =>
        buildStaffModels(csvStaff, this.props.exhibition.sales_staff, users)
      )
      .then((staff) => {
        // show staffAssignment-component
        const tabs = this.state.tabs.slice();
        tabs[1].disabled = false;
        this.setState({
          isParsing: false,

          activeId: "staff",
          tabs: tabs,
          staffData: staff,
          errorMessage: "",
        });
      })
      .catch((error) => {
        Logger.error("StaffImport.parseCSV", "CSV_PARSING_ERROR", error);

        // oh no, error during csv-parsing
        this.setState({
          errorMessage: error.message,
        });
      });
  }

  saveAssignment(notLinked: User[], alreadyLinked: User[]) {
    return this.props
      .refetchExhibitionsAction()
      .then(() => {
        const updatedStaff = this.props.exhibition.sales_staff
          ? this.props.exhibition.sales_staff.slice()
          : [];
        let updatedSales = this.props.exhibition.sales_user_list
          ? this.props.exhibition.sales_user_list.slice()
          : [];
        // hold a reference of person in charge to check if an unlinked user is the current
        // person in charge
        let updatedPersonInCharge = this.props.exhibition.person_in_charge;

        // get all staff that will be linked to exhibition and push it to exhibition
        for (let i = 0; i < notLinked.length; i++) {
          if (!notLinked[i].deactivated) {
            updatedStaff.push(notLinked[i].id);
            updatedSales.push({
              id: notLinked[i].id,
              shortname: notLinked[i].shortname,
              report_to: notLinked[i].report_to || null,
              fullname: notLinked[i].fullname,
              divisions: notLinked[i].divisions,
              has_st: notLinked[i].has_st,
            });
          }
        }

        // get all staff that will be unlinked from exhibition and remove it from array
        for (let i = 0; i < alreadyLinked.length; i++) {
          if (alreadyLinked[i].deactivated) {
            const position = updatedStaff.indexOf(alreadyLinked[i].id);
            updatedStaff.splice(position, 1);
            updatedSales = _.reject(updatedSales, { id: alreadyLinked[i].id });

            // if unlinked user is also person in charge, remove it too
            if (updatedPersonInCharge === alreadyLinked[i].id) {
              updatedPersonInCharge = "";
            }
          }
        }

        return this.props.updateExhibitionAction?.({
          _id: this.props.exhibition._id,
          title: this.props.exhibition.title,
          key: this.props.exhibition.key,
          location: this.props.exhibition.location,
          country: this.props.exhibition.country,
          start: this.props.exhibition.start,
          end: this.props.exhibition.end,
          acquisition_period: this.props.exhibition.acquisition_period,
          person_in_charge: updatedPersonInCharge,
          sales_staff: updatedStaff,
          sales_user_list: updatedSales,
        });
      })
      .then(() => {
        // close the import modal
        this.props.onRequestClose?.();
      });
  }

  render() {
    return (
      <div className="staff-import">
        <Tabs
          items={this.state.tabs}
          activeId={this.state.activeId}
          setActive={this.changeActiveTab.bind(this)}
        />
        {(() => {
          switch (this.state.activeId) {
            case "csv_import":
              return (
                <div className="staff-import-csv tab-content">
                  <textarea
                    placeholder={i18n("csv_import_placeholder")}
                    value={this.state.csvInput}
                    onChange={this.changeCSVInput.bind(this)}
                  />
                  {this.state.errorMessage && (
                    <div className="csv-feedback">
                      {this.state.errorMessage}
                    </div>
                  )}
                  <Button
                    className="primary-button"
                    onClick={this.parseCSV.bind(this)}
                    loading={this.state.isParsing}
                    disabled={!this.state.csvInput}
                  >
                    {i18n("csv_import_button")}
                  </Button>
                </div>
              );
            case "staff":
              return (
                <div className="staff-import-result tab-content">
                  <StaffAssignment
                    staffData={this.state.staffData}
                    saveAssignment={this.saveAssignment.bind(this)}
                  />
                </div>
              );
            default:
              null;
          }
        })()}
      </div>
    );
  }
}
