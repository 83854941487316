import BaseApi from "./baseApi";

export default class UsersApi extends BaseApi {
  baseUrl: string | null = null;

  getUsers() {
    return this.axiosInstance.get("users").then((response) => {
      const { users } = response.data;
      return users ?? [];
    });
  }

  getUserById(userId: string) {
    return this.axiosInstance
      .get(`users/${userId}`)
      .then((response) => response.data.user)
      .catch((errorResponse) => {
        // return error-message from server if there was one pushed
        const { error } = errorResponse.response.data;
        if (error) {
          throw new Error(error);
        } else {
          throw new Error(errorResponse);
        }
      });
  }
}
