import React, { Component } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import { Modal } from "groz-beckert-web";
import Button from "../button/button";
import { Checkmark } from "react-ionicons";

import { i18n } from "../../services/i18n";
import "./inChargeAssignment.less";

import { Exhibition, ExhibitionPropType } from "../../types/exhibition";
interface State {
  showAssignmentModal: boolean;
  isAssigning?: boolean;
  staffData: any[];
  filterInput: string;
  errorMessage: string;
}
interface Props {
  isReadOnly: boolean;
  users: {
    [key: string]: any;
    full_name: string;
    email: string;
  };
  exhibition: Exhibition;

  updateExhibitionAction: (exhibition: Exhibition) => Promise<void>;
  refetchExhibitionsAction: () => Promise<void>;
}

export default class InChargeAssignment extends Component<Props, State> {
  static propTypes = {
    isReadOnly: PropTypes.bool,
    users: PropTypes.object,
    exhibition: ExhibitionPropType,

    updateExhibitionAction: PropTypes.func,
    refetchExhibitionsAction: PropTypes.func,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      showAssignmentModal: false,
      isAssigning: false,

      staffData: this.parseStaffData(props.exhibition.sales_staff),
      filterInput: "",
      errorMessage: "",
    };
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      staffData: this.parseStaffData(nextProps.exhibition.sales_staff),
    });
  }

  parseStaffData(staffIds: any[]) {
    const staffData: {}[] = [];
    staffIds.map((staffId) => {
      staffData.push({
        id: staffId,
        checked: false,
      });
    });

    return staffData;
  }

  getViewableStaffData() {
    const staffIds = this.props.exhibition.sales_staff;
    const users = this.props.users;
    const filterString = this.state.filterInput;

    const viewableStaffData: any = [];
    // console.log(this.state.staffData);
    staffIds.map((staffId) => {
      // is the current staffId available in userDb?
      const userData = users[staffId];
      if (userData) {
        // get checked-state of staff
        const state = _.find(this.state.staffData, { id: staffId });

        // filter from input
        if (filterString.length > 0) {
          const filterValue = users[staffId].full_name.toLowerCase();
          if (filterValue.includes(filterString.toLowerCase())) {
            viewableStaffData.push({
              id: staffId,
              full_name: users[staffId].full_name,
              email: users[staffId].email,
              checked: state?.checked,
            });
          }
        } else {
          viewableStaffData.push({
            id: staffId,
            full_name: users[staffId].full_name,
            email: users[staffId].email,
            checked: state?.checked,
          });
        }
      }
    });

    return viewableStaffData;
  }

  hasChecked(defaultChecked?: string) {
    for (let i = 0; i < this.state.staffData.length; i++) {
      if (this.state.staffData[i].checked) {
        return true;
      } else if (this.state.staffData[i].id === defaultChecked) {
        // set default
        const updatedStaffData = this.state.staffData.slice();
        updatedStaffData[i].checked = true;
        this.setState({
          staffData: updatedStaffData,
        });

        return true;
      }
    }

    return false;
  }

  onToggleListItem(staffId: string) {
    const updatedStaffData = this.state.staffData.slice();

    // uncheck all other items
    for (let i = 0; i < updatedStaffData.length; i++) {
      if (updatedStaffData[i].id === staffId) {
        updatedStaffData[i].checked = true;
      } else {
        updatedStaffData[i].checked = false;
      }
    }

    this.setState({
      staffData: updatedStaffData,
    });
  }

  onFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      filterInput: event.target.value,
    });
  }

  onSaveAssignment() {
    this.setState({
      isAssigning: true,
    });

    // get selected staff
    const selectedStaff = _.findLast(this.state.staffData, { checked: true });
    if (!selectedStaff) {
      return this.setState({
        errorMessage: i18n("staff_not_selected"),
      });
    }

    // push staff user
    return this.props
      .refetchExhibitionsAction()
      .then(() => {
        return this.props.updateExhibitionAction({
          _id: this.props.exhibition._id,
          title: this.props.exhibition.title,
          key: this.props.exhibition.key,
          location: this.props.exhibition.location,
          country: this.props.exhibition.country,
          start: this.props.exhibition.start,
          end: this.props.exhibition.end,
          acquisition_period: this.props.exhibition.acquisition_period,
          person_in_charge: selectedStaff.id,
          sales_staff: this.props.exhibition.sales_staff,
          sales_user_list: this.props.exhibition.sales_user_list,
        });
      })
      .then(() => {
        this.setState({
          isAssigning: false,
          showAssignmentModal: false,
          filterInput: "",
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.message,
        });
      });
  }

  onRequestAssignmentModal() {
    if (
      this.props.exhibition.acquisition_period.closed ||
      this.props.isReadOnly
    ) {
      return;
    }

    this.setState({
      showAssignmentModal: true,
    });
  }

  render() {
    const { isReadOnly } = this.props;

    let person_in_charge;
    if (this.props.users[this.props.exhibition.person_in_charge]) {
      person_in_charge =
        this.props.users[this.props.exhibition.person_in_charge].full_name;
    }
    const disableSaveButton = !this.hasChecked();
    const viewableStaff = this.getViewableStaffData();

    return (
      <div
        className={`component-incharge-assignment ${
          this.props.exhibition.acquisition_period.closed || isReadOnly
            ? "closed"
            : ""
        }`}
      >
        <h4>{i18n("staff")}</h4>
        <div className="item">
          <div className="label">{i18n("person_in_charge")}</div>
          <div
            className="value"
            onClick={this.onRequestAssignmentModal.bind(this)}
          >
            {!person_in_charge ? (
              <span>None</span>
            ) : (
              <span>{person_in_charge}</span>
            )}
          </div>
        </div>
        <Modal
          className="assignment-modal"
          withCloseButton
          isVisible={this.state.showAssignmentModal}
          onRequestClose={() => {
            this.setState({ showAssignmentModal: false, filterInput: "" });
          }}
        >
          <>
          <div className="search-bar item">
            <input
              className="value"
              type="text"
              placeholder={i18n("staff_search")}
              value={this.state.filterInput}
              onChange={this.onFilterChange.bind(this)}
            />
          </div>
          <div className="staff-assignment-body">
            {viewableStaff.map(
              (
                staff: {
                  full_name: string;
                  email: string;
                  id: string;
                  checked: boolean;
                },
                index: number
              ) => (
                <div
                  className={`staff-item ${staff.checked ? "checked" : ""}`}
                  key={staff.id}
                  onClick={this.onToggleListItem.bind(this, staff.id)}
                >
                  <div className="staff-id">{index + 1}</div>
                  <div className="staff-name">
                    {staff.full_name}{" "}
                    {staff.email && <span>({staff.email.toLowerCase()})</span>}
                  </div>
                  <div className="staff-controls">
                    <Checkmark style={{ width: 15 }} color="#004f23" />
                  </div>
                </div>
              )
            )}
            {this.state.staffData.length === 0 && (
              <div className="staff-list-empty">{i18n("staff_list_empty")}</div>
            )}
          </div>
          {this.state.errorMessage && (
            <div className="staff-feedback">{this.state.errorMessage}</div>
          )}

          <div className="staff-submit">
            <Button
              className="primary-button"
              onClick={this.onSaveAssignment.bind(this)}
              loading={this.state.isAssigning}
              disabled={disableSaveButton}
            >
              {i18n("save")}
            </Button>
          </div>
          </>
        </Modal>
      </div>
    );
  }
}
