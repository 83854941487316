import { Session } from "../types/session";
import BaseApi from "./baseApi";

export default class SessionApi extends BaseApi {
  getSession(): Promise<Session> {
    return this.axiosInstance
      .get("session")
      .then((response) => {
        const { roles, username } = response.data;
        // is readonly?
        let readOnly = true;
        if (roles && roles.indexOf("full_admin") !== -1) {
          readOnly = false;
        }

        return {
          auth: true,
          isReadOnly: readOnly,
          userName: username,
        };
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          return Promise.reject("network_error");
        }

        return Promise.reject(error);
      });
  }

  createSession(username: string, password: string) {
    return this.axiosInstance
      .post("session", {
        username,
        password,
      })
      .then((response) => {
        const { roles, username } = response.data;
        // is readonly?
        let readOnly = true;
        if (roles.indexOf("full_admin") !== -1) {
          readOnly = false;
        }
        return {
          auth: true,
          isReadOnly: readOnly,
          userName: username,
        };
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return Promise.reject(error.response.data.error);
        }

        return Promise.reject(error);
      });
  }

  destroySession() {
    return this.axiosInstance.delete("session");
  }
}
