import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "groz-beckert-web";
import AdminCreate from "../adminCreate/adminCreate";
import AdminUpdate from "../adminUpdate/adminUpdate";
import { i18n } from "../../services/i18n";
import "./adminManagement.less";
import { Admin } from "../../types/admin";
import { Model } from "../../components/adminEdit/types";
import { AxiosResponse } from "axios";

// stupid workaround for awesome model validation
const passwordPlaceholder =
  "not_changed-iaM6geequesei6aiQuahqu0sohphei0Jee8Gei6bahnei3ooth-_-1337";
interface Props {
  admins?: Admin[] | null;
  adminName?: string | null;
  adminError: string | null;
  createAdminAction: (model: Admin) => Promise<AxiosResponse<any, any>>;
  updateAdminAction: (model: Admin) => Promise<AxiosResponse<any, any>>;
  deleteAdminAction: (id: string) => Promise<AxiosResponse<any, any>>;
  getAdminsAction: () => void;
}
interface State {
  createModalVisible: boolean;
  updateModalVisible: boolean;
  updateModalAdmin: null | Model;
  searchInput: string;
  admins: null | Admin[] | void | undefined;
}
export default class AdminManagement extends Component<Props, State> {
  static propTypes = {
    admins: PropTypes.array,
    adminName: PropTypes.string,
    adminError: PropTypes.string,
    getAdminsAction: PropTypes.func,
    createAdminAction: PropTypes.func,
    updateAdminAction: PropTypes.func,
    deleteAdminAction: PropTypes.func,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      createModalVisible: false,
      updateModalVisible: false,
      updateModalAdmin: null,
      searchInput: "",
      admins: null,
    };
  }

  onClearSearch() {
    this.setState({
      searchInput: "",
    });
  }

  onSearchChange(event: any) {
    this.setState({
      searchInput: event.target.value,
    });
  }

  onCreateModal = () => {
    this.setState({
      createModalVisible: true,
    });
  };

  onUpdateModal = (admin: Admin) => {
    const adminModel = {
      _id: admin.id,
      name: admin.value.name,
      full_name: admin.value.full_name,
      type: "user",
      roles: admin.value.roles,
      password: passwordPlaceholder,
    };

    this.setState({
      updateModalVisible: true,
      updateModalAdmin: adminModel,
    });
  };

  onCreateAdmin = (admin: Admin) => {
    return this.props
      .createAdminAction?.(admin)
      .then(() => {
        return this.props.getAdminsAction?.();
      })
      .then((admins) => {
        this.setState({
          admins,
        });

        return Promise.resolve();
      });
  };

  onUpdateAdmin = (model: Admin) => {
    const admin = Object.assign({}, model);

    // if password was not changed, remove it from update
    if (admin.password === passwordPlaceholder) {
      delete admin.password;
    }

    return this.props
      .updateAdminAction?.(admin)
      .then(() => {
        return this.props.getAdminsAction?.();
      })
      .then((admins) => {
        this.setState({
          admins,
        });

        return Promise.resolve();
      });
  };

  onDeleteAdmin = (admin: Admin | Model) => {
    return this.props
      .deleteAdminAction?.(admin._id)
      .then(() => {
        return this.props.getAdminsAction?.();
      })
      .then((admins) => {
        this.setState({
          admins,
        });

        return Promise.resolve();
      });
  };

  render() {
    const {
      searchInput,
      createModalVisible,
      updateModalVisible,
      updateModalAdmin,
    } = this.state;
    let admins = this.props.admins;
    const { adminError, adminName } = this.props;

    if (adminError) {
      return <div>{adminError}</div>;
    }

    if (!admins) {
      return <div>{i18n("loading")}...</div>;
    }

    // do we need to apply a filter?
    if (searchInput.length !== 0) {
      const adminsFiltered = [];

      for (let i = 0; i < admins.length; i++) {
        const currentAdmin = admins[i];
        const name = currentAdmin.value.name.toLowerCase();
        const fullName = (currentAdmin.value.full_name || "").toLowerCase();

        // search for name that match the searchInput
        if (
          name.includes(searchInput.toLowerCase()) ||
          fullName.includes(searchInput.toLowerCase())
        ) {
          adminsFiltered.push(currentAdmin);
        }
      }

      admins = adminsFiltered;
    }

    return (
      <div className="container-exhibitions">
        <div className="hero">
          <h1>{i18n("evc_users")}</h1>
          <button className="primary-button" onClick={this.onCreateModal}>
            {i18n("evc_users_create_admin")}
          </button>
        </div>
        <div className="archiv-search">
          <div className="headline">{i18n("evc_users_list")}</div>
          <input
            type="text"
            placeholder={i18n("evc_users_search_placeholder")}
            value={this.state.searchInput}
            onChange={this.onSearchChange.bind(this)}
          />
          <button
            className={
              searchInput.length !== 0 ? "clear-button active" : "clear-button"
            }
            onClick={this.onClearSearch.bind(this)}
          >
            {i18n("search_clear")}
          </button>
        </div>
        <div className="admin-list">
          {admins.map((admin, index) => (
            <AdminItem
              key={admin.key}
              index={index}
              admin={admin}
              updateModalAction={this.onUpdateModal}
            />
          ))}
          {admins.length === 0 && (
            <div className="none">{i18n("evc_users_no_admins")}</div>
          )}
        </div>
        <Modal
          withCloseButton
          isVisible={createModalVisible}
          onRequestClose={() => {
            this.setState({ createModalVisible: false });
          }}
        >
          <AdminCreate createAdminAction={this.onCreateAdmin} />
        </Modal>
        <Modal
          withCloseButton
          isVisible={updateModalVisible}
          onRequestClose={() => {
            this.setState({
              updateModalVisible: false,
              updateModalAdmin: null,
            });
          }}
        >
          <AdminUpdate
            admin={updateModalAdmin}
            adminName={adminName as string}
            updateAdminAction={this.onUpdateAdmin}
            deleteAdminAction={this.onDeleteAdmin}
          />
        </Modal>
      </div>
    );
  }
}
interface AdminItem {
  admin: Admin;
  index: number;
  updateModalAction: (admin: Admin) => void;
}

const AdminItem = (props: AdminItem) => {
  const { admin, index, updateModalAction } = props;
  const { name, full_name, roles } = admin.value;

  // parse roles
  let fullAdmin = false;
  if (roles.indexOf("full_admin") !== -1) {
    fullAdmin = true;
  }

  return (
    <div className="admin-item">
      <div className="admin-id">{index + 1}</div>
      <div className="admin-name">
        {full_name || i18n("evc_users_unkown_name")}
        <span>{name}</span>
        {!fullAdmin && (
          <span className="read-only">{i18n("evc_users_read_only")}</span>
        )}
      </div>
      <div className="admin-controls">
        <button
          className="secondary-button"
          onClick={() => updateModalAction(admin)}
        >
          {i18n("edit")}
        </button>
      </div>
    </div>
  );
};
