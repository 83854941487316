import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "../button/button";
import { Add, Remove } from "react-ionicons";

import { i18n } from "../../services/i18n";
import "./staffAssignment.less";
import { User } from "../../types/user";
interface Props {
  staffData: User[];

  saveAssignment: (
    notLinkedStaff: User[],
    alreadyLinkedStaff: User[]
  ) => Promise<void>;
}

interface State {
  errorMessage: string;
  notExistingStaff: User[];
  notLinkedStaff: User[];
  alreadyLinkedStaff: User[];
  isSaving: boolean;
}
export default class staffAssignment extends Component<Props, State> {
  static propTypes = {
    staffData: PropTypes.array,

    saveAssignment: PropTypes.func,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      notExistingStaff: this.filterStaff("not-existing"),
      notLinkedStaff: this.filterStaff("not-linked"),
      alreadyLinkedStaff: this.filterStaff("already-linked"),

      isSaving: false,
      errorMessage: "",
    };
  }

  /**
   * Filters the handed staffArray into 3 sections:
   * - not-existing: Staff-user does not exist in CouchDB yet, user can't be linked
   * - not-linked: Staff-user isn't linked yet to exhibition, will be linked if not deactivated
   * - linked: Staff-user is alreday linked with exhibition, will be unlinked if deactivated
   *
   * @param  {string}            filterKey [filterKey for section]
   * @return {Array<UserObject>}           [Array of UserObjects for a handed section]
   */
  filterStaff(filterKey: string): User[] {
    const filteredResults: User[] = [];
    if (filterKey === "not-linked") {
      for (let i = 0; i < this.props.staffData.length; i++) {
        if (!this.props.staffData[i].linked) {
          filteredResults.push(this.props.staffData[i]);
        }
      }
    } else if (filterKey === "already-linked") {
      for (let i = 0; i < this.props.staffData.length; i++) {
        if (this.props.staffData[i].linked) {
          filteredResults.push(this.props.staffData[i]);
        }
      }
    }

    return filteredResults;
  }

  /**
   * Toggles the activation-state of a staff-user. If a staff-user is deactivated he will be
   * unlinked from exhibition on this.saveAssignment execution.
   *
   * @param  {number} index     [Array-index of staff-user that will be toggled]
   * @param  {string} filterKey [Section in which the staff-user is stored]
   */
  toggleAssignment(index: number, filterKey: string) {
    if (filterKey === "not-linked") {
      const updatedNotLinked = this.state.notLinkedStaff.slice();

      // toggle index
      updatedNotLinked[index].deactivated =
        !updatedNotLinked[index].deactivated;
      this.setState({
        notLinkedStaff: updatedNotLinked,
      });
    } else if (filterKey === "already-linked") {
      const updatedLinked = this.state.alreadyLinkedStaff.slice();

      // toggle index
      updatedLinked[index].deactivated = !updatedLinked[index].deactivated;
      this.setState({
        alreadyLinkedStaff: updatedLinked,
      });
    }
  }

  /**
   * Hands the assignment to parent, where it will be filtered and sent to backend.
   * Gives success-/error-feedback.
   */
  onSaveAssignment() {
    this.setState({
      isSaving: true,
    });

    this.props
      .saveAssignment(this.state.notLinkedStaff, this.state.alreadyLinkedStaff)
      .then(() => {
        this.setState({
          isSaving: false,
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.message,
        });
      });
  }

  /**
   * Counts all not-linked staff that will be linked on submit.
   *
   * @return {number} [Link count]
   */
  getLinkedCount() {
    // get all newly linked accounts
    let newlyLinked = 0;
    for (let i = 0; i < this.state.notLinkedStaff.length; i++) {
      if (!this.state.notLinkedStaff[i].deactivated) {
        newlyLinked++;
      }
    }

    return newlyLinked;
  }

  /**
   * Counts all already linked staff that will be unlinked on submit.
   *
   * @return {number} [Unlink count]
   */
  getUnlinkedCount() {
    // get all newly unlinked accounts
    let newlyUnlinked = 0;
    for (let i = 0; i < this.state.alreadyLinkedStaff.length; i++) {
      if (this.state.alreadyLinkedStaff[i].deactivated) {
        newlyUnlinked++;
      }
    }

    return newlyUnlinked;
  }

  render() {
    const linkedCount = this.getLinkedCount();
    const unlinkedCount = this.getUnlinkedCount();

    return (
      <div className="component-staff-assignment">
        <div className="staff-assignment-body">
          <div className="assignment-list">
            <div className="assignment-headline">
              {i18n("staff_not_linked")}
            </div>
            {this.state.notLinkedStaff.map((staff, index) => (
              <div
                className={`assignment-item ${
                  staff.deactivated ? "deactivated" : ""
                }`}
                key={staff.id}
              >
                <div className="staff-id">{index + 1}</div>
                <div className="staff-name">
                  {staff.fullname}
                  {staff.email && <span>({staff.email.toLowerCase()})</span>}
                  {!staff.has_st && (
                    <span className="no-st">{i18n("staff_no_st")}</span>
                  )}
                </div>
                <div className="assignment-controls">
                  <button
                    className="clear-button"
                    onClick={this.toggleAssignment.bind(
                      this,
                      index,
                      "not-linked"
                    )}
                  >
                    <Remove color="#d1050c" />
                    <Add color="#004f23" />
                  </button>
                </div>
              </div>
            ))}
            {this.state.notLinkedStaff.length === 0 && (
              <div className="empty-feedback">{i18n("empty")}</div>
            )}
          </div>

          <div className="assignment-list">
            <div className="assignment-headline">{i18n("staff_linked")}</div>
            {this.state.alreadyLinkedStaff.map((staff, index) => (
              <div
                className={`assignment-item ${
                  staff.deactivated ? "deactivated" : ""
                }`}
                key={staff.id}
              >
                <div className="staff-id">{index + 1}</div>
                <div className="staff-name">
                  {staff.fullname}
                  {staff.email && <span>({staff.email.toLowerCase()})</span>}
                  {!staff.has_st && (
                    <span className="no-st">{i18n("staff_no_st")}</span>
                  )}
                </div>
                <div className="assignment-controls">
                  <button
                    className="clear-button"
                    onClick={this.toggleAssignment.bind(
                      this,
                      index,
                      "already-linked"
                    )}
                  >
                    <Remove color="#d1050c" />
                    <Add color="#004f23" />
                  </button>
                </div>
              </div>
            ))}
            {this.state.alreadyLinkedStaff.length === 0 && (
              <div className="empty-feedback">{i18n("empty")}</div>
            )}
          </div>
        </div>
        {this.state.errorMessage && (
          <div className="assignment-feedback">{this.state.errorMessage}</div>
        )}

        <div className="assignment-submit">
          <div className="assignment-info">
            <span>+{linkedCount}</span> / <span>-{unlinkedCount}</span>{" "}
            {i18n("staff")}
          </div>

          <Button
            className="primary-button"
            onClick={this.onSaveAssignment.bind(this)}
            loading={this.state.isSaving}
            disabled={linkedCount === 0 && unlinkedCount === 0}
          >
            {i18n("save")}
          </Button>
        </div>
      </div>
    );
  }
}
