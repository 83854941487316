import { i18n } from "../../services/i18n";
import "./tabs.less";

/**
 * Component that presents a bar with tabs (used for navigation).
 * Selected tabs will be returned via props.setActive(tabId).
 *
 * @type {Object}
 */
interface Props {
  items: { id: string; disabled: boolean }[];
  activeId: string;
  setActive: (id: string) => void;
}
const Tabs = (props: Props) => {
  return (
    <div className="component-tabs">
      {props.items.map((item: { id: string; disabled: boolean }) =>
        !item.disabled ? (
          <div
            className={`tab-item ${
              props.activeId === item.id ? "active" : ""
            } ${item.disabled ? "disabled" : ""}`}
            key={item.id}
            onClick={() => props.setActive(item.id)}
          >
            {i18n(item.id)}
          </div>
        ) : (
          <div className="tab-item disabled" key={item.id}>
            {i18n(item.id)}
          </div>
        )
      )}
    </div>
  );
};
export default Tabs;
