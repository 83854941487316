import React, { Component } from "react";
import PropTypes from "prop-types";
import { i18n } from "../../services/i18n";
import "./exhibitionExport.less";

import Button from "../../components/button/button";

import { XlsxExporter, combine2dArrays } from "../../services/exporter";
import _ from "lodash";

import { gbCompanyTypes } from "../../utils/gbCompanyTypes";
import { gbCountries } from "../../utils/gbCountries";

const customerGroups = [...gbCompanyTypes];
const countries = [...gbCountries];
interface Props {
  exhibitionId: string;
  getExhibitionExportAction: (id: string) => Promise<void>;
  columns: [];
  additionalData: any;
  countries: {
    country: {
      id: string;
    };
  }[];
  customerGroups: [];
}
interface State {
  isExporting: boolean;
}

export default class ExhibitionExport extends Component<Props, State> {
  static propTypes = {
    exhibitionId: PropTypes.string,
    getExhibitionExportAction: PropTypes.func,
    columns: PropTypes.array,
    additionalData: PropTypes.object,
    countries: PropTypes.array,
    customerGroups: PropTypes.array,
  };

  /**
   * Default properties.
   *
   * Columns can be added by adding a column configuration to columns array.
   * IMPORTANT: The formulas in additionalData object are affected by adding a column.
   */
  static defaultProps = {
    columns: [
      { id: "division", name: "Division", type: "string" },
      { id: "country", name: "Country", type: "string" },
      { id: "group", name: "CustGroup", type: "string" },
      { id: "visitorcount", name: "VisitorCount", type: "string" },
      { id: "continent", name: "Continent", type: "string" },
      { id: "companyname", name: "Company", type: "string" },
      { id: "visitorname", name: "VisitorName", type: "string" },
      { id: "shortname", name: "GB shortname", type: "string" },
    ],
    additionalData: {
      default: [
        [
          '="Division " & MID(CELL("filename",A1),FIND("]",CELL("filename",A1))+1,256) & ":"',
          "",
          "",
        ],
        ["", "Companies", "Persons"],
        ["Machine Builder", "=ZÄHLENWENN(C:C;J3)", "=SUMMEWENN(C:C;J3;D:D)"],
        ["Enduser", "=ZÄHLENWENN(C:C;J4)", "=SUMMEWENN(C:C;J4;D:D)"],
        ["Agent", "=ZÄHLENWENN(C:C;J5)", "=SUMMEWENN(C:C;J5;D:D)"],
        ["Dealer", "=ZÄHLENWENN(C:C;J6)", "=SUMMEWENN(C:C;J6;D:D)"],
        ["School", "=ZÄHLENWENN(C:C;J7)", "=SUMMEWENN(C:C;J7;D:D)"],
        ["Others", "=ZÄHLENWENN(C:C;J8)", "=SUMMEWENN(C:C;J8;D:D)"],
        ["Gesamt", "=SUMME(K3:K8)", "=SUMME(L3:L8)"],
      ],
      GBKG: [
        [
          '="Division " & MID(CELL("filename",A1),FIND("]",CELL("filename",A1))+1,256) & ":"',
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        ["", "Companies", "Persons", "", "", "", "", "", "", "", "", ""],
        [
          "Machine Builder",
          "=ZÄHLENWENN(C:C;J3)",
          "=SUMMEWENN(C:C;J3;D:D)",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        [
          "Enduser",
          "=ZÄHLENWENN(C:C;J4)",
          "=SUMMEWENN(C:C;J4;D:D)",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        [
          "Agent",
          "=ZÄHLENWENN(C:C;J5)",
          "=SUMMEWENN(C:C;J5;D:D)",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        [
          "Dealer",
          "=ZÄHLENWENN(C:C;J6)",
          "=SUMMEWENN(C:C;J6;D:D)",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        [
          "School",
          "=ZÄHLENWENN(C:C;J7)",
          "=SUMMEWENN(C:C;J7;D:D)",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        [
          "Others",
          "=ZÄHLENWENN(C:C;J8)",
          "=SUMMEWENN(C:C;J8;D:D)",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        [
          "Gesamt",
          "=SUMME(K3:K8)",
          "=SUMME(L3:L8)",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        ["", "", "", "", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", "", "", "", ""],
        [
          "K",
          "Companies",
          "Persons",
          "W",
          "Companies",
          "Persons",
          "N",
          "Companies",
          "Persons",
          "TN",
          "Companies",
          "Persons",
        ],
        [
          "Machine Builder",
          "=ZÄHLENWENNS($A:$A;J12;$C:$C;J13)",
          "=SUMMEWENNS($D:$D;$A:$A;J12;$C:$C;J13)",
          "Machine Builder",
          "=ZÄHLENWENNS($A:$A;M12;$C:$C;M13)",
          "=SUMMEWENNS($D:$D;$A:$A;M12;$C:$C;M13)",
          "Machine Builder",
          "=ZÄHLENWENNS($A:$A;P12;$C:$C;P13)",
          "=SUMMEWENNS($D:$D;$A:$A;P12;$C:$C;P13)",
          "Machine Builder",
          "=ZÄHLENWENNS($A:$A;S12;$C:$C;S13)",
          "=SUMMEWENNS($D:$D;$A:$A;S12;$C:$C;S13)",
        ],
        [
          "Enduser",
          "=ZÄHLENWENNS($A:$A;J12;$C:$C;J14)",
          "=SUMMEWENNS($D:$D;$A:$A;J12;$C:$C;J14)",
          "Enduser",
          "=ZÄHLENWENNS($A:$A;M12;$C:$C;M14)",
          "=SUMMEWENNS($D:$D;$A:$A;M12;$C:$C;M14)",
          "Enduser",
          "=ZÄHLENWENNS($A:$A;P12;$C:$C;P14)",
          "=SUMMEWENNS($D:$D;$A:$A;P12;$C:$C;P14)",
          "Enduser",
          "=ZÄHLENWENNS($A:$A;S12;$C:$C;S14)",
          "=SUMMEWENNS($D:$D;$A:$A;S12;$C:$C;S14)",
        ],
        [
          "Agent",
          "=ZÄHLENWENNS($A:$A;J12;$C:$C;J15)",
          "=SUMMEWENNS($D:$D;$A:$A;J12;$C:$C;J15)",
          "Agent",
          "=ZÄHLENWENNS($A:$A;M12;$C:$C;M15)",
          "=SUMMEWENNS($D:$D;$A:$A;M12;$C:$C;M15)",
          "Agent",
          "=ZÄHLENWENNS($A:$A;P12;$C:$C;P15)",
          "=SUMMEWENNS($D:$D;$A:$A;P12;$C:$C;P15)",
          "Agent",
          "=ZÄHLENWENNS($A:$A;S12;$C:$C;S15)",
          "=SUMMEWENNS($D:$D;$A:$A;S12;$C:$C;S15)",
        ],
        [
          "Dealer",
          "=ZÄHLENWENNS($A:$A;J12;$C:$C;J16)",
          "=SUMMEWENNS($D:$D;$A:$A;J12;$C:$C;J16)",
          "Dealer",
          "=ZÄHLENWENNS($A:$A;M12;$C:$C;M16)",
          "=SUMMEWENNS($D:$D;$A:$A;M12;$C:$C;M16)",
          "Dealer",
          "=ZÄHLENWENNS($A:$A;P12;$C:$C;P16)",
          "=SUMMEWENNS($D:$D;$A:$A;P12;$C:$C;P16)",
          "Dealer",
          "=ZÄHLENWENNS($A:$A;S12;$C:$C;S16)",
          "=SUMMEWENNS($D:$D;$A:$A;S12;$C:$C;S16)",
        ],
        [
          "School",
          "=ZÄHLENWENNS($A:$A;J12;$C:$C;J17)",
          "=SUMMEWENNS($D:$D;$A:$A;J12;$C:$C;J17)",
          "School",
          "=ZÄHLENWENNS($A:$A;M12;$C:$C;M17)",
          "=SUMMEWENNS($D:$D;$A:$A;M12;$C:$C;M17)",
          "School",
          "=ZÄHLENWENNS($A:$A;P12;$C:$C;P17)",
          "=SUMMEWENNS($D:$D;$A:$A;P12;$C:$C;P17)",
          "School",
          "=ZÄHLENWENNS($A:$A;S12;$C:$C;S17)",
          "=SUMMEWENNS($D:$D;$A:$A;S12;$C:$C;S17)",
        ],
        [
          "Others",
          "=ZÄHLENWENNS($A:$A;J12;$C:$C;J18)",
          "=SUMMEWENNS($D:$D;$A:$A;J12;$C:$C;J18)",
          "Others",
          "=ZÄHLENWENNS($A:$A;M12;$C:$C;M18)",
          "=SUMMEWENNS($D:$D;$A:$A;M12;$C:$C;M18)",
          "Others",
          "=ZÄHLENWENNS($A:$A;P12;$C:$C;P18)",
          "=SUMMEWENNS($D:$D;$A:$A;P12;$C:$C;P18)",
          "Others",
          "=ZÄHLENWENNS($A:$A;S12;$C:$C;S18)",
          "=SUMMEWENNS($D:$D;$A:$A;S12;$C:$C;S18)",
        ],
        [
          "Gesamt",
          "=SUMME(K13:K18)",
          "=SUMME(L13:L18)",
          "Gesamt",
          "=SUMME(N13:N18)",
          "=SUMME(O13:O18)",
          "Gesamt",
          "=SUMME(Q13:Q18)",
          "=SUMME(R13:R18)",
          "Gesamt",
          "=SUMME(T13:T18)",
          "=SUMME(U13:U18)",
        ],
        ["", "", "", "", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", "", "", "", ""],
        [
          "CC",
          "Companies",
          "Persons",
          "S",
          "Companies",
          "Persons",
          "X",
          "Companies",
          "Persons",
          "",
          "",
          "",
        ],
        [
          "Machine Builder",
          "=ZÄHLENWENNS($A:$A;J22;$C:$C;J23)",
          "=SUMMEWENNS($D:$D;$A:$A;J22;$C:$C;J23)",
          "Machine Builder",
          "=ZÄHLENWENNS($A:$A;M22;$C:$C;M23)",
          "=SUMMEWENNS($D:$D;$A:$A;M22;$C:$C;M23)",
          "Machine Builder",
          "=ZÄHLENWENNS($A:$A;P22;$C:$C;P23)",
          "=SUMMEWENNS($D:$D;$A:$A;P22;$C:$C;P23)",
          "",
          "",
          "",
        ],
        [
          "Enduser",
          "=ZÄHLENWENNS($A:$A;J22;$C:$C;J24)",
          "=SUMMEWENNS($D:$D;$A:$A;J22;$C:$C;J24)",
          "Enduser",
          "=ZÄHLENWENNS($A:$A;M22;$C:$C;M24)",
          "=SUMMEWENNS($D:$D;$A:$A;M22;$C:$C;M24)",
          "Enduser",
          "=ZÄHLENWENNS($A:$A;P22;$C:$C;P24)",
          "=SUMMEWENNS($D:$D;$A:$A;P22;$C:$C;P24)",
          "",
          "",
          "",
        ],
        [
          "Agent",
          "=ZÄHLENWENNS($A:$A;J22;$C:$C;J25)",
          "=SUMMEWENNS($D:$D;$A:$A;J22;$C:$C;J25)",
          "Agent",
          "=ZÄHLENWENNS($A:$A;M22;$C:$C;M25)",
          "=SUMMEWENNS($D:$D;$A:$A;M22;$C:$C;M25)",
          "Agent",
          "=ZÄHLENWENNS($A:$A;P22;$C:$C;P25)",
          "=SUMMEWENNS($D:$D;$A:$A;P22;$C:$C;P25)",
          "",
          "",
          "",
        ],
        [
          "Dealer",
          "=ZÄHLENWENNS($A:$A;J22;$C:$C;J26)",
          "=SUMMEWENNS($D:$D;$A:$A;J22;$C:$C;J26)",
          "Dealer",
          "=ZÄHLENWENNS($A:$A;M22;$C:$C;M26)",
          "=SUMMEWENNS($D:$D;$A:$A;M22;$C:$C;M26)",
          "Dealer",
          "=ZÄHLENWENNS($A:$A;P22;$C:$C;P26)",
          "=SUMMEWENNS($D:$D;$A:$A;P22;$C:$C;P26)",
          "",
          "",
          "",
        ],
        [
          "School",
          "=ZÄHLENWENNS($A:$A;J22;$C:$C;J27)",
          "=SUMMEWENNS($D:$D;$A:$A;J22;$C:$C;J27)",
          "School",
          "=ZÄHLENWENNS($A:$A;M22;$C:$C;M27)",
          "=SUMMEWENNS($D:$D;$A:$A;M22;$C:$C;M27)",
          "School",
          "=ZÄHLENWENNS($A:$A;P22;$C:$C;P27)",
          "=SUMMEWENNS($D:$D;$A:$A;P22;$C:$C;P27)",
          "",
          "",
          "",
        ],
        [
          "Others",
          "=ZÄHLENWENNS($A:$A;J22;$C:$C;J28)",
          "=SUMMEWENNS($D:$D;$A:$A;J22;$C:$C;J28)",
          "Others",
          "=ZÄHLENWENNS($A:$A;M22;$C:$C;M28)",
          "=SUMMEWENNS($D:$D;$A:$A;M22;$C:$C;M28)",
          "Others",
          "=ZÄHLENWENNS($A:$A;P22;$C:$C;P28)",
          "=SUMMEWENNS($D:$D;$A:$A;P22;$C:$C;P28)",
          "",
          "",
          "",
        ],
        [
          "Gesamt",
          "=SUMME(K23:K28)",
          "=SUMME(L23:L28)",
          "Gesamt",
          "=SUMME(N23:N28)",
          "=SUMME(O23:O28)",
          "Gesamt",
          "=SUMME(Q23:Q28)",
          "=SUMME(R23:R28)",
          "",
          "",
          "",
        ],
      ],
    },
    countries,
    customerGroups,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isExporting: false,
    };
  }

  onExportStatsXlsx() {
    this.setState({
      isExporting: true,
    });

    // fetch export data
    return this.props
      .getExhibitionExportAction(this.props.exhibitionId)
      .then((exportData: any) => {
        this.doExport(exportData);
      });
  }

  /**
   * Export data to xlsx.
   * @param {Array<Object>} exportData
   */
  doExport(exportData: { value: {} }[]) {
    // do not export if result is empty
    if (exportData.length <= 0) {
      this.setState({
        isExporting: false,
      });
      return;
    }

    // Instatiating excel exporter
    const exporter = new XlsxExporter(this);

    // define data function for exporter
    const data = (exporter: XlsxExporter) => {
      return {
        name: "export",
        sheets: []
          .concat(this.getSheetsByDivision("GBKG", exporter, exportData))
          .concat(this.getSheetsByDivision("K", exporter, exportData))
          .concat(this.getSheetsByDivision("W", exporter, exportData))
          .concat(this.getSheetsByDivision("N", exporter, exportData))
          .concat(this.getSheetsByDivision("TN", exporter, exportData))
          .concat(this.getSheetsByDivision("CC", exporter, exportData))
          .concat(this.getSheetsByDivision("S", exporter, exportData))
          .concat(this.getSheetsByDivision("X", exporter, exportData)),
      };
    };

    // create the export, download it and update the buttons status
    exporter.create(data).then((b64Data: string) => {
      exporter.download(b64Data);
      this.setState({
        isExporting: false,
      });
    });
  }

  /**
   * Get the sheets by division
   * @param {String} name division name
   * @param {Exporter} exporter
   * @param {Array<Object>} exportData data to be exported
   * @returns {Array<Object>}
   */
  getSheetsByDivision(name: string, exporter: XlsxExporter, exportData: any) {
    let data = _.cloneDeep(exportData);
    let data2;

    const countries: any = {};
    this.props.countries.forEach(
      (country) => (countries[country.id] = country)
    );
    // map country and continent names
    data.forEach((value: { continent: string; country: string }) => {
      value.continent = countries[value.country].continent;
      value.country = countries[value.country].name;
    });

    const customerGroups: any = {};
    this.props.customerGroups.forEach(
      (group: { id: string }) => (customerGroups[group.id] = group)
    );
    // map group names
    data.forEach((value: { group: { name: string } }) => {
      value.group = customerGroups[value.group].name;
    });

    // converting data to data matrix
    data = exporter.objectsToArrays(data, this.props.columns);

    // filter the datasets by division (if not GBKG, which displays all data)
    if (name !== "GBKG")
      data = data.filter((value: string) => {
        return value[0] === name;
      });

    if (data.length <= 0) return [];

    // generate and prepend the heading row
    data = [exporter.createHeaderRow(this.props.columns)].concat(data);

    // defining the table range for the data
    const referenceRange = [1, 1, this.props.columns.length, data.length];

    // get additional data to insert into sheet
    if (name in this.props.additionalData)
      data2 = this.props.additionalData[name];
    else data2 = this.props.additionalData["default"];

    // adding additional data to the matrix (i.e. statistics)
    data = combine2dArrays(data, data2, 1, "r");

    return [
      {
        name,
        data,
        tables: [
          {
            columns: this.props.columns,
            referenceRange,
          },
        ],
      },
    ];
  }

  render() {
    return (
      <div className="exhibition-export">
        <Button
          className="primary-button"
          onClick={this.onExportStatsXlsx.bind(this)}
          loading={this.state.isExporting}
          disabled={false}
        >
          {i18n("export_statistics_xlsx")}
        </Button>
      </div>
    );
  }
}
