import A from "../utils/model/attribute";

const AdminSchema = A.schema({
  _id: A.required.nonemptyString.defaultsTo(""),
  name: A.required.nonemptyString.defaultsTo(""),
  full_name: A.required.nonemptyString.defaultsTo(""),
  type: A.required.nonemptyString.defaultsTo("user"),
  roles: A.required.array.defaultsTo([]),
  password: A.required.nonemptyString.defaultsTo(""),
});

export interface Admin {
  _id: string;
  key: string;
  id: string;
  password: string;
  name?: string;
  full_name?: string;
  value: {
    name: string;
    full_name: string;
    roles: string[];
  };
  roles: string[];
}

export { AdminSchema };
