import ExcelBuilder, {util} from 'excel-builder-webpacker';
import {saveBase64As} from './io';
import {i18n} from './i18n';
import * as _ from 'lodash';

/**
 * Base Exporter class
 */
class Exporter {
    contentType = null;
    fileExtension = null;
    fileName = null;

    /**
     * Constructor
     */
    constructor(contentType, fileExtension, fileName) {
      this.contentType = contentType;
      this.fileExtension = fileExtension;
      this.fileName = fileName;
    }

    /**
     * Create the export file data
     * This method has to be overwritten
     * @return {Promise}
     */
    create(data) {
      return new Promise((resolve, reject) => {
        throw new Error(i18n('not implemented yet'));
      });
    }

    /**
     * Download the created export
     */
    download(b64Data) {
      if (b64Data === null) {
        throw new Error(i18n('could not create export'));
      }
      const time = new Date().getTime();
      saveBase64As(b64Data, this.contentType, `${this.fileName}_${time}.${this.fileExtension}`);
    }
}

/**
 * Excel XLSX Exporter class
 */
export class XlsxExporter extends Exporter {
  workbook = null;
  stylesheet = null;
  formatters = null;
  data = null;
  exportComponent = null;
  defaultTableThemeStyle = 'TableStyleLight1';

  /**
   * Constructor
   * Sets the Excel specific variables
   * @param {Component} exportComponent
   */
  constructor(exportComponent) {
    super('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'xlsx', exportComponent.props.fileName);
    this.exportComponent = exportComponent;
  }

  setData(data) {
    this.data = this.prepareData(data);
  }

  /**
   * Prepare the incoming data which could be given as function 
   */
  prepareData(data) {
    if (typeof data === 'function') {
      data = data(this);
    }
    return data;
  }

  /**
   * Initialize the Workbook, the styles and formatters.
   */
  initWorkbook() {
    //only initialize if not already initialized
    if (this.workbook !== null)
      return;

    this.workbook = ExcelBuilder.Builder.createWorkbook();

    this.stylesheet = this.workbook.getStyleSheet();
    this.formatters = this.createFormatters();
  }

  /**
   * Get the workbook - initializes if not initialized yet
   */
  getWorkbook() {
    if (this.workbook === null)
      this.initWorkbook();
    return this.workbook;
  }

  /**
   * @override
   * Creates the export as Base64
   */
  create(data) {
    // initialize the workbook
    this.initWorkbook();
    // set the data after initializing workbook, because the data could use its formatters
    this.setData(data);
    // create all the contents
    this.createSheets();
    // create the output
    return this.createFile();
  }

  /**
   * The final generation of the file data (Base64)
   */
  createFile() {
    return ExcelBuilder.Builder.createFile(this.workbook);
  }

  /**
   * Creating predefined formatters
   */
  createFormatters() {
    const stylesheet = this.stylesheet;
    return ({
      date: stylesheet.createSimpleFormatter('date'),
      currency: stylesheet.createFormat({format: '$ #,##0.00;$ #,##0.00;-', font: {color: 'FFE9F50A'}}),
      header: stylesheet.createFormat({
        font: { bold: true/*, underline: true, color: {theme: 3}*/},
        alignment: {horizontal: 'center'}
      })
    });
  }

  /**
   * Create the Sheets and tables (all contents)
   */
  createSheets() {
    if (!this.data)
      throw new Error(i18n('data has to be defined'));

    this.fileName = this.data.name || this.fileName;
    const sheets = this.data.sheets;

    for (let i = 0; i < sheets.length; i++) {
      const sheet = sheets[i];
      const worksheet = this.workbook.createWorksheet({ name: sheet.name || i18n('table') + ' ' + (i+1) });

      worksheet.setData(sheet.data);
      this.createTables(sheet.tables || [], worksheet, sheet);
      this.workbook.addWorksheet(worksheet);
    }
  }

  /**
   * Creating the tables for the defined worksheet.
   */
  createTables(tables, worksheet, sheet) {
    // default table definition if it wasn't defined
    if (tables.length <= 0 ) {
      const cols = [];
      const sheetdata = sheet.data || [];
      const sheetdataFirst = sheetdata[0] || [];
      const sheetdataSecond = sheetdata[1] || [];
      (sheetdataFirst || []).forEach((v, idx) => {
        const typeTemp = sheetdataSecond[idx] || '';
        const value = (typeof v === 'object' && 'value' in v ? v.value || (i18n('column') + idx) : v) + '';
        sheetdataFirst[idx] = { value, metadata: {style: this.formatters.header.id, type: 'string'} };

        let type = 'string';
        let style = undefined;
        if (typeTemp === null) {
          //
        } if (!isNaN(parseFloat(typeTemp)) && isFinite(typeTemp)) {
          type = 'number';
        } else if (typeTemp instanceof Date) {
          type = 'date';
          style = this.formatters.date.id;
        }

        cols.push({name: value || v + '', type, style});
      }, this);
      tables.push({columns: cols});
    }

    for (let j = 0; j < tables.length; j++) {

      const table = tables[j];
      const columns = table.columns || [];
      const worksheettable = new ExcelBuilder.Table();
      const referenceRange = table.referenceRange || [];
      worksheettable.styleInfo.themeStyle = table.themeStyle || this.defaultTableThemeStyle;

      const referenceRangeStart = [referenceRange[0] || 1, referenceRange[1] || 1];
      const referenceRangeEnd = [referenceRange[2] || (referenceRangeStart[0] + columns.length - 1), referenceRange[3] || (referenceRangeStart[1] + sheet.data.length - 1)];
      worksheettable.setReferenceRange(referenceRangeStart, referenceRangeEnd);

      columns.forEach((col, idx) => {
        worksheettable.addTableColumn(col);
      });
      worksheet.addTable(worksheettable);
      this.workbook.addTable(worksheettable);
    }
  }

  /**
   * Create and download the export
   */
  createAndDownload(data) {
    this.create(data).then((b64Data) => this.download(b64Data));
  }

  /**
   * Converts an array of objects to an array of arrays
   * Optionally define a key whitelist (has to be ordered)
   * @param {Object} jsonObjects array of objects
   * @param {Array<String>|Array<Object>} filterKeys keys to filter
   */
  objectsToArrays(jsonObjects, filterKeys) {
    const objects = _.cloneDeep(jsonObjects);

    jsonObjects.forEach((json, idx) => {
      if (json instanceof Array)
        return;
      filterKeys = filterKeys || Object.keys(json);
      objects[idx] = this.objectToArray(json, filterKeys);
    });

    return objects;
  }

  /**
   * Convert a JSON Object to an array
   * Optionally define keys whitelist (has to be ordered)
   * 
   * @param {Object} json 
   * @param {Array<String>|Array<Object>} keys 
   */
  objectToArray(json, keys) {
    const array = [];
    keys = keys || Object.keys(json);

    keys.forEach((key) => {
      key = (typeof key === 'object' && 'id' in key) ? key.id : key;
      array.push(json[key]);
    });
    return array;
  }

  /**
   * Create the header row for the column config
   * 
   * @param {Array<Object>} columnConfig 
   */
  createHeaderRow(columnConfig) {
    const headerRow = [];
    columnConfig.forEach((config) => {
      headerRow.push({
        value: config.name || '',
        metadata: {
          style: this.formatters.header.id,
          type: 'string'
        }
      });
    });
    return headerRow;
  }

  /**
   * Convert a JSON object with sheetname as key and data as value to sheets array
   * 
   */
  objectToSheets(json, filterKeys) {
    const sheets = [];
    Object.keys(json).forEach((name) => {
      const data = json[name];
      if (!(data instanceof Array) || data.length <= 0) {
        return;
      }
      sheets.push({
        name,
        data: this.objectsToArrays(data, filterKeys),
      });
    }, this);
    return sheets;
  }
}


export const validateFormulaString = (value) => {
  value = value
    .replace(/ZÄHLEN/g, 'COUNT')
    .replace(/SUMME/g, 'SUM')
    .replace(/WENN/g, 'IF')
    .replace(/;/g, ',')
  ;
  return value;
};

/**
 * Convert value to a cell value and identify type.
 * @param {Mixed} value 
 */
const convertToCellValues = ((value) => {
  let type = 'string';
  type = !isNaN(value - parseFloat(value)) ? 'number' : type;
  type = (typeof value === 'string' && value.startsWith('=')) ? 'formula' : type;

  value = type === 'number' ? value * 1 : value;
  value = type === 'formula' ? validateFormulaString(value) : value;

  if (typeof value === 'object')
    return value;
  return {value, metadata: {type}};
});

/**
 * Combines one two-dimensional array with another.
 * Important: The first child array is used to get the count of columns for the x axis.
 * 
 * @param {Array<Array>} array1 two-dimensional array
 * @param {Array<Array>} array2 two-dimensional array
 * @param {number} offset space between the arrays.
 * @param {String} position where to add the array. Possible values: 'r' (right), 'b' (bottom).
 * @param {Function} convertFunction function to convert values 
 */
export const combine2dArrays = (array1, array2, offset, position, convertFunction) => {
  offset = (offset || 0) * 1;
  position = (position === 'r' || position === 'b') ? position : 'r';
  convertFunction = convertFunction || convertToCellValues;

  const combined = [];
  const yLen1 = array1.length;
  const xLen1 = array1[0].length;
  const yLen2 = array2.length;
  const xLen2 = array2[0].length;
  const completeY = yLen1 + (position === 'b' ? offset : 0) + yLen2;
  const completeX = xLen1 + (position === 'r' ? offset : 0) + xLen2;
  const yOffset = yLen1 + offset;
  const xOffset = xLen1 + offset;

  for (let y = 0; y < completeY; y++) {
    combined[y] = combined[y] || [];
    for (let x = 0; x < completeX ;x++) {
      if (y < yLen1 && x < xLen1) {
        combined[y][x] = convertFunction(array1[y][x]);
      } else if (position === 'r' && x >= xOffset && y < yLen2) {
        combined[y][x] = convertFunction(array2[y][x - xOffset]);
      } else if (position === 'b' && y >= yOffset && x < xLen2) {
        combined[y][x] = convertFunction(array2[y - yOffset][x]);
      } else {
        combined[y][x] = convertFunction('');
      }
    }
  }

  return combined;
};