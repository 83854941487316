export default {
  loading: "Loading",
  calculating: "Calculating",
  not_found: "Not Found",
  overview: "Overview",
  archiv: "Archive",
  exhibition: "Exhibition",
  exhibitions: "Exhibitions",
  import_exhibition: "Import Exhibition",
  active_exhibitions: "Active Exhibitions",
  inactive_exhibitions: "Inactive Exhibitions",
  no_exhibitions: "0 Exhibitions found",
  create_exhibition: "Create Exhibition",
  exhibition_date_start: "Exhibition Start",
  exhibition_date_end: "Exhibition End",
  start_date: "Start date",
  end_date: "End date",
  acquisition_period: "Acquisition period",
  end_acquisition_period: "End acquisition period",
  end_acquisition_period_confirm:
    "Do you really want to end the Acquisition Period? This is not reversible.",
  acquisition_info:
    "The acquisition period marks the timeframe in which exhibition reports can be submitted.",
  acquisition_period_closed: "Closed",
  acquisition_period_start: "Start",
  acquisition_period_end: "End",
  closing_open_reports: "Closing open Reports…",
  edit: "Edit",
  details: "Details",
  reports: "Reports",
  drafts: "Drafts",
  create: "Create",
  update: "Update",
  title: "Title",
  key: "Key",
  location: "Location",
  country: "Country",
  delete: "Delete",
  delete_exhibition_confirm: "Do you really want to remove the Exhibition?",
  confirm: "Confirm",
  cancel: "Cancel",
  person_in_charge: "Person in Charge",
  date: "Date",
  csv_import: "Import CSV-File",
  csv_import_placeholder: "Paste your CSV-File",
  csv_import_button: "Import",
  csv_error_input: "Please paste a CSV-File",
  csv_error_syntax: "Not a valid CSV-Syntax pasted",
  staff: "Staff",
  staff_list: "List",
  staff_list_empty: "No Staff added yet",
  staff_list_update: "Update",
  staff_no_st: "No SalesTool",
  staff_check_st: "Check SalesTool-Status",
  staff_not_linked: "Staff not linked yet",
  staff_linked: "Staff already linked",
  staff_search: "Search Staff",
  staff_not_selected: "Please select at least one Staff-User",
  general: "General",
  notification: "Notification",
  notification_default: "The creation of EVC-reports will be closed in",
  notification_default_2: "Please submit all your reports until",
  notification_days: "days",
  notification_day: "day",
  notification_send: "Send Notification",
  notification_help:
    "Notifications will be send to all Staff Members assigned to this Exhibition.",
  notification_history: "History",
  notification_error: "Invalid Notification Text",
  notification_none: "0 Notifications send",
  statistic: "Statistic",
  save: "Save",
  empty: "No Entries",
  login_name: "Username",
  login_password: "Password",
  login_password_repeat: "Password Repeat",
  login_unauthorized: "Username or password is incorrect",
  export: "Export",
  export_statistics_xlsx: "Export Excel Statistics",
  export_factsheet_pdf: "Export PDF Factsheet",
  export_position_number_division_listing_description:
    "# = position number in division listing",
  visitors: "Visitors",
  k: "Knitting",
  w: "Weaving",
  n: "Nonwoven",
  tn: "Tufting",
  cc: "Carding",
  s: "Sewing",
  others: "Others",
  x: "Others",
  sum: "Sum",
  global: "Global",
  all_other_countries: "All Other Countries",
  statistic_visitors_exhibition: "Visitors during exhibition",
  statistic_visitors_daily_per_division: "Visitors per Division (per day)",
  statistic_top_10_countries: "Top 10 Countries",
  statistic_visitors_division_total_percentage:
    "Visitors per Division (total %)",
  statistic_visitors_division_total_count:
    "Visitors per Division (total count)",
  statistic_visitors_continent_country: "Visitors Continent and Country",
  statistic_visitors_division: "Visitors per Division",
  statistic_visitors_company_type: "Visitors by Company Type",
  type: "Type",
  companies: "Companies",
  statistic_reports_exhibition: "Reports during exhibition",
  statistic_reports_daily_per_division: "Reports per Division (per day)",
  statistic_reports_division_total_percentage: "Reports per Division (total %)",
  statistic_reports_division_total_count: "Reports per Division (total count)",
  statistic_reports_continent_country: "Reports Continent and Country",
  statistic_reports_division: "Reports per Division",
  statistic_reports_company_type: "Reports by Company Type",
  statistic_reports_unsent: "Sent Reports",
  sent: "Submitted",
  unsent: "Draft",
  login: "Login",
  show_more: "Show More",
  show_less: "Show Less",
  in_charge_not_set: "ERROR: No responsible person set!",
  in_charge_unkown: "ERROR: Could not fetch name of person!",
  search_placeholder: "Search Exhibition-Name or Person In Charge ",
  search_clear: "clear",
  evc_users: "EVC Admins",
  evc_users_list: "Admin List",
  evc_users_read_only: "Read-Only",
  evc_users_no_admins: "0 Admins found",
  evc_users_unkown_name: "Unkown Name",
  evc_users_search_placeholder: "Search Admin-Name",
  evc_users_create_admin: "Create Admin",
  evc_users_update_admin: "Update Admin",
  evc_users_create_conflict:
    "The entered Username does already exist. Please choose another Username",
  evc_users_unkown_conflict: "Unkown Error: Please contact the EVC-Support",
  evc_users_submit_success_feedback: "New admin user was created successfully",
  evc_users_update_success_feedback: "Admin user was updated successfully",
  evc_users_delete_success_feedback: "Admin user was deleted successfully",
  full_name: "Full Name",
  required_field: "Missing",
  forbidden:
    'You don\'t have permissions to visit this site, because your account were granted "Read-Only"-Permissions. Contact an EVC-Admin with full permissions to resolve this problem.',
  passwords_not_matching: "Passwords do not match. Please try again.",
  yes: "Yes",
  no: "No",
  greeting: "Welcome",
  network_error:
    "Network Error: Server-API is not available, please check your internet connection.",
  unkown_error: "Unkown Error: Please contact the EVC-Support",
  permission_error:
    "Permission Error: You don't have the permissions do execute this operation. Please contact an Admin with write permissions",

  decimal_point: ",",
  thousands_sep: ".",
  export_button_title: "Export",
  print_button_title: "Print",
  range_selector_from: "From",
  range_selector_to: "To",
  range_selector_zoom: "Period",
  download_png: "Download PNG-Image",
  download_jpeg: "Download JPEG-Image",
  download_pdf: "Download PDF-Document",
  download_svg: "Download SVG-Image",
  reset_zoom: "Reset Zoom",

  jan: "January",
  feb: "February",
  mar: "March",
  apr: "April",
  may: "May",
  june: "June",
  july: "July",
  aug: "August",
  sept: "September",
  oct: "October",
  nov: "November",
  dec: "December",
  jan_short: "Jan",
  feb_short: "Feb",
  mar_short: "Mar",
  apr_short: "Apr",
  may_short: "May",
  june_short: "June",
  july_short: "July",
  aug_short: "Aug",
  sept_short: "Sept",
  oct_short: "Oct",
  nov_short: "Nov",
  dec_short: "Dec",

  sunday: "Sunday",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",

  error_end_before_start: "Has to be after exhibition start",
  error_aqend_before_aqstart: "Has to be after acquisition start",
  error_aqstart_after_start: "Has to be before exhibition start",
  error_aq_end_not_3_days_after_end:
    "Has to be at least 3 days after exhibition end",
};
