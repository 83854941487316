import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import StatisticGraph from "../../components/statisticGraph/statisticGraph";
import StatisticPieChart from "../../components/statisticPieChart/statisticPieChart";

import Button from "../../components/button/button";

import * as _ from "lodash";
import moment from "moment";
import { Exhibition, ExhibitionPropType } from "../../types/exhibition";
import { i18n } from "../../services/i18n";

import "./exhibitionStatisticsExport.less";

import { Canvg } from "canvg";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";

// !!!VERY IMPORTANT: connect pdfMake and vfs_fonts
pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfMake.vfs;

interface Props {
  exhibitionId: string;
  items: [];
  exhibition: Exhibition;
  statisticsData: any;
  exportType: string;
}
interface State {
  isExporting: boolean;
}

export default class ExhibitionStatisticsExport extends Component<
  Props,
  State
> {
  static propTypes = {
    exhibitionId: PropTypes.string,
    items: PropTypes.array,
    exhibition: ExhibitionPropType,
    statisticsData: PropTypes.object.isRequired,
    exportType: PropTypes.string.isRequired,
  };

  defaultTblOpts = {
    margin: 0,
    width: 480,
    fillColor: "#a9b745",
    //layout: 'noBorders',
    layout: {
      paddingTop: () => 2,
      paddingRight: () => 2,
      paddingBottom: () => 2,
      paddingLeft: () => 2,
      hLineWidth: () => 0,
      vLineWidth: () => 0,
    },
    headerRows: 1,
  };

  constructor(props: Props) {
    super(props);
    this.state = { isExporting: false };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  onClick() {
    // set button loading
    this.setState({ isExporting: true });
    // start pdf creation
    window.setTimeout(this.createPdfExport.bind(this), 100);
  }

  chartToImg(chartComponent: React.ReactInstance) {
    return this.canvas2Img(
      this.svg2Canvas(
        (ReactDOM.findDOMNode(chartComponent) as Element)?.getElementsByTagName(
          "svg"
        )[0]
      )
    ).src;
  }

  getChartHeadline(headline: string, options?: {}) {
    const headlineOptions = {
      style: "chartHeading",
    };
    return { ...headlineOptions, ...options, text: headline };
  }

  /**
   * Create PDF table export object (visitors daily per division).
   */
  createVisitorsTableObject() {
    // define headers and columns widths
    let tableHeaders = [["", "", "K", "W", "N", "TN", "CC", "SMN", "Others"]];
    const widths = [100, "*", "*", "*", "*", "*", "*", "*", "*"];
    const addFormat = (key: string, value: string) => {
      if (key === "Sum") return { text: value, ...{ style: "sum" } };
      return value;
    };

    // cloning the table data
    const tableData: any = _.cloneDeep(
      this.props.statisticsData.dailyPerDivision
    );
    // mapping table row data values
    let tableDataArray: any = Object.keys(tableData.body).map((key) => {
      const obj = tableData.body[key];
      const sumValue =
        obj.Sum ||
        (obj.K || 0) +
          (obj.W || 0) +
          (obj.N || 0) +
          (obj.TN || 0) +
          (obj.CC || 0) +
          (obj.S || 0) +
          (obj.X || 0);
      return [
        addFormat(
          key,
          key === "Sum" ? "Total" : (this.formatDate(key) as string)
        ),
        addFormat(key, sumValue),
        addFormat(key, obj.K || 0),
        addFormat(key, obj.W || 0),
        addFormat(key, obj.N || 0),
        addFormat(key, obj.TN || 0),
        addFormat(key, obj.CC || 0),
        addFormat(key, obj.S || 0),
        addFormat(key, obj.X || 0),
      ];
    });

    // sum as first value
    // tableDataArray.unshift(tableDataArray.pop());

    // add styles to data and headers
    tableDataArray = tableDataArray.map((array: any) => {
      array.forEach((value: string, i: number) => {
        array[i] = {
          text: value,
          alignment: i > 0 ? "center" : "left",
        };
      });
      return array;
    });
    tableHeaders = tableHeaders.map((array: any) => {
      array.forEach((value: string, key: number) => {
        array[key] = {
          text: value,
          alignment: key > 0 ? "center" : "left",
        };
      });
      return array;
    });

    // create table export object
    return {
      ...this.defaultTblOpts,
      layout: {
        ...this.defaultTblOpts.layout,
        hLineWidth: (
          i: number,
          obj: { table: { body: { length: number } } }
        ) => {
          // add a horizontal line before the last (sum) column
          if (i === obj.table.body.length - 1) {
            return 1;
          }
          return 0;
        },
      },
      table: {
        widths: widths,
        body: [...tableHeaders, ...tableDataArray],
      },
    };
  }

  /**
   * Create PDF table export object (top ten countries).
   */
  createTopTenCountriesObject() {
    const divisions = ["K", "W", "N", "TN", "CC", "SMN", "Others"];
    // define headers and columns widths
    let tableHeaders = [
      [i18n("statistic_top_10_countries"), i18n("global"), ...divisions],
    ];
    const w = 33;
    let widths = [100, w, w, w, w, w, w, w, w];

    // cloning the table data
    const topCountries = _.cloneDeep(this.props.statisticsData.topCountries);
    const topCountriesDivision = _.cloneDeep(
      this.props.statisticsData.topCountriesDivision
    );
    const sumsPerDivisionAndCountry: any = {};

    // mapping table row data values
    let tableDataArray = Object.keys(topCountries.body).map((key, i) => {
      const value = topCountries.body[key];
      const result = [key, value.sum];
      // sum global visitors
      divisions.forEach((division) => {
        let div = division === "SMN" ? "S" : division;
        div = division === "Others" ? "X" : div;

        const divObject = topCountriesDivision[div] || {};
        const divCountry = divObject[key] || {};
        const sum = divCountry.sum;
        // sum the division visitors
        sumsPerDivisionAndCountry[division] =
          sumsPerDivisionAndCountry[division] || {};
        sumsPerDivisionAndCountry[division][key] =
          (sumsPerDivisionAndCountry[division][key] || 0) + (sum || 0) * 1;
        // fill the cell value
        result.push(sum || "");
      });

      return result;
    });

    // get ordered sums array for each divisions visitors
    // so the position is the index of the amount of visitors in the resulting array
    const countrySums: any = {};
    divisions.forEach((division) => {
      const sums = sumsPerDivisionAndCountry[division];
      countrySums[division] = [];
      Object.keys(sums).forEach((country) => {
        countrySums[division].push(sums[country]);
      });
      countrySums[division].sort((a: number, b: number) => b - a);
    });

    // function to get text from cell value
    const getText = (value: { text: number }[]) => {
      try {
        return value[0].text * 1;
      } catch (e) {}
      return 0;
    };

    const sumPositions: any = {};
    // add styles to data and headers
    tableDataArray = tableDataArray.map((array) => {
      array.forEach((value, key) => {
        array[key] = [
          {
            text: value,
            alignment: key > 0 ? "center" : "left",
          },
        ];
      });

      // iterate through divisions to add the division dependant positions
      divisions.forEach((division) => {
        let position = 0;
        let value = 0;
        try {
          let index = tableHeaders[0].indexOf(division);

          const cellWidth = 10;
          // add the table header cell and width configuration
          if (widths[index - 1] !== cellWidth) {
            // add table header cell
            tableHeaders[0] = [
              ...tableHeaders[0].slice(0, index),
              { text: "#", style: "position" },
              ...tableHeaders[0].slice(index),
            ];
            // add width configuration
            widths = [
              ...widths.slice(0, index),
              cellWidth,
              ...widths.slice(index),
            ];
            index++;
          }
          index--;
          try {
            //get the value of the column to get the position for this value (the position could be assigned multiple times)
            value = getText(array[index]);
            if (value > 0) {
              sumPositions[division] =
                typeof sumPositions[division] === "undefined"
                  ? {}
                  : sumPositions[division];
              // buffer the position for the amount of visitors
              position = sumPositions[division][value] =
                typeof sumPositions[division][value] === "undefined"
                  ? countrySums[division].indexOf(value) + 1
                  : sumPositions[division];
            }

            // add the position to the data array
            array = [
              ...array.slice(0, index),
              { text: position || "", style: "position" },
              ...array.slice(index),
            ];
          } catch (e1) {
            // add empty position to the data array, because the cell has to be defined
            array = [
              ...array.slice(0, index),
              { text: "", style: "position" },
              ...array.slice(index),
            ];
          }
        } catch (e) {
          //
        }
      });

      return array;
    });

    tableHeaders = tableHeaders.map((array) => {
      array.forEach((value, key) => {
        if (value instanceof Object) {
          array[key] = value;
        } else {
          array[key] = {
            text: value,
            alignment: key > 0 ? "center" : "left",
            bold: true,
          };
        }
      });
      return array;
    });

    // extract top ten if more than ten datasets given
    // assumes that the array is ordered
    const amount = 10;
    if (tableDataArray.length > amount) {
      const topTenDataArray = _.cloneDeep(tableDataArray).splice(0, amount);
      const otherData = _.cloneDeep(tableDataArray).splice(amount);

      let other = [];

      other.push(i18n("all_other_countries"));
      other.push(
        otherData
          .map((v) => {
            return getText(v[1]);
          })
          .reduce((a, b) => {
            return a + b;
          }) || ""
      );
      other.push({ text: "", style: "position", fillColor: "#a9b745" }); // also add the position columns
      other.push(
        otherData
          .map((v) => {
            return getText(v[3]);
          })
          .reduce((a, b) => {
            return a + b;
          }) || ""
      );
      other.push({ text: "", style: "position", fillColor: "#a9b745" }); // also add the position columns
      other.push(
        otherData
          .map((v) => {
            return getText(v[5]);
          })
          .reduce((a, b) => {
            return a + b;
          }) || ""
      );
      other.push({ text: "", style: "position", fillColor: "#a9b745" }); // also add the position columns
      other.push(
        otherData
          .map((v) => {
            return getText(v[7]);
          })
          .reduce((a, b) => {
            return a + b;
          }) || ""
      );
      other.push({ text: "", style: "position", fillColor: "#a9b745" }); // also add the position columns
      other.push(
        otherData
          .map((v) => {
            return getText(v[9]);
          })
          .reduce((a, b) => {
            return a + b;
          }) || ""
      );
      other.push({ text: "", style: "position", fillColor: "#a9b745" }); // also add the position columns
      other.push(
        otherData
          .map((v) => {
            return getText(v[11]);
          })
          .reduce((a, b) => {
            return a + b;
          }) || ""
      );
      other.push({ text: "", style: "position", fillColor: "#a9b745" }); // also add the position columns
      other.push(
        otherData
          .map((v) => {
            return getText(v[13]);
          })
          .reduce((a, b) => {
            return a + b;
          }) || ""
      );
      other.push({ text: "", style: "position", fillColor: "#a9b745" }); // also add the position columns
      other.push(
        otherData
          .map((v) => {
            return getText(v[15]);
          })
          .reduce((a, b) => {
            return a + b;
          }) || ""
      );

      // add styles to the data cells
      other = [other];
      other = other.map((array) => {
        array.forEach((value, key) => {
          if (array[key] instanceof Object) {
            return;
          }
          array[key] = {
            text: value,
            alignment: key > 0 ? "center" : "left",
            bold: true,
          };
        });
        return array;
      });

      // update the data array
      tableDataArray = [...topTenDataArray, ...other];
    }

    // create table export object
    return {
      ...this.defaultTblOpts,
      //layout: undefined,
      table: {
        widths: widths,
        body: [...tableHeaders, ...tableDataArray],
      },
    };
  }

  formatDate(date: Date | string) {
    try {
      return moment.utc(date).format("DD.MM.YYYY");
    } catch (e) {
      return date;
    }
  }
  createPdfExport(title: string, date: Date) {
    // get exhibition object
    const chartWidth = 250;
    // create export object
    const pdfExport = {
      // PDF CONTENTS
      content: [
        {
          // OUTER TABLE (for the borders)
          style: "tableExample",
          table: {
            widths: [500],
            body: [
              // TITLE
              [
                {
                  text: this.props.exhibition.title,
                  style: "title",
                  border: [true, true, true, false],
                },
              ],
              // DATE
              [
                {
                  text:
                    date || "" + this.formatDate(this.props.exhibition.start),
                  style: "date",
                  border: [true, false, true, true],
                },
              ],
              // EMPTY LINE
              [" "],
              // VISITOR CHART - HEADLINE
              [
                this.getChartHeadline(
                  i18n(`statistic_${this.props.exportType}_exhibition`)
                ),
              ],
              // VISITOR CHART - TABLE DATE
              [this.createVisitorsTableObject()],
              // VISITOR CHART
              [{ image: "chart1", width: chartWidth, style: "chart" }],
              // VISITORS PER DIVISION CHART - HEADLINE
              [
                this.getChartHeadline(
                  i18n(
                    `statistic_${this.props.exportType}_division_total_percentage`
                  ),
                  {
                    // pageBreak: 'before',
                  }
                ),
              ],
              // VISITORS PER DIVISION CHART
              [{ image: "chart2", width: chartWidth, style: "chart" }],
              [this.createTopTenCountriesObject()],
              [
                {
                  text: i18n(
                    "export_position_number_division_listing_description"
                  ),
                  style: "footer",
                },
              ],
            ],
          },
        },
      ],
      // define images used in the pdf document
      images: {
        chart1: this.chartToImg(this.refs.daily_total_graph),
        chart2: this.chartToImg(this.refs.pie_total),
      },
      // define the styles for pdf documents elements
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: 0,
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: 0,
        },
        tableExample: {
          margin: 0,
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
        chartHeading: {
          fillColor: "#a9b745",
          bold: true,
        },
        title: {
          fontSize: 18,
          bold: true,
          alignment: "center",
          margin: [0, 10, 0, 0],
          fillColor: "#3b8194",
        },
        chart: {
          width: 500,
          alignment: "center",
          margin: 0,
        },
        date: {
          alignment: "right",
          margin: 0,
          fillColor: "#3b8194",
        },
        footer: {
          color: "gray",
        },
        position: {
          fontSize: 8,
          fillColor: "#c2d798",
          alignment: "center",
        },
        sum: {
          bold: true,
        },
      },
      // defining a default style
      defaultStyle: {
        // alignment: 'justify'
        fontSize: 10,
      },
      // define the footer contents for the pdf document
      footer: function (page: number, pages: number) {
        return {
          columns: [
            {
              alignment: "right",
              style: "footer",
              text: [
                { text: page.toString(), italics: true },
                " of ",
                { text: pages.toString(), italics: true },
                " - ",
                moment().format("DD.MM.YYYY HH:mm"),
              ],
            },
          ],
          margin: [10, 0],
        };
      },
    };

    // create the pdf and download it
    pdfMake
      .createPdf(pdfExport as any)
      .download(
        `${this.props.exhibition.key}-statistics_${moment().valueOf()}.pdf`
      );
    // unset the buttons loading state
    this.setState({ isExporting: false });
  }

  /**
   * Create a canvas element from a svg dom element.
   *
   * @param {DOMElement} svg
   */
  svg2Canvas(svg: Node) {
    const canvas = document.createElement("canvas");
    const ctx = canvas?.getContext("2d");
    const v = Canvg.fromString(
      ctx as CanvasRenderingContext2D,
      new XMLSerializer().serializeToString(svg)
    );

    v.start();
    return canvas;
  }

  /**
   * Create a image element from a canvas dom element.
   *
   * @param {DOMElement} canvas
   */
  canvas2Img(canvas: HTMLCanvasElement) {
    const imgData = canvas.toDataURL("image/jpeg", 1.0),
      img = document.createElement("img");
    img.src = imgData;
    return img;
  }

  ptToPx(pt: number) {
    return pt * (1 / 3 + 1);
  }

  pxToPt(px: number) {
    return px / (1 / 3 + 1);
  }

  render() {
    return (
      <div className="container-exhibition-statistics-export">
        <Button
          className="primary-button"
          onClick={this.onClick.bind(this)}
          loading={this.state.isExporting}
        >
          {i18n("export_factsheet_pdf")}
        </Button>
        <div className="export-container" ref="exportContainer">
          <div className="statistics-report">
            <StatisticGraph
              ref="daily_total_graph"
              headline={i18n(`statistic_${this.props.exportType}_exhibition`)}
              type={`${this.props.exportType.replace(/s$/gi, "")}`}
              graphData={this.props.statisticsData.dailyTotalGraph}
            />
          </div>
          <div className="statistics-report">
            <StatisticPieChart
              ref="pie_total"
              headline={i18n(
                `statistic_${this.props.exportType}_division_total_percentage`
              )}
              type={`${this.props.exportType.replace(/s$/gi, "")}-percentage`}
              pieData={this.props.statisticsData.pieTotal}
            />
          </div>
        </div>
      </div>
    );
  }
}
