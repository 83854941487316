import axios, { AxiosInstance } from "axios";
import { config } from "../environment";

export default class BaseApi {
  baseUrl: string | null = null;
  private static _axiosInstance: AxiosInstance;

  get axiosInstance() {
    return BaseApi._axiosInstance;
  }

  constructor() {
    if (!BaseApi._axiosInstance) {
      const { url, version } = config.api;

      if (url.substr(-1) === "/") {
        this.baseUrl = url + version;
      } else {
        this.baseUrl = `${url}/${version}`;
      }

      BaseApi._axiosInstance = axios.create({
        withCredentials: true,
        baseURL: this.baseUrl,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    }
  }
}
