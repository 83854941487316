import * as _ from 'lodash';
import moment from 'moment';

import { i18n } from './i18n';
import { sortKeys, sortDates } from '../utils/helpers';

import { gbCountries } from '../utils/gbCountries';
import { gbDivisions } from '../utils/gbDivisions';
import { gbContinents } from '../utils/gbContinents';
import { gbCompanyTypes } from '../utils/gbCompanyTypes';

export class VisitorsCalculator {
  constructor(visitors) {
    this.visitors = visitors;
  }

  parseDailyDivison() {
    // table-headers
    const headers = [
      i18n('date'),
      i18n('k'),
      i18n('w'),
      i18n('n'),
      i18n('tn'),
      i18n('cc'),
      i18n('s'),
      i18n('x'),
      i18n('sum'),
    ];

    // parse into dates
    const dates = {};
    const divisions = {
      'K': 0, 'W': 0, 'N': 0, 'TN': 0, 'CC': 0, 'S': 0, 'X': 0, 'Sum': 0,
    };
    const total = {
      'K': 0, 'W': 0, 'N': 0, 'TN': 0, 'CC': 0, 'S': 0, 'X': 0, 'Sum': 0,
    };
    for (let i = 0; i < this.visitors.length; i++) {
      const currentVisitors = this.visitors[i];
      currentVisitors.visitors = parseInt(currentVisitors.visitors, 10);

      if (!dates[currentVisitors.date]) {
        // date entry does not exist yet, create one
        dates[currentVisitors.date] = _.clone(divisions);
      }

      // add visitors to division for current date
      dates[currentVisitors.date][currentVisitors.division] +=
        Number.isNaN(currentVisitors.visitors) ? 1 : currentVisitors.visitors;

      // add visitors also to sum of current date
      dates[currentVisitors.date]['Sum'] +=
        Number.isNaN(currentVisitors.visitors) ? 1 : currentVisitors.visitors;


      // add visitors also to total sum of division & total sum
      total[currentVisitors.division] +=
        Number.isNaN(currentVisitors.visitors) ? 1 : currentVisitors.visitors;

      total['Sum'] += Number.isNaN(currentVisitors.visitors) ? 1 : currentVisitors.visitors;
    }

    // sort days
    const sortedDates = {};
    sortDates(dates).map((dateKey) => {
      sortedDates[dateKey] = dates[dateKey];
    });

    const body = {
      ...sortedDates,
      Sum: total,
    };

    return {
      headers,
      body,
    };
  }

  parseDailyTotalGraph(dailyPerDivision) {
    // parse dailyPerDivision-object into highcharts form with sums
    const dailyGraphData = [];
    Object.keys(dailyPerDivision).map((dateKey) => {
      if (dateKey !== 'Sum') {
        dailyGraphData.push([
          moment.utc(dateKey).valueOf(),
          dailyPerDivision[dateKey].Sum,
        ]);
      }
    });

    return dailyGraphData.reverse();
  }

  parseCompanyType() {
    // table-headers
    const headers = [
      i18n('type'),
      i18n('companies'),
      i18n('visitors'),
    ];

    // table-body
    const companies = {
      'global': {}, 'K': {}, 'W': {}, 'N': {}, 'TN': {}, 'CC': {}, 'S': {}, 'X': {},
    };
    const companyTypes = {};
    gbCompanyTypes.map((type) => {
      companyTypes[type.name] = { companies: 0, visitors: 0 };
    });
    companyTypes['Sum'] = { companies: 0, visitors: 0 };

    // insert company-types into divisions
    Object.keys(companies).map((divisionKey) => {
      companies[divisionKey] = _.cloneDeep(companyTypes);
    });

    // get division data
    this.visitors.map((visitor) => {
      const companyMapping = _.find(gbCompanyTypes, { id: visitor.companyType });
      if (!companies[visitor.division] || !companyMapping) {
        console.warn(`Unkown company division "${visitor.division} [${visitor._rev}]"`);
      } else {
        const type = companyMapping.name;
        companies[visitor.division][type].companies += 1;
        companies[visitor.division][type].visitors += Number.isNaN(parseInt(visitor.visitors, 10)) ?
          1 : parseInt(visitor.visitors, 10);

        // add to division-sum
        companies[visitor.division]['Sum'].companies += 1;
        companies[visitor.division]['Sum'].visitors += Number.isNaN(parseInt(visitor.visitors, 10)) ?
          1 : parseInt(visitor.visitors, 10);

        // add to global-sum
        companies['global'][type].companies += 1;
        companies['global'][type].visitors += Number.isNaN(parseInt(visitor.visitors, 10)) ?
          1 : parseInt(visitor.visitors, 10);
        companies['global']['Sum'].companies += 1;
        companies['global']['Sum'].visitors += Number.isNaN(parseInt(visitor.visitors, 10)) ?
          1 : parseInt(visitor.visitors, 10);
      }
    });

    return {
      headers,
      body: companies,
    };
  }

  parsePieTotal(sumPerDivision) {
    // parse sumPerDivision-object into highcharts form
    const pieTotalData = [];
    Object.keys(sumPerDivision).map((divisionKey) => {
      if (divisionKey !== 'Sum' && divisionKey !== '') {
        pieTotalData.push({
          name: i18n(divisionKey.toLowerCase()),
          y: sumPerDivision[divisionKey]
        });
      }
    });

    return pieTotalData;
  }

  parseTopCountries() {
    // parse into countries
    const countries = {};
    for (let i = 0; i < this.visitors.length; i++) {
      const currentVisitors = this.visitors[i];

      // get country name from id
      const gbCountryObject = _.find(gbCountries, { id: currentVisitors.country });
      if (!gbCountryObject) {
        console.warn(`Unkown country "${currentVisitors.country}" [${currentVisitors._rev}]`);
        continue;
      }
      const gbCountry = gbCountryObject ? gbCountryObject.name : 'Unkown';

      if (!countries[gbCountry]) {
        // country entry does not exist yet, create one
        countries[gbCountry] = { 'sum': 0 };
      }

      // add visitors to country
      countries[gbCountry].sum += Number.isNaN(parseInt(currentVisitors.visitors, 10)) ?
        1 : parseInt(currentVisitors.visitors, 10);
    }

    // sort countries
    const sortedCountries = {};
    sortKeys(countries).map((countryKey) => {
      sortedCountries[countryKey] = countries[countryKey];
    });

    return {
      body: sortedCountries,
    };
  }

  parseTopCountriesPerDivision() {
    // parse into divisios
    const divisions = {
      'K': {}, 'W': {}, 'N': {}, 'TN': {}, 'CC': {}, 'S': {}, 'X': {},
    };
    for (let i = 0; i < this.visitors.length; i++) {
      const currentVisitors = this.visitors[i];

      // skip invalid divisions
      if (!divisions[currentVisitors.division]) {
        console.warn(`Unkown division "${currentVisitors.division}" [${currentVisitors._rev}]`);
        continue;
      }

      // get country name from id
      const gbCountryObject = _.find(gbCountries, { id: currentVisitors.country });
      if (!gbCountryObject) {
        console.warn(`Unkown country "${currentVisitors.country}" [${currentVisitors._rev}]`);
        continue;
      }
      const gbCountry = gbCountryObject ? gbCountryObject.name : 'Unkown';


      if (!divisions[currentVisitors.division][gbCountry]) {
        // country entry in division does not exist yet, create one
        divisions[currentVisitors.division][gbCountry] = { 'sum': 0 };
      }

      // add visitors to country
      divisions[currentVisitors.division][gbCountry].sum +=
        Number.isNaN(parseInt(currentVisitors.visitors, 10)) ? 1 : parseInt(currentVisitors.visitors, 10);
    }

    // sort countries inside division
    const sortedDivisions = {};
    Object.keys(divisions).map((divisionKey) => {
      sortedDivisions[divisionKey] = {};

      sortKeys(divisions[divisionKey]).map((countryKey) => {
        sortedDivisions[divisionKey][countryKey] = divisions[divisionKey][countryKey];
      });
    });

    return sortedDivisions;
  }

  parseDivisionCountryHeatMap(topCountriesDivision) {
    // parse axis-data
    const yAxis = gbCountries.map(country => country.name).sort();
    const yAxisReversed = yAxis.reverse();
    const xAxis = gbDivisions.map(division => division.name);

    // parse data into coordinates
    const coordinateData = [];
    gbDivisions.map((division, indexDivision) => {
      // loop through countries from bottom and check if they have an entry inside division
      yAxisReversed.map((countryName, indexCountry) => {
        if (topCountriesDivision[division.id][countryName]) {
          // entry exists, map value to coordinate
          coordinateData.push(
            [indexDivision, indexCountry, topCountriesDivision[division.id][countryName].sum]
          );
        }
      });
    });

    return {
      data: coordinateData,
      y: yAxis,
      x: xAxis,
    };
  }

  parseContinentTree(topCountriesDivision) {
    const tree = [];
    const continentMapping = {};
    const colors = ['#a9b745', '#3b8194', '#83afbb', '#f6f8ec', '#eaeed3', '#5e9074'];

    // create parents
    gbContinents.map((continent, index) => {
      // add continent as tree-parent
      tree.push({
        name: continent.name,
        id: `parent-${continent.id}`,
        color: colors[index],
      });

      // add continent to mapping (is used to get the relation continentName<->parent-id)
      continentMapping[continent.name] = `parent-${continent.id}`;
    });

    // add values to parent
    Object.keys(topCountriesDivision).map((divisionKey) => {
      Object.keys(topCountriesDivision[divisionKey]).map((countryName) => {
        // to which contient does the current country belong
        const country = _.find(gbCountries, { name: countryName });

        // get parent-id of continent in tree
        const parentId = continentMapping[country.continent];

        // does the current country already exist?
        const existingCounty = _.find(tree, { name: countryName });
        if (!existingCounty) {
          // link country to parent
          tree.push({
            name: country.name,
            parent: parentId,
            id: `${parentId}_country-${country.id}`,
          });
        }

        // link division to country
        tree.push({
          name: divisionKey,
          parent: `${parentId}_country-${country.id}`,
          id:  `${parentId}_country-${country.id}-${divisionKey}`,
          value: topCountriesDivision[divisionKey][countryName].sum
        });
      });
    });

    return tree;
  }
}

export class ReportsCalculator {
  constructor(reports) {
    this.visitors = reports;
  }

  parseDailyDivison() {
    // table-headers
    const headers = [
      i18n('date'),
      i18n('k'),
      i18n('w'),
      i18n('n'),
      i18n('tn'),
      i18n('cc'),
      i18n('s'),
      i18n('x'),
      i18n('sum'),
    ];

    // parse into dates
    const dates = {};
    const divisions = {
      'K': 0, 'W': 0, 'N': 0, 'TN': 0, 'CC': 0, 'S': 0, 'X': 0, 'Sum': 0,
    };
    const total = {
      'K': 0, 'W': 0, 'N': 0, 'TN': 0, 'CC': 0, 'S': 0, 'X': 0, 'Sum': 0,
    };
    for (let i = 0; i < this.visitors.length; i++) {
      const currentVisitors = this.visitors[i];

      if (!dates[currentVisitors.date]) {
        // date entry does not exist yet, create one
        dates[currentVisitors.date] = _.clone(divisions);
      }

      // add reports to division for current date
      dates[currentVisitors.date][currentVisitors.division] += 1;

      // add reports also to sum of current date
      dates[currentVisitors.date]['Sum'] += 1;

      // add reports also to total sum of division
      total[currentVisitors.division] += 1;
      total['Sum'] += 1;
    }

    // sort days
    const sortedDates = {};
    sortDates(dates).map((dateKey) => {
      sortedDates[dateKey] = dates[dateKey];
    });

    const body = {
      ...sortedDates,
      Sum: total,
    };

    return {
      headers,
      body,
    };
  }

  parseDailyTotalGraph(dailyPerDivision) {
    // parse dailyPerDivision-object into highcharts form with sums
    const dailyGraphData = [];
    Object.keys(dailyPerDivision).map((dateKey) => {
      if (dateKey !== 'Sum') {
        dailyGraphData.push([
          moment.utc(dateKey).valueOf(),
          dailyPerDivision[dateKey].Sum,
        ]);
      }
    });

    return dailyGraphData.reverse();
  }

  parseCompanyType() {
    // table-headers
    const headers = [
      i18n('type'),
      i18n('companies'),
      i18n('reports'),
    ];

    // table-body
    const companies = {
      'global': {}, 'K': {}, 'W': {}, 'N': {}, 'TN': {}, 'CC': {}, 'S': {}, 'X': {},
    };
    const companyTypes = {};
    gbCompanyTypes.map((type) => {
      companyTypes[type.name] = { companies: 0, visitors: 0 };
    });
    companyTypes['Sum'] = { companies: 0, visitors: 0 };

    // insert company-types into divisions
    Object.keys(companies).map((divisionKey) => {
      companies[divisionKey] = _.cloneDeep(companyTypes);
    });

    // get division data
    this.visitors.map((visitor) => {
      const companyMapping = _.find(gbCompanyTypes, { id: visitor.companyType });

      if (!companies[visitor.division] || !companyMapping) {
        console.warn(`Unkown company division "${visitor.division} [${visitor._rev}]"`);
      } else {
        const type = companyMapping.name;
        companies[visitor.division][type].companies += 1;
        companies[visitor.division][type].visitors += 1;

        // add to division-sum
        companies[visitor.division]['Sum'].companies += 1;
        companies[visitor.division]['Sum'].visitors += 1;

        // add to global-sum
        companies['global'][type].companies += 1;
        companies['global'][type].visitors += 1;
        companies['global']['Sum'].companies += 1;
        companies['global']['Sum'].visitors += 1;
      }
    });

    return {
      headers,
      body: companies,
    };
  }

  parsePieTotal(sumPerDivision) {
    // parse sumPerDivision-object into highcharts form
    const pieTotalData = [];
    Object.keys(sumPerDivision).map((divisionKey) => {
      if (divisionKey !== 'Sum' && divisionKey !== '') {
        pieTotalData.push({
          name: i18n(divisionKey.toLowerCase()),
          y: sumPerDivision[divisionKey]
        });
      }
    });

    return pieTotalData;
  }

  parseTopCountries() {
    // parse into countries
    const countries = {};
    for (let i = 0; i < this.visitors.length; i++) {
      const currentVisitors = this.visitors[i];

      // get country name from id
      const gbCountryObject = _.find(gbCountries, { id: currentVisitors.country });
      if (!gbCountryObject) {
        console.warn(`Unkown country "${currentVisitors.country}" [${currentVisitors._rev}]`);
        continue;
      }
      const gbCountry = gbCountryObject ? gbCountryObject.name : 'Unkown';

      if (!countries[gbCountry]) {
        // country entry does not exist yet, create one
        countries[gbCountry] = { 'sum': 0 };
      }

      // add reports to country
      countries[gbCountry].sum += 1;
    }

    // sort countries
    const sortedCountries = {};
    sortKeys(countries).map((countryKey) => {
      sortedCountries[countryKey] = countries[countryKey];
    });

    return {
      body: sortedCountries,
    };
  }

  parseTopCountriesPerDivision() {
    // parse into divisios
    const divisions = {
      'K': {}, 'W': {}, 'N': {}, 'TN': {}, 'CC': {}, 'S': {}, 'X': {},
    };
    for (let i = 0; i < this.visitors.length; i++) {
      const currentVisitors = this.visitors[i];

      // skip invalid divisions
      if (!divisions[currentVisitors.division]) {
        console.warn(`Unkown division "${currentVisitors.division}" [${currentVisitors._rev}]`);
        continue;
      }

      // get country name from id
      const gbCountryObject = _.find(gbCountries, { id: currentVisitors.country });
      if (!gbCountryObject) {
        console.warn(`Unkown country "${currentVisitors.country}" [${currentVisitors._rev}]`);
        continue;
      }
      const gbCountry = gbCountryObject ? gbCountryObject.name : 'Unkown';

      if (!divisions[currentVisitors.division][gbCountry]) {
        // country entry in division does not exist yet, create one
        divisions[currentVisitors.division][gbCountry] = { 'sum': 0 };
      }

      // add visitors to country
      divisions[currentVisitors.division][gbCountry].sum += 1;
    }

    // sort countries inside division
    const sortedDivisions = {};
    Object.keys(divisions).map((divisionKey) => {
      sortedDivisions[divisionKey] = {};

      sortKeys(divisions[divisionKey]).map((countryKey) => {
        sortedDivisions[divisionKey][countryKey] = divisions[divisionKey][countryKey];
      });
    });

    return sortedDivisions;
  }

  parseDivisionCountryHeatMap(topCountriesDivision) {
    // parse axis-data
    const yAxis = gbCountries.map(country => country.name).sort();
    const yAxisReversed = yAxis.reverse();
    const xAxis = gbDivisions.map(division => division.name);

    // parse data into coordinates
    const coordinateData = [];
    gbDivisions.map((division, indexDivision) => {
      // loop through countries from bottom and check if they have an entry inside division
      yAxisReversed.map((countryName, indexCountry) => {
        if (topCountriesDivision[division.id][countryName]) {
          // entry exists, map value to coordinate
          coordinateData.push(
            [indexDivision, indexCountry, topCountriesDivision[division.id][countryName].sum]
          );
        }
      });
    });

    return {
      data: coordinateData,
      y: yAxis,
      x: xAxis,
    };
  }

  parseContinentTree(topCountriesDivision) {
    const tree = [];
    const continentMapping = {};
    const colors = ['#a9b745', '#3b8194', '#83afbb', '#f6f8ec', '#eaeed3', '#5e9074'];

    // create parents
    gbContinents.map((continent, index) => {
      // add continent as tree-parent
      tree.push({
        name: continent.name,
        id: `parent-${continent.id}`,
        color: colors[index],
      });

      // add continent to mapping (is used to get the relation continentName<->parent-id)
      continentMapping[continent.name] = `parent-${continent.id}`;
    });

    // add values to parent
    Object.keys(topCountriesDivision).map((divisionKey) => {
      Object.keys(topCountriesDivision[divisionKey]).map((countryName) => {
        // to which contient does the current country belong
        const country = _.find(gbCountries, { name: countryName });

        // get parent-id of continent in tree
        const parentId = continentMapping[country.continent];

        // does the current country already exist?
        const existingCounty = _.find(tree, { name: countryName });
        if (!existingCounty) {
          // link country to parent
          tree.push({
            name: country.name,
            parent: parentId,
            id: `${parentId}_country-${country.id}`,
          });
        }

        // link division to country
        tree.push({
          name: divisionKey,
          parent: `${parentId}_country-${country.id}`,
          id:  `${parentId}_country-${country.id}-${divisionKey}`,
          value: topCountriesDivision[divisionKey][countryName].sum
        });
      });
    });

    return tree;
  }

  parseSentTotal() {
    // table-headers
    const headers = [
      '',
      i18n('sent'),
      i18n('unsent'),
    ];

    // count body
    let wasSent = 0;
    let notSent = 0;
    this.visitors.map((report) => {
      if (report.wasSent) {
        wasSent += 1;
      } else {
        notSent += 1;
      }
    });
    const sentData = {};
    sentData[i18n('reports')] = {
      sent: wasSent,
      unsent: notSent,
    };

    return {
      headers,
      body: sentData,
    };
  }
}
